import React, { useContext, useState, useRef, useEffect } from "react";

import dayjs from "dayjs";
import API_URL from "../../config/api";
import Swal from "sweetalert2";
import { DashboardContext } from "./Dashboard";

import { useTranslation } from "react-i18next";

const CountdownTime=({ id, date })=> {
  useEffect(() => {
    let timer = setInterval(() => {
      if (date === 0) {
        clearInterval(timer);
        Alert_Trade();
        return 0;
      } else return date - 1;
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  const { t } = useTranslation();
  const { get_OneUserTrading, get_user } = useContext(DashboardContext);

  let data = useRef(null);

  const Alert_Trade = async () => {
    await API_URL.post(`api/tradelist/getOneUserTradingTimeout`, {
      id: id,
    })
      .then(async (res) => {
        data = res.data;

        await Swal.fire({
          background: "#0a161c",
          title: `<div class="bg-gray-800 text-white py-2 rounded-sm"><strong>${data.symbol}</strong></div>`,
          html: `
                      <p class="mb-2 ${
                        data.trade_result === 0
                          ? "text-green-700"
                          : "text-red-600"
                      }">${data.trade_result === 0 ? "+" : "-"} ${data.net}</p>
                      <div class="bg-gray-900">
                      <table class="text-xs alert_table">
                <tr>
                  <th>${t("buy_alert")}</th>
                  <th>${t("time")}(${t("minute")})</th>
                  <th>${t("opening_price")}</th>
                  <th>${t("result")}</th>
                </tr>
                <tr>
                  <td>${
                    data.type_order === 1 ? `${t("higher")}` : `${t("lower")}`
                  }</td>
                  <td>${dayjs(data.closing_time).diff(
                    data.opening_time,
                    "minute"
                  )}</td>
                  <td>${data.opening_price}</td>
                  <td>${data.trade_result === 0 ? "+" : "-"} ${data.net}</td>
                </tr>
    
              </table>
                      </div>
    
                    `,

          focusConfirm: false,
          showDenyButton: true,
          showConfirmButton: false,
          denyButtonText: `
                     <p class="m-auto text-center px-3">${t("close")}</p>
                    `,

          customClass: {
            container: "p-0",
            popup: "p-1",
            title: "p-1",
            htmlContainer: "p-1 m-0 ",
            confirmButton: "w-1/4",
            footer: "p-1",
          },
        });
        get_OneUserTrading();
        get_user();
      })
      .catch((err) => {});
  };
  return <></>;
}

export default CountdownTime;
