import React, { useState, useEffect, useContext } from "react";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import OpenNotification from "../../../Toast/OpenNotification";
import { DashboardContext } from "../Dashboard";
import API_URL from "../../../config/api";
import { useTranslation } from "react-i18next";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import LoadingScreen from "../../../Toast/LoadingScreen";
import { Pagination } from "@mui/material";
import { Table, Spin, Tag } from "antd";
import numberFormat from "../../../Function/NumberFormat2float";



function History_main() {
  const { t } = useTranslation();
  const [userId] = useState(localStorage.getItem("awesomeLeadsTokenId"));
  const [alltrade, setAlltrade] = useState([]);
  const [alltradepc, setAlltradepc] = useState([]);
  const [alltradeBackup, setAlltradeBackup] = useState([]);
  const [alltradeloading, setAlltradeLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const itemcount = 10;

  useEffect(() => {
    get_OneUserAllTrade();
  }, []);

  const handleChangePage = (event, value) => {
    setPage(value);
    if (value > 1) {
      setAlltrade(
        alltradeBackup.slice((value - 1) * itemcount, itemcount * value)
      );
    } else {
      setAlltrade(alltradeBackup.slice(0, itemcount));
    }
    window.scrollTo(0, 0);
  };

  const get_OneUserAllTrade = async () => {
    setAlltrade([]);
    setAlltradeBackup([]);
    setAlltradepc([]);
    setAlltradeLoading(true);
    await API_URL.get(`api/tradelist/getoneuseralltrade/${userId}`)
      .then((res) => {
        const getalluser = res.data;

        if (getalluser.length !== 0) {
          getalluser.sort((a, b) => (b.createdAt > a.createdAt ? 1 : -1));
          setAlltrade(getalluser.slice(0, itemcount));
          // setAlltrade(getalluser);
          setAlltradeBackup(getalluser);
          setAlltradepc(getalluser);

          setPageCount(
            getalluser.length / itemcount < 1
              ? 1
              : Math.ceil(getalluser.length / itemcount)
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setAlltradeLoading(false);
  };

  const [timestart, setTimestart] = useState(
    dayjs().startOf("month").format("YYYY-MM-DD")
  );
  const [timeend, setTimeend] = useState(
    dayjs().endOf("month").format("YYYY-MM-DD")
  );
  const [inputSearch, setInputSearch] = useState("");
  const timeFormat = (time) => {
    return dayjs(time).format("YYYY-MM-DD");
  };

  const changDatestart = (e) => {
    if (e.target.value >= timeend) {
      setTimestart(dayjs(timeend).add(-1, "day").format("YYYY-MM-DD"));
    } else {
      setTimestart(e.target.value);
    }
  };
  const changDateend = (e) => {
    if (e.target.value <= timestart) {
      setTimeend(dayjs(timestart).add(1, "day").format("YYYY-MM-DD"));
    } else {
      setTimeend(e.target.value);
    }
  };
  const handleChageByDate = () => {
    setAlltradeLoading(true);
    let data = [];
    let sumde = 0;
    let sumwith = 0;
    alltradeBackup.map((all) => {
      if (inputSearch.length === 0) {
        if (
          timeFormat(all.createdAt) >= timestart &&
          timeFormat(all.createdAt) <= timeend
        ) {
          data.push(all);
        }
      } else {
        if (
          timeFormat(all.createdAt) >= timestart &&
          timeFormat(all.createdAt) <= timeend &&
          all.people.uid === inputSearch
        ) {
          data.push(all);
        }
      }
    });

    OpenNotification({
      message: `${t("finishedsearch")}`,
      type: 1,
    });

    setAlltrade(data);
    setAlltradepc(data);
    setAlltradeLoading(false);
  };
  const columns_trading = [
    {
      title: `${t("no_list")}`,
      align: "center",
      width: "8%",
      render: (alltradepc, record, index) => (
        <p className=" text-xs text-white my-auto px-2">{index + 1}</p>
      ),
    },

    {
      title: `${t("stocks")}`,
      align: "center",
      render: (alltradepc) => (
        <p className=" text-xs text-white my-auto px-2">{alltradepc.symbol}</p>
      ),
    },

    {
      title: `${t("order_type")}`,
      align: "center",
      render: (alltradepc) => (
        <>
          {alltradepc.type_order === 1 ? (
            <Tag color="green">
              <p className="text-xs text-white my-auto px-2 py-1 font-semibold">
                {t("buy")}
              </p>
            </Tag>
          ) : (
            <Tag color="volcano">
              <p className="text-xs text-white my-auto px-2 py-1 font-semibold">
                {t("sell")}
              </p>
            </Tag>
          )}
        </>
      ),
    },
    {
      title: `${t("amount")}`,
      align: "center",
      render: (alltradepc) => (
        <p className="wsn text-xs text-white my-auto px-2">
          {numberFormat(alltradepc.amount)}
        </p>
      ),
    },
    {
      title: `${t("opening_time")}`,
      align: "center",
      render: (alltradepc) => (
        <p className="wsn text-xs text-white my-auto px-2">
          {dayjs(alltradepc.opening_time).format("DD/MM/YYYY HH:mm:ss")}
        </p>
      ),
    },
    {
      title: `${t("opening_price")}`,
      align: "center",
      render: (alltradepc) => (
        <p className="wsn text-xs text-white my-auto px-2">
          {numberFormat(alltradepc.opening_price)}
        </p>
      ),
    },
    {
      title: `${t("closing_time")}`,
      align: "center",
      render: (alltradepc) => (
        <p className="wsn text-xs text-white my-auto px-2">
          {dayjs(alltradepc.closing_time).format("DD/MM/YYYY HH:mm:ss")}
        </p>
      ),
    },
    {
      title: `${t("closing_price")}`,
      align: "center",
      render: (alltradepc) => (
        <p className="wsn text-xs text-white my-auto px-2">
          {numberFormat(alltradepc.closing_price)}
        </p>
      ),
    },
    {
      title: `${t("result")}`,
      align: "center",
      render: (alltradepc) => (
        <p
          className={
            "wsn text-xs my-auto px-2 " +
            (alltradepc.trade_result === 0 ? "text-green-600" : "text-red-600")
          }
        >
          {alltradepc.trade_result === 0 ? "+" : "-"} {alltradepc.net} USDT
        </p>
      ),
    },
  ];

  return (

    <div>
      <div className="w-full md:w-2/3 flex flex-wrap justify-center  bg-zinc-700 py-3 px-1 mx-auto rounded-lg">
        <div className="w-1/2 md:w-1/5 px-1  rounded-md">
          <TextField
            inputProps={{ style: { color: "white",colorScheme:"dark" } }}
            id="date"
            label={t("start")}
            type="date"
            size="small"
            value={timestart}
            onChange={(e) => changDatestart(e)}
            InputLabelProps={{
              shrink: true,
              style: { color: "#fff" },
            }}
            fullWidth
          />
        </div>
        <div className="w-1/2 md:w-1/5 px-1  rounded-md">
          <TextField
             inputProps={{ style: { color: "white",colorScheme:"dark" } }}
            id="date"
            label={t("end")}
            type="date"
            size="small"
            fullWidth
            value={timeend}
            onChange={(e) => changDateend(e)}
            InputLabelProps={{
              shrink: true,
              style: { color: "#fff" },
            }}
          />
        </div>

        <button
          onClick={handleChageByDate}
          className="w-2/3 lg:w-1/12 my-1 md:my-0 px-4 py-1 bg-blue-600 hover:bg-blue-800 rounded-md shadow-lg text-white wsn"
        >
          {t("search")}
        </button>
      </div>
      <div className="w-full md:w-2/3 hidden lg:block mx-auto py-2 white_text">
        <Table
          className="table-striped-rows  "
          size="middle"
          scroll={{
            x: 800,
          }}
          pagination={{ pageSize: 15 }}
          rowKey="id"
          columns={columns_trading}
          dataSource={alltradepc}
          loading={{
            indicator: (
              <div>
                <Spin size="large" />
              </div>
            ),
            spinning: alltradeloading,
          }}
        />
      </div>
      <div className="w-full md:w-2/3 mx-auto py-2 white_text block lg:hidden">
        {alltradeloading ? (
          <LoadingScreen></LoadingScreen>
        ) : (
          <>
            {alltrade.length !== 0 ? (
              <>
                {alltrade.map((data, index) => (
                  <div
                    className=" bg-zinc-800 rounded-lg py-2 my-1 px-1 text-xs lg:text-sm"
                    key={index}
                  >
                    <div
                      className={
                        "border-l-2 lg:border-l-4 px-2 " +
                        (data.type_order === 1
                          ? "border-green-700"
                          : "border-red-700")
                      }
                    >
                      <div className={"grid grid-cols-3 "}>
                        <div className="justify-start ml-0.5 col-span-2">
                          <div className="text-white grid grid-cols-2 w-full lg:w-1/2">
                            <p>{data.symbol}</p>
                            <div className="my-auto text-xs truncate flex">
                              <p
                                className={
                                  data.type_order === 1
                                    ? "text-green-600"
                                    : "text-red-600"
                                }
                              >
          
                                {data.type_order === 1
                                  ? `${t("buy")}`
                                  : `${t("sell")}`}
                              </p>
                              <p>&nbsp;({data.amount} USDT)</p>
                            </div>
                          </div>
                          <div className="text-white grid  ">
                            <p className="my-auto">
                              {numberFormat(data.opening_price)} →{" "}
                              {numberFormat(data.closing_price)}
                            </p>
                          </div>
                        </div>
                        <div className="justify-end grid">
                          <div className="text-white  w-full ">
                            <p
                              className={
                                (data.trade_result === 0
                                  ? "text-green-600"
                                  : "text-red-600") + " my-auto"
                              }
                            >
                              {data.trade_result === 0 ? "+" : "-"} {data.net}{" "}
                              USDT
                            </p>
                          </div>
                        </div>
                      </div>
                      <p className="my-auto text-right text-gray-500 text-xs">
                        {dayjs(data.opening_time).format("DD/MM/YYYY HH:mm:ss")}{" "}
                        →{" "}
                        {dayjs(data.closing_time).format("DD/MM/YYYY HH:mm:ss")}
                      </p>
                    </div>
                  </div>
                ))}
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={handleChangePage}
                  size="large"
                  color="primary"
                  className="flex justify-center mt-5 text-white"
                />
              </>
            ) : (
              <div className=" grid gap-2 justify-center  items-center text-center text-white m-10 mx-auto">
                <div>
                  <FolderOffIcon className="scale-150" />
                  <p className="text-xs lg:text-base mt-3">{t("nodata")}</p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>

  );
}

export default History_main;
