import React, { useState, useEffect } from "react";

import { useCookies } from "react-cookie";

import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import HistoryIcon from "@mui/icons-material/History";
import AllTrade from "./AllTrade";
import TradingList from "./TradingList";
function Trading() {
  const [openTabTrandingCK, setOpenTabTrandingCK] = useCookies([
    "openTabTrandingCK",
  ]);
  const [openTab, setOpenTab] = useState(
    Number(openTabTrandingCK.openTabTrandingCK) || 1
  );

  const datatab = [
    {
      id: 1,
      tabname: "รายการเทรดอยู่",
    },
    {
      id: 2,
      tabname: "ประวัติการเทรดทั้งหมด",
    },
  ];

  return (
    <div>
      <div className="w-full px-0.5 md:px-3">
        <ul
          className="flex mb-0 list-none  pt-3 pb-0.5 flex-row"
          role="tablist"
        >
          {datatab.map((data, index) => (
            <li
              className="-mb-px mr-2 last:mr-0 flex-auto text-center whitespace-nowrap"
              key={index}
            >
              <a
                className={
                  "rounded-t-md myFont text-xs lg:text-sm md:text-md my-auto font-bold uppercase px-5 py-2 lg:py-4 shadow-lg  block leading-normal " +
                  (openTab === data.id
                    ? "text-white bg-blue-900 "
                    : "text-blue-600  bg-white hover:text-blue-900 hover:bg-gray-100")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTabTrandingCK("openTabTrandingCK", data.id, {
                    path: `/`,
                  });
                  setOpenTab(data.id);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                {data.id === 1 ? (
                  <StackedBarChartIcon className="my-auto" />
                ) : (
                  <HistoryIcon className="my-auto" />
                )}{" "}
                {data.tabname}
              </a>
            </li>
          ))}
        </ul>
        <div className="h-1 bg-blue-800"></div>
        <div className="relative flex flex-col min-w-0 break-words  w-full mb-6  ">
          <div className="px-0.5 lg:px-1 py-2 flex-auto">
            <div className="tab-content tab-space h-full">
              {/* tab1 */}

              <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                <TradingList />
              </div>
              {/* tab2 */}
              <div className={openTab === 2 ? "block" : "hidden"} id="link3">
                <AllTrade />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trading;
