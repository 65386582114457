import React, { useEffect } from "react";

const CoinMarketCapWidget = () => {
  useEffect(() => {
    // Dynamically create the script tag
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://widgets.coingecko.com/gecko-coin-price-static-headline-widget.js";
    script.async = true;

    // Append the script to the document body
    document.body.appendChild(script);

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <gecko-coin-price-static-headline-widget
      locale="en"
      dark-mode="true"
      transparent-background="true"
      coin-ids="bitcoin,ethereum,shiba-inu,binancecoin,dogecoin,solana,ripple,cardano,polkadot,litecoin,chainlink,avalanche-2,matic-network,cosmos"
    //   coin-ids="bitcoin,ethereum,shiba-inu,binancecoin,dogecoin,solana,ripple"
      initial-currency="usd"
      className="pointer-events-none"
    ></gecko-coin-price-static-headline-widget>
  );
};

export default CoinMarketCapWidget;
