// src/TradingViewWidget.js
import React, { useState, useContext, useMemo, useEffect } from "react";

import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { Table, Spin, Tag, Segmented, Modal } from "antd";

import MovingIcon from "@mui/icons-material/Moving";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { TextField } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { DashboardContext } from "../Dashboard";
import API_URL from "../../../config/api";
import numberFormat from "../../../Function/NumberFormat2float";
import OpenNotification from "../../../Toast/OpenNotification";
import dayjs from "dayjs";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import ErrorMessage from "../../../Toast/ErrorMessage";

import { useTranslation } from "react-i18next";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import { useWebSocket } from "../WebSocketUser";

import { Pagination } from "@mui/material";
import { TradeContext } from "./Trade";
import Swal from "sweetalert2";

const TradingViewWidget = ({ symbol, symbolname }) => {
  const { sendMessage } = useWebSocket();

  const {
    payrate,
    userId,
    tradinglist,
    tradinglistloading,
    get_OneUserTrading,
    get_user,
    userdata,
  } = useContext(DashboardContext);

  const { tabValue, setTabValue, setSelecttabtrade } = useContext(TradeContext);

  const { t } = useTranslation();

  const [selectTime, setSelectTime] = useState(1);
  const [selectSell, setSelectSell] = useState(0);
  const [amount, setAmount] = useState("");

  const [msgErr1, setMsgErr1] = useState("");

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const [btn_soon, setBtn_soon] = useState(false);

  const itemcount = 10;

  const [alltrade, setAlltrade] = useState([]);

  useEffect(() => {
    setAlltrade(tradinglist.slice(0, itemcount));

    setPageCount(
      tradinglist.length / itemcount < 1
        ? 1
        : Math.ceil(tradinglist.length / itemcount)
    );
  }, [tradinglist]);

  const handleChangePage = (event, value) => {
    setPage(value);
    if (value > 1) {
      setAlltrade(
        tradinglist.slice((value - 1) * itemcount, itemcount * value)
      );
    } else {
      setAlltrade(tradinglist.slice(0, itemcount));
    }
    window.scrollTo(0, 0);
  };

  const ModalTrade = async (e) => {
    e.preventDefault();
    setBtn_soon(true);
    if (amount.length === 0 || amount === "" || amount === "0" || amount < 1) {
      setMsgErr1(`${t("enteramount")}`);
      setBtn_soon(false);
      return;
    }
    if (Number(amount) > userdata.credit) {
      setMsgErr1(`${t("notenoughmoney")}`);
      setBtn_soon(false);
      return;
    }
    if (selectSell === 0) {
      setMsgErr1(`${t("choosehighlow")}`);
      setBtn_soon(false);
      return;
    }
    setMsgErr1("");

    await API_URL.post(`api/tradelist/getTradePrice`, {
      amount: amount,
      symbol: symbolname,
      peopleId: userId,
    })
      .then(async (res) => {
        const symbol_price = res.data.getPrice;
        const symbol_name = res.data.symbolName;
        await Swal.fire({
          background: "#0a161c",
          title: `<div class="bg-gray-800 text-white py-2 rounded-sm"><strong>${symbol_name}</strong></div>`,
          html: `
            <div class="bg-gray-900">
              <table class="text-xs alert_table">
                <tr>
                  <th>${t("order_type")}</th>
                  <th>${t("amount")} (USDT)</th>
                  <th>${t("time")}(${t("minute")})</th>
                  <th>${t("opening_price")}</th>
                  <th>${t("profit")}</th>
                </tr>
                <tr>
                  <td class="${
                    selectSell === 1 ? `text-green-600` : "text-red-600"
                  } font-semibold">${
            selectSell === 1 ? `${t("buy")}` : `${t("sell")}`
          }</td>
                  <td >${numberFormat(amount)}</td>
                  <td>${payrate[selectTime - 1].countTime}</td>
                  <td>${numberFormat(symbol_price)}</td>
                  <td>${payrate[selectTime - 1].percent} %</td>
                </tr>
    
              </table>
            </div>
    
                `,

          focusConfirm: false,
          focusCancel: false,
          showDenyButton: true,
          showConfirmButton: true,
          confirmButtonColor: "#1D4ED8",
          confirmButtonText: `
               ${t("confirm")}
                `,
          denyButtonText: `
                ${t("close")}
                `,
          allowOutsideClick: false,
          customClass: {
            container: "p-0",
            popup: "p-1",
            title: "p-1",
            htmlContainer: "p-1 m-0 ",
            confirmButton: "py-2 text-sm lg:text-base",
            denyButton: "py-2 text-sm lg:text-base",
            footer: "p-1",
          },
        }).then(async(result) => {
          if (result.isConfirmed) {
            await API_URL.post(`api/tradelist/createUserTradeConfirm`, {
              selectSell: selectSell,
              getPrice: symbol_price,
              selectPercent: payrate[selectTime - 1].percent,
              countTime: payrate[selectTime - 1].countTime,
              amount: amount,
              symbol: symbol_name,
              peopleId: userId,
            })
              .then((res) => {
                OpenNotification({ message: `${t("purchased")}`, type: 1 });
        
                setAmount("");
        
                get_OneUserTrading();
                get_user();
                sendMessage({ type: "refreshUserTrading" });
              })
              .catch((err) => {
                OpenNotification({
                  message: `${t("data_transmission_error")}`,
                  type: 4,
                });
              });
          } else if (result.isDenied) {
          }
        });
      })
      .catch((err) => {
        OpenNotification({
          message: `${t("data_transmission_error")}`,
          type: 4,
        });
      });
      setBtn_soon(false);
  };

  const ConfirmTrade = async (e) => {
    e.preventDefault();
    if (amount.length === 0 || amount === "" || amount === "0" || amount < 1) {
      setMsgErr1(`${t("enteramount")}`);
      return;
    }
    if (Number(amount) > userdata.credit) {
      setMsgErr1(`${t("notenoughmoney")}`);
      return;
    }
    if (selectSell === 0) {
      setMsgErr1(`${t("choosehighlow")}`);
      return;
    }
    setMsgErr1("");
    setBtn_soon(true);
    await API_URL.post(`api/tradelist/createusertrade`, {
      selectSell: selectSell,
      selectPercent: payrate[selectTime - 1].percent,
      countTime: payrate[selectTime - 1].countTime,
      amount: amount,
      symbol: symbolname,
      peopleId: userId,
    })
      .then((res) => {
        OpenNotification({ message: `${t("purchased")}`, type: 1 });

        setAmount("");

        get_OneUserTrading();
        get_user();
        sendMessage({ type: "refreshUserTrading" });
      })
      .catch((err) => {
        OpenNotification({
          message: `${t("data_transmission_error")}`,
          type: 4,
        });
      });
    setBtn_soon(false);
  };

  const onChangetab = (value) => {
    setSelecttabtrade("selecttabtrade", value.toString(), {
      path: `/`,
    });
    setTabValue(value.toString());
  };

  const columns_trading = [
    {
      title: `${t("no_list")}`,
      align: "center",
      width: "8%",
      render: (tradinglist, record, index) => (
        <p className=" text-xs text-white my-auto px-2">{index + 1}</p>
      ),
    },

    {
      title: `${t("stocks")}`,
      align: "center",
      render: (tradinglist) => (
        <p className=" text-xs text-white my-auto px-2">{tradinglist.symbol}</p>
      ),
    },

    {
      title: `${t("order_type")}`,
      align: "center",
      render: (tradinglist) => (
        <>
          {tradinglist.type_order === 1 ? (
            <Tag color="green">
              <p className="text-xs text-white my-auto px-2 py-1 font-semibold">
                {t("buy")}
              </p>
            </Tag>
          ) : (
            <Tag color="volcano">
              <p className="text-xs text-white my-auto px-2 py-1 font-semibold">
                {t("sell")}
              </p>
            </Tag>
          )}
        </>
      ),
    },
    {
      title: `${t("amount")}`,
      align: "center",
      render: (tradinglist) => (
        <p className="wsn text-xs text-white my-auto px-2">
          {numberFormat(tradinglist.amount)}
        </p>
      ),
    },
    {
      title: `${t("opening_time")}`,
      align: "center",
      render: (tradinglist) => (
        <p className="wsn text-xs text-white my-auto px-2">
          {dayjs(tradinglist.opening_time).format("DD/MM/YYYY HH:mm:ss")}
        </p>
      ),
    },
    {
      title: `${t("opening_price")}`,
      align: "center",
      render: (tradinglist) => (
        <p className="wsn text-xs text-white my-auto px-2">
          {numberFormat(tradinglist.opening_price)}
        </p>
      ),
    },
    {
      title: `${t("closing_time")}`,
      align: "center",
      render: (tradinglist) => (
        <p className="wsn text-xs text-white my-auto px-2">
          {dayjs(tradinglist.closing_time).format("DD/MM/YYYY HH:mm:ss")}
        </p>
      ),
    },
    // {
    //   title: "Closing price",
    //   align: "center",
    //   render: (tradinglist) => (
    //     <p className="wsn text-xs text-white my-auto px-2">
    //       {tradinglist.closing_price}
    //     </p>
    //   ),
    // },
    {
      title: `${t("status")}`,
      align: "center",
      render: (tradinglist) => (
        <p className="wsn text-xs text-white my-auto px-2">
          {t("waiting_trade")}
        </p>
      ),
    },
  ];
  const studies = useMemo(
    () => [
      {
        id: "MASimple@tv-basicstudies",
        inputs: {
          length: 9,
          type: "ema",
        },
      },
      {
        id: "DoubleEMA@tv-basicstudies",
        inputs: {
          length: 9,
          type: "dema",
        },
      },
    ],
    []
  );
  return (
    <div className="w-full px-3">
      <Segmented
        block
        defaultValue={tabValue}
        value={tabValue}
        className="py-2 mt-2 lg:mt-0"
        style={{
          marginBottom: 8,
        }}
        onChange={(value) => onChangetab(value)}
        options={[
          {
            label: `${t("graph")}`,
            value: "1",
            icon: <AutoGraphIcon />,
          },
          {
            label: `${t("trade")}`,
            value: "2",
            icon: <PriceChangeIcon />,
          },
        ]}
      />
      {tabValue === "1" ? (
        <>
          <div className="h-1/3 lg:h-3/6">
            <AdvancedRealTimeChart
              symbol={symbol || "BINANCE:BTCUSDT"}
              timezone="Asia/Bangkok"
              theme="dark"
              hide_side_toolbar={true}
              width={"100%"}
              style="1"
              allow_symbol_change={false}
              enable_publishing={false}
              details={false}
              studies={studies}
            ></AdvancedRealTimeChart>
          </div>
        </>
      ) : (
        <>
          <p className="my-1 text-white ">
            {t("selectedcoins")} - {symbol.toUpperCase()}
          </p>
          <div className=" w-full mx-auto  bg-gray-700 px-2 lg:px-5 pb-2 lg:pb-5 pt-1 lg:pt-2  text-white rounded-lg mt-2 ">
            <p className="text-left m-0 text-xs lg:text-sm">{t("amount")} </p>
            <TextField
              name="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              size="small"
              id="outlined-textarea"
              autoComplete="off"
              placeholder=">=1 USDT"
              className="w-full text-white "
              color="info"
              onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
              inputProps={{ maxLength: 50, style: { color: "#fff" } }}
              InputProps={{
                startAdornment: (
                  <AccountBalanceWalletIcon
                    position="start"
                    className="mr-5 text-white"
                  ></AccountBalanceWalletIcon>
                ),
                endAdornment: (
                  <p className=" text-white bg-blue-800 p-2 rounded-sm">USDT</p>
                ),
              }}
            />
          </div>

          <div className="grid grid-cols-4 md:grid-cols-4 gap-2 md:gap-6 py-3 px-2">
            {payrate.map((data, index) => (
              <div className=" flex-auto text-center " key={index}>
                <a
                  className={
                    " text-xs md:text-md font-bold uppercase px-2 py-1 lg:py-2 shadow-lg rounded-lg  block leading-normal " +
                    (selectTime === data.id
                      ? "text-white border-blue-700 border-2 bg-blue-800"
                      : " text-white   hover:bg-blue-500  border-2 border-blue-900")
                  }
                  onClick={(e) => {
                    e.preventDefault();

                    setSelectTime(data.id);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  <p className="truncate text-xs lg:text-sm">
                    {data.countTime} {t("minute")}
                  </p>
                  <p
                    className="mt-2 text-red-400"
                    style={{ textShadow: "0px 1px 2px black" }}
                  >
                    {data.percent} %
                  </p>
                </a>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-2 gap-3 pt-0 pb-2 lg:py-3 px-2 lg:px-4">
            <button
              onClick={(e) => {
                setSelectSell(selectSell !== 1 ? 1 : 0);
              }}
              className={
                "w-full  rounded-lg border-green-500 border-2 text-base text-white   text-center py-1 lg:py-2 mx-auto drop-shadow-lg " +
                (selectSell === 1 ? "bg-green-600" : "hover:bg-gray-900")
              }
            >
              <MovingIcon className="my-auto" /> <h4>{t("buy")}</h4>
            </button>
            <button
              onClick={(e) => {
                setSelectSell(selectSell !== 2 ? 2 : 0);
              }}
              className={
                "w-full  rounded-lg border-red-600 border-2 text-base text-white   text-center py-1 lg:py-2 mx-auto drop-shadow-lg " +
                (selectSell === 2 ? "bg-red-600" : "hover:bg-gray-900")
              }
            >
              <TrendingDownIcon className="my-auto " /> <h4>{t("sell")}</h4>
            </button>
          </div>
          {msgErr1 ? <ErrorMessage message={msgErr1} /> : <></>}
          <button
            disabled={btn_soon}
            onClick={(e) => ModalTrade(e)}
            className="w-full font-bold  rounded-lg border-yellow-500 border-2 text-base text-black hover:bg-yellow-400 bg-yellow-500 text-center py-1 lg:py-2 mx-auto drop-shadow-lg "
          >
            <h4>{t("confirm")}</h4>
          </button>
          <div className="mt-2">
            <div className="flex  items-center text-white my-2">
              <FactCheckIcon className="text-left mr-2" />
              <p className="text-xs lg:text-sm">{t("tradingnow")}</p>
            </div>

            <div className="my-1 h-0.5 bg-zinc-500"></div>
            <Table
              className="table-striped-rows my-1 hidden lg:block"
              size="middle"
              scroll={{
                x: 800,
              }}
              pagination={{ pageSize: 10 }}
              rowKey="id"
              columns={columns_trading}
              dataSource={tradinglist}
              loading={{
                indicator: (
                  <div>
                    <Spin size="large" />
                  </div>
                ),
                spinning: tradinglistloading,
              }}
            />
            <div className="w-full block lg:hidden mx-auto my-2 white_text">
              {tradinglistloading ? (
                <Spin size="large" />
              ) : (
                <>
                  {alltrade.length !== 0 ? (
                    <>
                      {alltrade.map((data, index) => (
                        <div
                          className=" bg-zinc-800 rounded-lg py-2 my-1 px-1 text-xs lg:text-sm"
                          key={index}
                        >
                          <div
                            className={
                              "border-l-2 lg:border-l-4 px-2 " +
                              (data.type_order === 1
                                ? "border-green-700"
                                : "border-red-700")
                            }
                          >
                            <div className={"grid grid-cols-3 "}>
                              <div className="justify-start ml-0.5 col-span-2">
                                <div className="text-white grid grid-cols-2 w-full lg:w-1/2">
                                  <p>{data.symbol}</p>
                                  <div className="my-auto text-xs truncate flex">
                                    <p
                                      className={
                                        data.type_order === 1
                                          ? "text-green-600"
                                          : "text-red-600"
                                      }
                                    >
                                      {data.type_order === 1
                                        ? `${t("buy")}`
                                        : `${t("sell")}`}
                                    </p>
                                    <p>&nbsp;({data.amount} USDT)</p>
                                  </div>
                                </div>
                                <div className="text-white grid  ">
                                  <p className="my-auto text-xs">
                                    {numberFormat(data.opening_price)} →{" "}
                                    {t("waiting_trade")}
                                  </p>
                                </div>
                              </div>
                              <div className="justify-end grid">
                                <div className="text-white  w-full ">
                                  <p className=" my-auto text-white">
                                    {t("waiting_trade")}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <p className="my-auto text-right text-gray-500 text-xs">
                              {dayjs(data.opening_time).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}{" "}
                              →{" "}
                              {dayjs(data.closing_time).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                            </p>
                          </div>
                        </div>
                      ))}
                      <Pagination
                        count={pageCount}
                        page={page}
                        onChange={handleChangePage}
                        size="large"
                        color="primary"
                        className="flex justify-center mt-5 text-white"
                      />
                    </>
                  ) : (
                    <div className=" grid gap-2 justify-center  items-center text-center text-white m-10 mx-auto">
                      <div>
                        <FolderOffIcon className="scale-150" />
                        <p className="text-xs lg:text-base mt-3">
                          {t("nodata")}
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TradingViewWidget;
