import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import "./Style/All_style.css";
import PersonIcon from "@mui/icons-material/Person";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LockIcon from "@mui/icons-material/Lock";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PasswordIcon from "@mui/icons-material/Password";
import mobile_cover from "../../../image/mobile_login_cover.jpg";
import EmailIcon from "@mui/icons-material/Email";
import Mylogo from "../../../image/Bitcoin-SV-logo.png";

import ContactsIcon from "@mui/icons-material/Contacts";
import ErrorMessage from "../../../Toast/ErrorMessage";
// import OpenNotification from "../notification/OpenNotification";

// import logoweb from "../../icon/Logoeorder.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import API_URL from "../../../config/api";
import OpenNotification from "../../../Toast/OpenNotification";
import Logoweb from "../../../Function/Logoweb";
import Nameweb from "../../../Function/Nameweb";

function 
TabLogin({ openTab, setOpenTab }) {
  const { t } = useTranslation();
  let { refcodeurl, tabindexurl } = useParams();
  // const [openTab, setOpenTab] = useState(Number(id) || 2);
  // const [openTab, setOpenTab] = useState(initialActiveTab === 'register' ? 1 : 2);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [idline, setIdline] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneLogin, setPhoneLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");
  const [pincode, setPincode] = useState("");
  const [refcode, setRefcode] = useState(refcodeurl || "");
  const [msgErr1, setMsgErr1] = useState("");
  const [msgErr2, setMsgErr2] = useState("");

  const [showPassword, setShowpassword] = useState(false);
  const [showPasswordlogin, setShowpasswordlogin] = useState(false);

  // useEffect(() => {
  //   console.log("Initial Active Tab:", initialActiveTab); // Add this line for debugging
  //   setOpenTab(initialActiveTab === 'register' ? 1 : 2);
  // }, [initialActiveTab]);

  const CreateUser = async (e) => {
    setMsgErr1("");
    if (
      firstname === "" ||
      lastname === "" ||
      phone === "" ||
      password === "" ||
      confirmPassword === "" ||
      email === ""
    ) {
      setMsgErr1(`***${t("fillintheinfocom")}***`);
      return;
    }
    if (phone.length !== 10) {
      setMsgErr1(`***${t("fillcompletephonenumber")}***`);
      return;
    }
    if (password.length < 8) {
      setMsgErr1(`***${t("pleaseenterpasswordandcharacter")}***`);
      return;
    }
    if (password !== confirmPassword) {
      setMsgErr1(`***${t("passwordnotmatch")}***`);
      return;
    }
    setMsgErr1("");

    await API_URL.post(`api/people/newPeople`, {
      firstname: firstname,
      lastname: lastname,
      phone: phone,
      password: password,
      email: email,
    })
      .then((res) => {
        OpenNotification({
          message: `${t("successfullyregisterpleaselogin")}`,
          type: 1,
        });
        setOpenTab(2);
        clearForm();
        setPhoneLogin(phone);
        setPasswordLogin(password);
      })
      .catch((err) => {
        err.response.data.status === 400
          ? // ? OpenNotification({ message: "มีผู้ใช้ใช้เบอร์โทรนี้แล้ว", type: 3 })
            // : OpenNotification({ message: `${t("thisphoneinuse")}`, type: 4 });
            setMsgErr1(`${t("thisphoneinuse")}`)
          : setMsgErr1(`${t("data_transmission_error")}`);
      });
  };

  const userloing = async (e) => {
    e.preventDefault();
    if (phoneLogin.length !== 10) {
      setMsgErr2(`***${t("fillcompletephonenumber")}***`);
      return;
    }
    if (passwordLogin.length < 8) {
      setMsgErr2(`***${t("pleaseenterpasswordandcharacter")}***`);
      return;
    }
    setMsgErr2("");
    await API_URL.post(`api/people/onePeople`, {
      phone: phoneLogin,
      password: passwordLogin,
    })
      .then((res) => {
        if (window.$chatwoot) {
          window.$chatwoot.reset();
        }
        localStorage.setItem(
          "awesomeLeadsTokenId",
          JSON.stringify(res.data.user.id)
        );
        localStorage.setItem(
          "awesomeLeadsTokenUserdata",
          JSON.stringify(res.data)
        );

        window.location.href = `/login/`;
        // window.location.reload();
      })
      .catch((err) => {
        setMsgErr2(`${t("phoneorpasswordincorrect")}`);
      });
  };

  const clearForm = () => {
    setConfirmPassword("");
    setFirstname("");
    setIdline("");
    setLastname("");
    setEmail("");
    setPassword("");
    setPhone("");
    setPincode("");
    setRefcode("");
  };
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist"
          >
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold  uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 1
                    ? "text-white bg-blue-500"
                    : "text-blue-500 bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#register"
                role="tablist"
              >
                {t("register")}
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs  font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 2
                    ? "text-white bg-blue-500"
                    : "text-blue-500 bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#login"
                role="tablist"
              >
                {t("login")}
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col min-w-0 break-words  bg-white  w-full mb-6 shadow-lg rounded">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div
                  className={openTab === 1 ? "block" : "hidden"}
                  id="register"
                >
                  <div className="w-full px-5 md:px-5">
                    <div>
                      <div className="flex flex-wrap -mx-9">
                        <div className="w-full md:w-1/2 px-3 mb-0 md:mb-2">
                          <label className="text-xs font-semibold px-1">
                            {t("firstname")}
                          </label>
                          <div className="flex ">
                            <TextField
                              name="firstname"
                              autoComplete="off"
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <PersonIcon
                                    position="start"
                                    className="-ml-2 text-gray-500"
                                  ></PersonIcon>
                                ),
                              }}
                              onKeyPress={(e) =>
                                /[0-9]/.test(e.key) && e.preventDefault()
                              }
                              inputProps={{ maxLength: 20 }}
                              className="w-6/6 focus:border-indigo-500 inputRounded "
                              fullWidth
                              value={firstname}
                              onChange={(e) => setFirstname(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="w-full md:w-1/2 px-3 mb-0 md:mb-2">
                          <label className="text-xs font-semibold px-1">
                            {t("lastname")}
                          </label>
                          <div className="flex">
                            <TextField
                              name="lastname"
                              autoComplete="off"
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <PersonIcon
                                    position="start"
                                    className="-ml-2 text-gray-500"
                                  ></PersonIcon>
                                ),
                              }}
                              onKeyPress={(e) =>
                                /[0-9]/.test(e.key) && e.preventDefault()
                              }
                              inputProps={{ maxLength: 20 }}
                              className="w-6/6 focus:border-indigo-500 inputRounded"
                              fullWidth
                              value={lastname}
                              onChange={(e) => setLastname(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-9">
                        <div className="w-full md:w-1/2 px-3 mb-0 md:mb-2">
                          <label className="text-xs font-semibold px-1">
                            {t("phone")}
                          </label>
                          <div className="flex">
                            <TextField
                              name="phone"
                              autoComplete="off"
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <LocalPhoneIcon
                                    position="start"
                                    className="-ml-2 text-gray-500"
                                  ></LocalPhoneIcon>
                                ),
                              }}
                              onKeyPress={(e) =>
                                !/[0-9]/.test(e.key) && e.preventDefault()
                              }
                              inputProps={{ maxLength: 10 }}
                              className="w-6/6 focus:border-indigo-500 inputRounded"
                              fullWidth
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="w-full md:w-1/2 px-3 mb-0 md:mb-2">
                          <label className="text-xs font-semibold px-1">
                            E-mail
                          </label>
                          <div className="flex">
                            <TextField
                              type="email"
                              name="email"
                              autoComplete="off"
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <EmailIcon
                                    position="start"
                                    className="-ml-2 text-gray-500"
                                  ></EmailIcon>
                                ),
                              }}
                              inputProps={{ maxLength: 50 }}
                              className="w-6/6 focus:border-indigo-500 inputRounded"
                              fullWidth
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-wrap -mx-9">
                        <div className="w-full md:w-1/2 px-3 mb-0 md:mb-2">
                          <label className="text-xs font-semibold px-1">
                            {t("password")}
                          </label>
                          <div className="flex">
                            <TextField
                              type={showPassword ? "text" : "password"}
                              name="password"
                              autoComplete="off"
                              size="small"
                              placeholder="************"
                              InputProps={{
                                startAdornment: (
                                  <LockIcon
                                    position="start"
                                    className="-ml-2 text-gray-500"
                                  ></LockIcon>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                        setShowpassword(!showPassword)
                                      }
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              className="w-6/6 focus:border-indigo-500 inputRounded"
                              fullWidth
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="w-full md:w-1/2 px-3 mb-0 md:mb-2">
                          <label className="text-xs font-semibold px-1">
                            {t("confirmpassword")}
                          </label>
                          <div className="flex">
                            <TextField
                              type={showPassword ? "text" : "password"}
                              name="password"
                              autoComplete="off"
                              size="small"
                              placeholder="************"
                              InputProps={{
                                startAdornment: (
                                  <LockIcon
                                    position="start"
                                    className="-ml-2 text-gray-500"
                                  ></LockIcon>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                        setShowpassword(!showPassword)
                                      }
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              className="w-6/6 focus:border-indigo-500 inputRounded"
                              fullWidth
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-9">
                        {/* <div className="w-full md:w-1/2 px-3 mb-0 md:mb-2">
                          <label className="text-xs font-semibold px-1">
                            รหัส PIN 6 หลัก
                          </label>
                          <div className="flex">
                            <TextField
                              name="pincode"
                              autoComplete="off"
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <PasswordIcon
                                    position="start"
                                    className="-ml-2 mr-1 text-gray-500"
                                  ></PasswordIcon>
                                ),
                              }}
                              onKeyPress={(e) =>
                                !/[0-9]/.test(e.key) && e.preventDefault()
                              }
                              inputProps={{ maxLength: 6 }}
                              className="w-6/6 focus:border-indigo-500 inputRounded"
                              fullWidth
                              value={pincode}
                              onChange={(e) => setPincode(e.target.value)}
                            />
                          </div>
                        </div> */}

                        <div className="w-full md:w-1/2 px-3 mb-0 md:mb-2">
                          {/* <label className="text-xs font-semibold px-1">
                            โค้ดแนะนำ
                          </label>
                          <div className="flex">
                            <TextField
                              name="refcode"
                              autoComplete="off"
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <ContactsIcon
                                    position="start"
                                    className="-ml-2 text-gray-500"
                                  ></ContactsIcon>
                                ),
                              }}
                              className="w-6/6 focus:border-indigo-500 inputRounded"
                              fullWidth
                              inputProps={{ maxLength: 8 }}
                              value={refcode}
                              onChange={(e) => setRefcode(e.target.value)}
                            />
                          </div> */}
                        </div>
                      </div>
                      <div className="py-1 w-full text-center">
                        {msgErr1 ? <ErrorMessage message={msgErr1} /> : <></>}
                      </div>

                      <div className="flex mt-10 -mx-9">
                        <div className="w-full px-3 ">
                          <button
                            onClick={(e) => CreateUser(e)}
                            className="block w-full max-w-xs mx-auto bg-gradient-to-b from-blue-500 to-blue-700 hover:bg-gradient-to-t border-b-8 border-blue-600 hover:border-blue-500 text-white rounded-lg px-3 py-3 font-semibold"
                          >
                            {t("register")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="login">
                  <div className="w-full px-1 md:px-2">
                    <div className="text-center">
                      {/* <h1 className="font-bold text-3xl text-gray-900">
                        ลงทะเบียน
                      </h1>
                      <p>กรุณากรอกข้อมูลให้ครบถ้วน</p> */}
                      {/* <LazyLoadImage alt={"e-order4youlogo"}  onClick={()=>window.location.href = `/`} className="h-28 mx-auto cursor-pointer" src={logoweb} /> */}
                    </div>
                    <div>
                      <div className="block -mx-3">
                        <div className="w-full flex justify-center">
                          <div className="  text-center  h-7 lg:h-11 mx-2 object-contain ">
                            <Logoweb />
                          </div>
                          <div className="text-black my-auto">
                            <Nameweb />
                          </div>
                        </div>
                        <div className="w-full px-3 mb-2">
                          <label className="text-xs font-semibold px-1">
                            {t("phone")}
                          </label>
                          <div className="flex">
                            <TextField
                              name="phoneLogin"
                              autoComplete="off"
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <LocalPhoneIcon
                                    position="start"
                                    className="-ml-2 text-gray-500"
                                  ></LocalPhoneIcon>
                                ),
                              }}
                              inputProps={{ maxLength: 20 }}
                              className="w-6/6  inputRounded"
                              fullWidth
                              value={phoneLogin}
                              onChange={(e) => setPhoneLogin(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex -mx-3">
                        <div className="w-full px-3 mb-5">
                          <label className="text-xs font-semibold px-1">
                            {t("password")}
                          </label>
                          <div className="flex">
                            <TextField
                              type={showPasswordlogin ? "text" : "password"}
                              name="password"
                              autoComplete="off"
                              size="small"
                              placeholder="************"
                              InputProps={{
                                startAdornment: (
                                  <LockIcon
                                    position="start"
                                    className="-ml-2 text-gray-500"
                                  ></LockIcon>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                        setShowpasswordlogin(!showPasswordlogin)
                                      }
                                    >
                                      {showPasswordlogin ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              className="w-6/6 focus:border-indigo-500 inputRounded"
                              fullWidth
                              value={passwordLogin}
                              onChange={(e) => setPasswordLogin(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="py-2 w-full text-center">
                        {msgErr2 ? <ErrorMessage message={msgErr2} /> : <></>}
                      </div>

                      <div className="flex -mx-3">
                        <div className="w-full px-3 mb-3">
                          <button
                            onClick={(e) => userloing(e)}
                            className="block w-full max-w-xs mx-auto bg-gradient-to-b from-blue-500 to-blue-700 hover:bg-gradient-to-t border-b-8 border-blue-600 hover:border-blue-500 text-white rounded-lg px-3 py-3 font-semibold"
                          >
                            {t("login")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TabLogin;
