import React, { useContext, useState,useRef } from "react";
import Countdown from "react-countdown";
import dayjs from "dayjs";
import API_URL from "../../config/api";
import Swal from "sweetalert2";
import { DashboardContext } from "./Dashboard";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useWebSocket } from "./WebSocketUser";
import numberFormat from "../../Function/NumberFormat2float";


const Countdown_UI = ({ id, date }) => {
  const { sendMessage } = useWebSocket();

  const { t } = useTranslation();
  const { get_OneUserTrading, get_user } = useContext(DashboardContext);
  const [isModal, setIsModal] = useState(false);
  const hasAlerted = useRef(false);
  let data = useRef(null)
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed && !hasAlerted.current) {
      hasAlerted.current = true;
      
      Alert_Trade();
    } else {
      return <></>;
    }
  };
  const Alert_Trade = async () => {
    await API_URL.post(`api/tradelist/getOneUserTradingTimeout`, {
      id: id,
    })
      .then(async(res) => {
         data = res.data;
         sendMessage({ type: "refreshUserTrading" });
        
        setIsModal(true)
        await  Swal.fire({
          background: '#0a161c',
          title: `<div class="bg-gray-800 text-white py-2 rounded-sm"><strong>${data.symbol}</strong></div>`,
          html: `
                  <p class="mb-2 ${
                    data.trade_result === 0 ? "text-green-700" : "text-red-600"
                  }">${data.trade_result === 0 ? "+" : "-"} ${data.net}</p>
                  <div class="bg-gray-900">
                  <table class="text-xs alert_table">
            <tr>
              <th>${t("order_type")}</th>
              <th>${t("time")}(${t("minute")})</th>
              <th>${t("opening_price")}</th>
              <th>${t("closing_price")}</th>
              <th>${t("result")}</th>
            </tr>
            <tr>
              <td class="${data.type_order=== 1 ?`text-green-600`:"text-red-600"}">${data.type_order === 1 ? `${t("buy")}` : `${t("sell")}`}</td>
              <td>${dayjs(data.closing_time).diff(data.opening_time, "minute")}</td>
              <td>${numberFormat(data.opening_price)}</td>
              <td>${numberFormat(data.closing_price)}</td>
              <td>${data.trade_result === 0 ? "+" : "-"} ${data.net}</td>
            </tr>

          </table>
                  </div>

                `,

          focusConfirm: false,
          showDenyButton: true,
          showConfirmButton: false,
          denyButtonText: `
                 <p class="m-auto text-center px-3">${t("close")}</p>
                `,

          customClass: {
            container: "p-0",
            popup: "p-1",
            title: "p-1",
            htmlContainer: "p-1 m-0 ",
            confirmButton: "w-1/4",
            footer: "p-1",
          },
        });
        get_OneUserTrading();
        get_user();
      })
      .catch((err) => {
        
      });
  };

  return (
    <>
      {/* {data&&isModal ? (
        <Modal
          title={"tesst"}
          open={isModal}
    
          onOk={()=>setIsModal(!isModal)}
          onCancel={()=>setIsModal(!isModal)}
          width={800}
          footer={
            <button
              onClick={()=>setIsModal(!isModal)}
              className=" bg-red-700 hover:bg-red-600 text-white border-gray-500 font-semibold  py-2 px-10 border  hover:border-transparent rounded"
            >
              CLOSE
            </button>
          }
        >
          <p
            className={`mb-2 ${
              data.trade_result === 0 ? "text-green-700" : "text-red-700"
            }`}
          >
            ${data.trade_result === 0 ? "+" : "-"} ${data.net}
          </p>
          <div class="bg-gray-200">
            <table class="text-xs alert_table">
              <tr>
                <th>Buy</th>
                <th>Time</th>
                <th>Opening price</th>
                <th>Result</th>
              </tr>
              <tr>
                <td>${data.type_order === 0 ? "HIGHER" : "LOWER"}</td>
                <td>
                  $
                  {dayjs(data.opening_time).diff(
                    dayjs(data.closing_time, "minute")
                  )}
                </td>
                <td>${data.opening_price}</td>
                <td>
                  ${data.trade_result === 0 ? "+" : "-"} ${data.net}
                </td>
              </tr>
            </table>
          </div>
        </Modal>
      ) : (
        <></>
      )} */}
      
      <Countdown date={date} renderer={renderer} />
    </>
  );
};

export default Countdown_UI;
