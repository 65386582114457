import { useEffect, useState } from 'react';

import API_URL from "../../config/api";
import { useTranslation } from "react-i18next";


const ChatwootComponent = ({ messages }) => {

  const { t } = useTranslation();
  const [hash, setHash] = useState(null);
  const data = JSON.parse(localStorage.getItem("awesomeLeadsTokenUserdata"));


  useEffect(() => {

    const generateHash = async () => {
      if (data) {
        try {
          const response = await API_URL.post(`api/admin/generate-hmac`, { message: data.user.id.toString() });
          setHash(response.data.hash);
        } catch (error) {
          console.error('Error generating hash:', error);
        }
      }
    };

    generateHash();
  }, [data]);

  useEffect(() => {
    // if (!hash) return;


    const loadChatwoot = async () => {
      const BASE_URL = "https://app.chatwoot.com";
      const script = document.createElement("script");


      script.src = `${BASE_URL}/packs/js/sdk.js`;
      script.defer = true;
      script.async = true;



      script.onload = async () => {

        window.addEventListener("chatwoot:ready", async () => {
          // window.$chatwoot.toggle("open");

          if (data) {
            window.$chatwoot.setUser(`${data.user.id}`, {
              identifier_hash: hash,
              email: `${data.user.email}`,
              name: `${data.user.firstname}`,
              phone_number: `+66${data.user.phone.substring(1)}`,
              company_name: `${data.user.uid}`

            });
            window.$chatwoot.setCustomAttributes({
              accountId: `${data.user.id}`,
              pricingPlan: "paid",
            });
          }
          // else {
          //   window.$chatwoot.setUser("ผู้ใช้ทั่วไป", {
          //     email: "anonymous@dlt-th.com",
          //     name: "ผู้ใช้ทั่วไป",
          //   });
          // }

        });

        window.chatwootSDK.run({
          websiteToken: '179au54z7eYnJHE14q3LjVLc',
          baseUrl: BASE_URL
        });


      };

      document.head.appendChild(script);
    };

    window.chatwootSettings = {
      hideMessageBubble: false,
      position: "right",
      locale: `${t("language_chat")}`,
      type: (data ? "standard" : "expanded_bubble"),//expanded_bubble
      launcherTitle: `${t("chatwithus")}`,
      darkMode: "auto",
    };

    loadChatwoot();
  }, [hash, messages, data]);


  return null;
};

export default ChatwootComponent;
