import logo from './logo.svg';
import './App.css';
import MainWebsite from "./components/front/MainWebsite"


function App() {
  return (
    <MainWebsite />
  );
}

export default App;
