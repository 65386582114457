import React, { useState } from "react";
import Dashboard from "./Dashboard";
import Login_user from "./Login_user";
import { Routes, Route, useParams } from "react-router-dom";

function Front_main() {
  const [userId] = useState(localStorage.getItem("awesomeLeadsTokenId"));
  return (
    <div>
      {!userId ? (
        <Routes>
          <Route path="/:id" element={<Login_user />}></Route>
          <Route path="/" element={<Login_user />}></Route>
        </Routes>
      ) : (
        <Dashboard />
      )}
    </div>
  );
}

export default Front_main;
