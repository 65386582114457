import React,{useState} from "react";
import imageEmpty from "../../../image/emptyimg.png";

import { LazyLoadImage } from "react-lazy-load-image-component";

import { Segmented } from "antd";
import { useTranslation } from "react-i18next";

import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';

export default function Deposit({

  imgbank,
  bankdetail,
}) {
  const [tabValue, setTabValue] = useState(1);
  const { t } = useTranslation();
  // const copyurl = () => {
  //   navigator.clipboard.writeText(codebank);
  //   OpenNotification({ message: "คัดลอกเรียบร้อย", type: 1 });
  // };
  const Onchagebvalue = (value)=>{
    if(value===2){
      window.$chatwoot.toggle("open")
    }else{
      setTabValue(value)
    }
  
  }
  return (
    <div>
      {/* {userdata.verify_status === 0 ||
      userdata.verify_status === 2 ||
      userdata.verify_status === 3 ? (
        <div className="h-screen text-center">
          <div className="m-auto grid align-middle items-center">
            <Lottie
              animationData={idcard_ani}
              loop={true}
              className="w-3/5 lg:w-1/5  mx-auto "
            />
            <p className="text-white text-sm lg:text-lg mb-3">
              You must verify your identity before you can deposit.
            </p>
            <Link
              to="/login/verify"
              className="flex justify-center mx-auto w-full lg:w-1/2 my-2 h-50 btn-home text-white rounded-md shadow-lg shadow-gray-500/50"
            >
              <div className="w-full p-5">
                <div className="grid grid-cols-1 lg:grid-cols-2">
                  <div>
                    <p className="text-sm md:text-lg text-center lg:text-left">
                      Verify identity
                    </p>
                  </div>

                  <div className="mt-2 lg:mt-0 flex justify-center lg:justify-end text-sm align-middle items-center">
                    <p className="mr-3">Status :</p>
                    {userdata.verify_status === 0 ? (
                      <>
                        <DoNotDisturbOnIcon className="text-red-600 mr-2" />
                        <p>Identity not verified yet</p>
                      </>
                    ) : userdata.verify_status === 1 ? (
                      <>
                        <CheckCircleIcon className="text-green-600 mr-2" />
                        <p>Identity verified.</p>
                      </>
                    ) : userdata.verify_status === 2 ? (
                      <>
                        <CancelIcon className="text-red-600 mr-2" />
                        <p>Failed to verify identity. Try again.</p>
                      </>
                    ) : (
                      <>
                        <ChangeCircleIcon className="text-orange-400 mr-2" />
                        <p>Waiting for identity verification approval</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      ) : (     )}*/}
        <div className="deposit_page">
          <div className="px-2 lg:px-5">
          <Segmented
        block
        defaultValue={tabValue}
        value={tabValue}
        className="py-2 px-1 mt-2 lg:mt-0 "
        style={{
          marginBottom: 8,
        }}
        onChange={(value) => Onchagebvalue(value)}
        options={[
          {
            label: `USDT`,
            value: 1,
            icon: <MonetizationOnIcon />,
          },
          {
            label: `P2P`,
            value: 2,
            icon: <MarkUnreadChatAltIcon />,
          },
        ]}
      />
          </div>

          <div className="mb-2">
            <LazyLoadImage
              className="rounded-md  text-center mx-auto object-contain"
              style={{height:"60vh"}}
              src={
                imgbank !== null
                  ? `${imgbank}`
                  : null && imgbank !== null
                  ? `${imgbank}`
                  : null
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = imgbank !== null ? `${imgbank}` : null; // prevents looping
                currentTarget.src = imageEmpty;
              }}
              alt="trade"
            />
          </div>

          <div className="px-2 lg:px-10 w-full mb-2 ">
            <div className="bg-zinc-800 rounded-lg p-2 lg:px-5 lg:py-4 shadow-md text-white w-full lg:w-2/3 mx-auto">
              <div>
                <p className="text-sm lg:text-base ">{bankdetail}</p>
              </div>
            </div>
          </div>

        </div>

    </div>
  );
}
