import React, { useState, useContext, useEffect } from "react";
import PaymentsIcon from "@mui/icons-material/Payments";
import { DashboardContext } from "../Dashboard";
import API_URL from "../../../config/api";
import dayjs from "dayjs";
import { Table, Tag, Modal } from "antd";
import numberFormat from "../../../Function/NumberFormat2float";
import ErrorMessage from "../../../Toast/ErrorMessage";
import { OutlinedInput } from "@mui/material";
import Swal from "sweetalert2";
import Lottie from "lottie-react";

import success_ani from "../../../Json/success_ani.json";
import money_more_ani from "../../../Json/money_more.json";
import withdraw_ani from "../../../Json/withdraw_icon.json";
import loading_ani from "../../../Json/97930-loading.json";
import pinerror_ani from "../../../Json/pinerror.json";
import OpenNotification from "../../../Toast/OpenNotification";
import { useWebSocket } from "../WebSocketUser";
import { useTranslation } from "react-i18next";


function Withdraw({userdata}) {

  const { t } = useTranslation();

  const {sendMessage }   = useWebSocket();
  const {  get_user } = useContext(DashboardContext);
  const [allcredit, setAllcredit] = useState([]);
  const [amount, setAmount] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);

  const [msgErr1, setMsgErr1] = useState("");
  const [userId] = useState(localStorage.getItem("awesomeLeadsTokenId"));


  useEffect(() => {
    get_AllWithdraw();
  }, []);
  const get_AllWithdraw = async () => {
    setAllcredit([]);

    
    await API_URL.get(`api/deposit/getOneUserWithdraw/${userId}`)
      .then((res) => {
        const getalluser = res.data;
        if (getalluser.length !== 0) {
          getalluser.map((e) => {
            e.amounttxt = e.amount.toString();
            e.sliptimetxt = dayjs(e.sliptime)
              .format("DD/MM/YYYY HH:mm")
              .toString();
          });
          setAllcredit(getalluser);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const columns_alluser = [
    {
      title: `${t("status")}`,
      align: "center",
      width: "8%",
      render: (allcredit) => (
        <>
          {allcredit.status === 0 ? (
            <Tag color="geekblue">
              <p className="text-xs text-white my-auto px-2 py-1 font-semibold">
                {t("waiting")}
              </p>
            </Tag>
          ) : allcredit.status === 1 ? (
            <Tag color="green">
              <p className="text-xs text-white my-auto px-2 py-1 font-semibold">
                {t("approve")}
              </p>
            </Tag>
          ) : (
            <Tag color="volcano">
              <p className="text-xs text-white my-auto px-2 py-1 font-semibold">
                {t("notapproved")}
              </p>
            </Tag>
          )}
        </>
      ),
    },

    {
      title: `${t("time")}`,
      align: "center",
      render: (allcredit) => (
        <p className=" text-xs text-white my-auto px-2">
          {dayjs(allcredit.sliptime).format("DD/MM/YYYY")} {" "}
          {dayjs(allcredit.sliptime).format("HH:mm")}
        </p>
      ),
    },

    {
      title: `${t("credit")}`,
      align: "center",
      render: (allcredit) => (
        <p className="wsn text-xs text-white my-auto px-2">
          {numberFormat(allcredit.amount)}
        </p>
      ),
    },
    {
      title: `${t("oldamount")}`,
      align: "center",
      render: (allcredit) => (
        <p className="wsn text-xs text-white my-auto px-2">
          {numberFormat(allcredit.preamount)}
        </p>
      ),
    },
    {
      title: `${t("balance")}`,
      align: "center",
      render: (allcredit) => (
        <>
          <Tag color="orange">
            <p className="text-xs text-white my-auto px-2 py-1 font-semibold">
              {numberFormat(allcredit.net)}
            </p>
          </Tag>
        </>
      ),
    },
    {
      title: `${t("note")}`,
      align: "center",
      width: "30%",
      render: (allcredit) => (
          <p className="text-xs text-white my-auto  text-hover-show">
            {allcredit.note}
          </p>
  
      ),
    },
  ];

  const CreateWithdraw = async () => {
    if (userdata.idbank === 0) {
      Swal.fire({
        title: `<strong>${t("nobankaccount")}</strong>`,
        icon: "warning",
        html: `${t("linkyourbankaccount")}`,
        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText:
          `<a  rel="noopener noreferrer" href="/login/account" class="mx-auto flex justify-center text-white asty1">` +
          " <button>" +
          `<i class="fa fa-comments" aria-hidden="true"></i>&emsp;${t("gotobank")}</button></a>`,
        confirmButtonAriaLabel: "Thumbs up, great!",
        cancelButtonAriaLabel: "Thumbs down",
      });
      return;
    }
    if (amount === 0 || Number(amount) === 0||amount==="0") {
      setMsgErr1(`${t("enter_the_correct_amount")}`);
      return;
    }
    setMsgErr1("");
    if (amount > userdata.credit) {
      setMsgErr1(`${t("insufficientwithdrawalamount")}`);
      return;
    }
    setMsgErr1("");
    setIsConfirm(!isConfirm);
  };
  const ConfirmWithdraw = async () => {
    setIsConfirm(!isConfirm);
    let secondsToGo = 6;
    const modal = Modal.info({
      title: "",
      okButtonProps: {
        disabled: true,
        className: "modal-footer-hiden-button hidden",
      },
      content: (
        <div className="w-full mx-auto -ml-3">
          {" "}
          <Lottie
            animationData={loading_ani}
            loop={true}
            className="w-2/5 mx-auto "
          />
          <p className="text-center text-base font-semibold my-2">
          {t("inprogress")}
          </p>
        </div>
      ),
    });
    const timer = setInterval(async () => {
      secondsToGo -= 1;

      if (secondsToGo === 3) {
        await API_URL.post(`api/deposit/addWithdraw`, {
          peopleId: userdata.id,
          amount: amount,
          // pincode: pincode,
        })
          .then(() => {
            modal.update({
              title: "",
              content: (
                <div className="w-full mx-auto -ml-3">
                  {" "}
                  <Lottie
                    animationData={success_ani}
                    loop={false}
                    className="w-2/5 mx-auto "
                  />
                  <p className="text-center text-base font-semibold my-2">
                    {t("waitingforadminapproval")}
                  </p>
                </div>
              ),
            });
            setTimeout(() => {
              get_user();
              modal.destroy();
              get_AllWithdraw();
              sendMessage({ type: "refreshUserWithdraw" });
           
            }, 4000);
          })
          .catch((err) => {
            err.response.data.status === 402
              ? modal.update({
                  title: "",
                  content: (
                    <div className="w-full mx-auto -ml-3">
                      <Lottie
                        animationData={pinerror_ani}
                        loop={false}
                        className="w-2/5 mx-auto "
                      />
                      <p className="text-center text-base font-semibold my-2">
                        รหัส PIN ไม่ถูกต้อง
                      </p>
                    </div>
                  ),
                })
              : err.response.data.status === 401
              ? modal.update({
                  title: "",
                  content: (
                    <div className="w-full mx-auto -ml-3">
                      <Lottie
                        animationData={money_more_ani}
                        loop={false}
                        className="w-2/5 mx-auto "
                      />
                      <p className="text-center text-base font-semibold my-2">
      
                        {t("insufficientwithdrawalamount")}
                      </p>
                    </div>
                  ),
                })
              : err.response.data.status === 400
              ? modal.update({
                  title: "",
                  content: (
                    <div className="w-full mx-auto -ml-3">
                      <Lottie
                        animationData={pinerror_ani}
                        loop={false}
                        className="w-2/5 mx-auto "
                      />
                      <p className="text-center text-base font-semibold my-2">
                        {t("withdrawalspendingapproval")}
                      </p>
                    </div>
                  ),
                })
              : OpenNotification({ message: `${t("data_transmission_error")}`, type: 4 });
            setTimeout(() => {
              setAmount(0);
              modal.destroy();
            }, 3000);
          });
      }
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
    }, secondsToGo * 1000);
  };
  return (
    <div>
      <Modal
        title=""
        open={isConfirm}
        onCancel={() => setIsConfirm(!isConfirm)}
        footer={null}
      >
        <div className="flex justify-center ">
          <Lottie className="w-1/2" animationData={withdraw_ani} loop={true} />
        </div>

        <div className="flex justify-center">
          <button
            onClick={ConfirmWithdraw}
            type="button"
            className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
          >
            {t("confirm_withdrawal")}
          </button>
          <button
            onClick={() => setIsConfirm(!isConfirm)}
            type="button"
            className="text-white bg-gradient-to-r hover:bg-zinc-700  border-gray-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
          >
            {t("cancel")}
          </button>
        </div>
      </Modal>
      <div className="w-full lg:w-1/2 grid grid-cols-1 lg:grid-cols-4 justify-center mx-auto bg-zinc-700 p-2 rounded-sm">
        <div className=" my-2 items-center w-full col-span-3 ">
          <div className="w-full  mx-0 lg:mx-2 my-2">
            <p className="flex justify-start  text-base my-auto text-white">
            {t("amount_to_withdraw")} 
            </p>
          </div>
          <div className="flex relative w-full lg:w-3/4">
            <OutlinedInput
              value={amount}
              placeholder="0"
              onChange={(e) => setAmount(e.target.value)}
              className="block w-full  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md   "
              aria-describedby="outlined-weight-helper-text"
              onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
              autoComplete="off"
              fullWidth
              inputProps={{ maxLength: 10 }}
            />
            <div className="absolute right-2  bottom-0 top-0 flex items-center">
              <p className="my-auto text-xl font-medium ">USDT</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap align-bottom items-end w-full">
          {msgErr1 ? (
            <ErrorMessage message={msgErr1} className="w-full" />
          ) : (
            <></>
          )}
          <button
            onClick={CreateWithdraw}
            className="text-center text-white font-semibold  w-full h-8 lg:h-11 rounded-md bg-green-500 hover:bg-green-600 shadow-md"
          >
            {t("withdraw")}
          </button>
        </div>
      </div>

      <div>
        <div className="w-full">
          <div className="w-full px-1 md:px-8">
            <ul
              className="flex mb-0 list-none  pt-3 pb-0 flex-row "
              role="tablist"
            >
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center ">
                <a className="text1line text-sm md:text-md font-bold uppercase px-2 py-2 lg:py-4 shadow-lg rounded-t-lg  block leading-normal text-white border-blue-900 border-2">
                  <PaymentsIcon className="mr-2" />
                  {t("withdrawal_history")}
                </a>
              </li>
            </ul>
            <div className="h-1 bg-blue-900"></div>
            <div className="relative flex flex-col min-w-0 break-words  w-full mb-6  ">
              <div className="px-0.5 lg:px-1 py-1 flex-auto">
                <div className="tab-content tab-space">
                  <div className="block">
                    <Table
                      className="table-striped-rows my-1"
                      size="middle"
                      scroll={{
                        x: 800,
                      }}
                      pagination={{ pageSize: 10 }}
                      rowKey="id"
                      columns={columns_alluser}
                      dataSource={allcredit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Withdraw;
