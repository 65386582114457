import React, { useState, useEffect } from "react";
import OpenNotification from "../../../Toast/OpenNotification";
import { Pagination } from "@mui/material";
import numberFormat from "../../../Function/NumberFormat2float";
import dayjs from "dayjs";
import API_URL from "../../../config/api";


import AutorenewIcon from "@mui/icons-material/Autorenew";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import { getCurrentUser } from "../../../services/auth.service";
import BankSwitch from "../../../Function/BankSwitch";
import { useWebSocket } from "../WebSocketContext";

function Withdraw() {
  const { websocket, sendMessage } = useWebSocket();

  const token = getCurrentUser();
  // const [usercredit, setUsercredit] = useState({});
  const [allcredit, setAllcredit] = useState([]);
  const [allcreditBackup, setAllcreditBackup] = useState([]);
  const [note, setNote] = useState("");
  // const [isSlipModal, setIsSlipModal] = useState(false);

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const itemcount = 6;

  useEffect(() => {
    get_AllCreditAdmin();
    // const interval = setInterval(() => {
    //   get_AllCreditAdmin();
    // }, 30000);

    // return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (websocket) {
      websocket.onmessage = (message) => {
        const data = JSON.parse(message.data);
        if (data.type === "refreshUserWithdraw") {
          get_AllCreditAdmin();
        }
      };
    }

    return () => {
      if (websocket) {
        websocket.onmessage = null;
      }
    };
  }, [websocket]);
  const handleNote = (e, id) => {
    var result = [...allcredit];
    result = result.map((x) => {
      if (x.id === id) {
        x.note = e.target.value;
        return x;
      } else return x;
    });
    setAllcredit(result);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    if (value > 1) {
      setAllcredit(
        allcreditBackup.slice((value - 1) * itemcount, itemcount * value)
      );
    } else {
      setAllcredit(allcreditBackup.slice(0, itemcount));
    }
  };

  const get_AllCreditAdmin = async () => {
    setAllcredit([]);
    setAllcreditBackup([]);
    await API_URL.get(`api/deposit/allWithdrawPending`)
      .then((res) => {
        const getalluser = res.data;
        if (getalluser.length !== 0) {
          getalluser.map((e) => {
            e.amount = e.amount.toString();
          });
          setAllcredit(getalluser.slice(0, itemcount));
          setAllcreditBackup(getalluser);
          setPageCount(
            getalluser.length / itemcount < 1
              ? 1
              : Math.ceil(getalluser.length / itemcount)
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Updatewithdraw = async (data, status) => {
    await API_URL.put(`api/deposit/updateWithdraw`, {
      id: data.id,
      peopleId: data.peopleId,
      status: status,
      amount: data.amount,
      userId: token.id,
      note: data.note,
    })
      .then((res) => {
        OpenNotification({ message: "เสร็จสิ้น", type: 1 });
        sendMessage({ type: "refreshUserWithdraw" });
        return res.data;
      })
      .catch((err) => {
        OpenNotification({ message: "เกิดข้อผิดพลาด", type: 4 });
      });
  };
  return (
    <div>
      <button
        onClick={get_AllCreditAdmin}
        className="bg-black hover:bg-gray-800 text-white shadow-md font-bold py-2 px-4 rounded inline-flex items-center my-2"
      >
        <AutorenewIcon className="fill-current w-4 h-4 mr-2"></AutorenewIcon>
        <span>โหลดข้อมูลใหม่</span>
      </button>
      {allcredit.length === 0 ? (
        <div className="mx-auto flex-col justify-center w-full h-screen text-center my-auto items-center">
          <FolderOffIcon fontSize="large" />
          <p className="text-2xl text-center font-bold">ไม่มีข้อมูลรายการถอน</p>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3">
            {allcredit.map((res, index) => (
              <div
                key={index}
                className="flex-col w-full px-1 py-1 lg:px-3 lg:py-4"
              >
                <div className="bg-white rounded-md shadow-md w-full px-2 py-2">
                  <div className="h-4/5 w-full items-center  my-auto ">
                    <div className="w-full flex px-2 lg:px-0">
                      <div className="bg-orange-100  py-1 lg:py-4 px-2 rounded-md w-2/4">
                        <div className=" my-0.5   mx-auto justify-start">
                          {" "}
                          <p className="wsn font-semibold text-xs">
                            เวลาแจ้งถอน :{" "}
                          </p>
                          <p className=" text-sm">
                            {dayjs(res.sliptime).format("DD/MM/YYYY")} -{" "}
                            {dayjs(res.sliptime).format("HH:mm")}
                          </p>
                        </div>

                        <div className=" my-0.5  mx-auto justify-start">
                          {" "}
                          <p className="wsn font-semibold text-xs">จำนวน :</p>
                          <div className=" flex items-center">
                          <p className="wsn text-lg font-semibold underline mr-2">
                            {numberFormat(res.amount)} 
                          </p>
                          <p className="wsn text-xs  ">
                            USDT
                          </p>
                          </div>
                     
                        </div>
                      </div>
                      <div className=" mt-3 ml-1 mx-auto justify-start w-2/4">
                        {" "}
                        <p className="wsn font-semibold text-xs my-auto truncate">
                          UID :
                        </p>
                        <p className="wsn text-base truncate">
                          {res.people.uid}
                        </p>
                        <div className=" my-1 flex mx-auto ">
                          {" "}
                          <p className="wsn font-semibold text-xs my-auto">
                            ยอดเดิม :&nbsp;&nbsp;&nbsp;
                          </p>
                          <p className="wsn text-xs truncate">
                            {numberFormat(res.preamount)} 
                          </p>
                        </div>
                        <div className=" my-1  mx-auto justify-start">
                          {" "}
                          <p className="wsn font-semibold text-xs my-auto">
                            ยอดถอนคงเหลือ :
                          </p>
                          <p className="wsn text-base truncate">
                            {numberFormat(res.people.credit)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className=" mt-1 mb-3  w-full">
                        <div className="wsn my-1 flex justify-start w-full ">
                          {BankSwitch(res.people.idbank)}
                        </div>
                        <div className="flex my-1  justify-start">
                          <p className="wsn font-semibold text-sm my-auto truncate">
                            หมายเลขบัญชี :&nbsp;&nbsp;&nbsp;
                          </p>
                          <p className="wsn text-base ">
                            {res.people.codebank}
                          </p>
                        </div>
                        <div className="flex my-1  justify-start">
                          {" "}
                          <p className="wsn font-semibold text-xs my-auto">
                            ชื่อ-นามสกุลธนาคาร :&nbsp;&nbsp;&nbsp;
                          </p>
                          <p className="wsn text-base ">
                            {res.people.firstname} {res.people.lastname}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="my-auto col-span-2">
                    <textarea
                      name="bankdetail"
                      rows="1"
                      value={res.note}
                      onChange={(e) => handleNote(e, res.id)}
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-sky-500"
                      placeholder="หมายเหตุ"
                    ></textarea>
                  </div>
                  <div className="h-1/5 flex items-center w-full">
                    <div className="grid grid-cols-2 w-full">
                      <button
                        onClick={() => Updatewithdraw(res, 1)}
                        className="mx-1 py-2 bg-green-600 rounded-md hover:bg-green-700 shadow-md text-white font-medium"
                      >
                        อนุมัติ
                      </button>
                      <button
                        onClick={() => Updatewithdraw(res, 2)}
                        className="mx-1 py-2 text-red-800 rounded-md bg-transparent hover:bg-red-800 hover:text-white border border-red-800 font-semibold"
                      >
                        ไม่อนุมัติ
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Pagination
            count={pageCount}
            page={page}
            onChange={handleChangePage}
            size="large"
            color="primary"
            className="flex justify-center mt-5"
          />
        </>
      )}
    </div>
  );
}

export default Withdraw;
