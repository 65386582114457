import React, { useRef } from "react";
import Countdown from "react-countdown";

import API_URL from "../../../config/api";

const Countdown_UI = ({ id, date ,get_UserAllTradeAdmin}) => {
  const hasAlerted = useRef(false);
  let data = useRef(null);
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed && !hasAlerted.current) {
      hasAlerted.current = true;
      TradeTimeout();
    } else {
      return (
        <></>
        // <p className="text-center text-xs  font-semibold p-1 bg-slate-200 rounded-md border-2 border-slate-800">
        //   {hours} ชั่วโมง {minutes} นาที {seconds} วินาที
        // </p>
      );
    }
  };
  const TradeTimeout = async () => {
    await API_URL.post(`api/tradelist/getOneUserTradingTimeout`, {
      id: id,
    })
      .then(async (res) => {
        get_UserAllTradeAdmin();
      })
      .catch((err) => {});
  };

  return (
    <>
      <Countdown date={date} renderer={renderer} />
    </>
  );
};

export default Countdown_UI;
