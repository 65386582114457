import React, { useState } from "react";
import { Modal } from "antd";
import imageEmpty from "../../../image/emptyimg.png";
import dayjs from "dayjs";
import ErrorMessage from "../../../Toast/ErrorMessage";
import { OutlinedInput } from "@mui/material";
import configapi from "../../../config/configapi";


function SlipModal({
  showSlipModal,
  showSlipModalClose,
  isSlipModal,
  allcredit,
}) {
  const [amount, setAmount] = useState(allcredit.amount);
  const [msgErr1, setMsgErr1] = useState("");
  const URL_HOST = `${configapi.API_SERVER}`;


  return (
    <Modal
      style={{
        top: 10,
      }}
      title={"สลิป"}
      open={isSlipModal}
      onOk={showSlipModal}
      onCancel={showSlipModalClose}
      width={700}
      key={0}
      footer={[null]}
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 ">
        <img
          className="rounded-sm mx-auto "
          src={
            allcredit.imageslip !== null
              ? `${URL_HOST}${allcredit.imageslip}`
              : null && allcredit.imageslip !== null
              ? `${URL_HOST}${allcredit.imageslip}`
              : null
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror =
              allcredit.imageslip !== null
                ? `${URL_HOST}${allcredit.imageslip}`
                : null; // prevents looping
            currentTarget.src = imageEmpty;
          }}
          
          alt="nice2"
        />
        <div className="w-full  my-auto px-1 lg:px-3">
          <div className="md:flex my-2   items-center w-full">
            <div className="w-2/6 mx-0 lg:mx-2 my-2">
              <p className="flex justify-start lg:justify-end text-base my-auto">
                uid :
              </p>
            </div>
            <div className="w-full lg:w-4/6">
              <p className="text1line flex justify-start lg:justify-end text-base font-semibold my-auto">
                {allcredit.people.uid}
              </p>
            </div>
          </div>
          <div className="md:flex my-2   items-center w-full">
            <div className="w-2/6 mx-0 lg:mx-2 my-2">
              <p className="flex justify-start lg:justify-end text-base my-auto">
                ชื่อบัญชี :
              </p>
            </div>
            <div className="w-full lg:w-4/6">
              <p className="text1line flex justify-start lg:justify-end text-base font-semibold my-auto">
                {allcredit.people.firstname} {allcredit.people.lastname}
              </p>
            </div>
          </div>
          <div className="md:flex my-2   items-center w-full">
            <div className="w-2/6 mx-0 lg:mx-2 my-2">
              <p className="flex justify-start lg:justify-end text-base my-auto">
                เวลาโอน :
              </p>
            </div>
            <div className="w-full lg:w-4/6">
              <p className="text1line flex justify-start lg:justify-end text-base font-semibold my-auto">
                {dayjs(allcredit.sliptime).format("DD/MM/YYYY")} เวลา{" "}
                {dayjs(allcredit.sliptime).format("HH:mm")}
              </p>
            </div>
          </div>
          <div className="md:flex my-2 items-center w-full">
            <div className="w-1/4  mx-0 lg:mx-2 my-2">
              <p className="flex justify-start lg:justify-end text-base my-auto">
                จำนวนเงิน
              </p>
            </div>
            <div className="flex relative w-full lg:w-3/4">
              <OutlinedInput
                value={amount}
                // onChange={(e) => setAmount(e.target.value)}
                className="block w-full  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md   "
                aria-describedby="outlined-weight-helper-text"
                onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
                autoComplete="off"
                fullWidth
                inputProps={{ maxLength: 10 }}
              />
              <div className="absolute right-2  bottom-0 top-0 flex items-center">
                <p className="my-auto text-xl font-medium ">บาท</p>
              </div>
            </div>
          </div>
          {msgErr1 ? <ErrorMessage message={msgErr1} /> : <></>}
          {/* {allcredit.status === 0 ? (
            <div className=" flex items-center w-full">
              <div className="grid grid-cols-2 w-full">
                <button className="mx-1 py-2 bg-green-600 rounded-md hover:bg-green-700 shadow-md text-white font-medium">
                  อนุมัติ
                </button>
                <button className="mx-1 py-2 text-red-800 rounded-md bg-transparent hover:bg-red-800 hover:text-white border border-red-800 font-semibold">
                  ไม่อนุมัติ
                </button>
              </div>
            </div>
          ) : (
            <></>
          )} */}
        </div>
      </div>
    </Modal>
  );
}

export default SlipModal;
