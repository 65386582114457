import React, { useState, useEffect, useRef, createContext } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  CreditCardOutlined,
  HomeOutlined,
  UserOutlined,
  AppstoreOutlined,
  LogoutOutlined,
  OrderedListOutlined,
  AreaChartOutlined,
  TeamOutlined,
  CalculatorOutlined,
  SettingFilled,
  SafetyOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { Layout, Menu, Button, theme } from "antd";

import Home from "./Home/Home";
import User_all from "./User/User_all";
import Credit from "./Credit/MainCredit";

import Transaction from "./Transaction/MainTransaction";
import Trading from "./Trading/Trading";
import Payrate from "./Payrate/Payrate";
import Contact from "./Contact/Contact";
import Admin_list from "./Admin_list/Admin";
import { logout, getCurrentUser } from "../.././services/auth.service";
import VerifyUser from "./VerifyUser/VerifyUser";
import Userdetail from "./User/Userdetail/Userdetail";

import { WebSocketProvider } from "./WebSocketContext";
import authHeader from "../../services/auth-header";
import Account from "./Account/Account";
import Logoweb from "../../Function/Logoweb";
import Nameweb from "../../Function/Nameweb";

const { Header, Sider, Content, Footer } = Layout;
export const DashboardContext = createContext();
function Dashboard() {
  let urlpath = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const [oldpath, setOldpath] = useState(urlpath.pathname);

  const [token] = useState(getCurrentUser());

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleLogout = () => {
    window.location.href = "/admin";
    logout();
  };

  let items_mod = [
    {
      key: "/admin",
      name: "หน้าแรก",
      icon: <HomeOutlined />,
      label: (
        <Link to="/admin">
          <span className="nav-text">หน้าแรก</span>
        </Link>
      ),
    },
    {
      key: "/admin/user",
      name: "สมาชิก",
      icon: <TeamOutlined />,

      label: (
        <Link to="/admin/user">
          <span className="nav-text">สมาชิก</span>
        </Link>
      ),
    },
    {
      key: "/admin/credit",
      name: "จัดการเครดิต",
      icon: <CreditCardOutlined />,
      label: (
        <Link to="/admin/credit">
          <span className="nav-text">จัดการเครดิต</span>
        </Link>
      ),
    },
    {
      key: "/admin/transaction",
      name: "รายการถอน",
      icon: <AppstoreOutlined />,
      label: (
        <Link to="/admin/transaction">
          <span className="nav-text">รายการถอน</span>
        </Link>
      ),
    },
    {
      key: "/admin/trading",
      name: "รายการเทรด",
      icon: <OrderedListOutlined />,
      label: (
        <Link to="/admin/trading">
          <span className="nav-text">รายการเทรด</span>
        </Link>
      ),
    },
    {
      key: "/admin/verify",
      name: "ยืนยันตัวตน",
      icon: <SafetyOutlined />,
      label: (
        <Link to="/admin/verify">
          <span className="nav-text">ยืนยันตัวตน</span>
        </Link>
      ),
    },

    // {
    //   key: "/admin/addstock",
    //   icon: <AreaChartOutlined />,
    //   label: (
    //     <Link to="/admin/addstock">
    //       <span className="nav-text">หุ้น</span>
    //     </Link>
    //   ),
    // },
    {
      key: "/admin/payrate",
      name: "อัตราจ่าย",
      icon: <CalculatorOutlined />,
      label: (
        <Link to="/admin/payrate">
          <span className="nav-text">อัตราจ่าย</span>
        </Link>
      ),
    },
    {
      key: "/admin/setting",
      name: "การตั้งค่า",
      icon: <SettingFilled />,
      label: (
        <Link to="/admin/setting">
          <span className="nav-text">การตั้งค่า</span>
        </Link>
      ),
    },
  ];
  if (token != null && token.roles[0] === "ROLE_MOD") {
    items_mod.push(
      {
        key: "/admin/management",
        name: "จัดการแอดมิน",
        icon: <SolutionOutlined />,
        label: (
          <Link to="/admin/management">
            <span className="nav-text">จัดการแอดมิน</span>
          </Link>
        ),
      },
      {
        key: "/admin/account",
        name: "จัดการบัญชี",
        icon: <UserOutlined />,
        label: (
          <Link to="/admin/account">
            <span className="nav-text">บัญชี</span>
          </Link>
        ),
      },
      {
        key: "8",
        name: "ออกจากระบบ",
        icon: <LogoutOutlined />,
        label: (
          <Link onClick={handleLogout}>
            <span className="nav-text">ออกจากระบบ</span>
          </Link>
        ),
      }
    );
  } else {
    items_mod.push(
      {
        key: "/admin/account",
        name: "จัดการบัญชี",
        icon: <UserOutlined />,
        label: (
          <Link to="/admin/account">
            <span className="nav-text">บัญชี</span>
          </Link>
        ),
      },
      {
        key: "8",
        name: "ออกจากระบบ",
        icon: <LogoutOutlined />,
        label: (
          <Link onClick={handleLogout}>
            <span className="nav-text">ออกจากระบบ</span>
          </Link>
        ),
      }
    );
  }

  // const [currentItem,setCurrentItem] = useState(items_mod.find(item => item.key === urlpath.pathname))
  let currentItem = items_mod.find((item) => item.key === urlpath.pathname);

  useEffect(() => {
    authHeader();

    currentItem = items_mod.find((item) => item.key === urlpath.pathname);
  }, [urlpath.pathname]);

  const mobileCLickMenu = () => {
    setCollapsed(!collapsed);
  };
  // useEffect(() => {
  //   if (oldpath !== urlpath.pathname) {
  //     setCollapsed(!collapsed);
  //     setOldpath(urlpath.pathname);
  //   }
  // }, [urlpath.pathname]);
  return (
    <WebSocketProvider>
      <DashboardContext.Provider>
        <Layout>
          <Sider
            trigger={null}
            collapsible
            collapsed={!collapsed}
            width={200}
            className="hidden lg:block"
          >
            <div className="mx-auto">
              <div className="mx-auto text-center h-7 lg:h-12 my-2 object-contain flex justify-center">
                <Logoweb />
              </div>
              <div className="text-white mb-4 text-center truncate">
                <Nameweb />
              </div>
            </div>

            <Menu
              className="h-screen"
              theme="dark"
              mode="inline"
              // onClick={()=>setCollapsed(!collapsed)}
              defaultSelectedKeys={[urlpath.pathname]}
              items={items_mod}
            ></Menu>
          </Sider>
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={200}
            className="block lg:hidden"
          >
            <div>
              <div className="mx-auto  text-center  h-12 my-2 object-contain flex justify-center">
                <Logoweb />
              </div>
              <div className="text-white mb-4 text-center truncate">
                <Nameweb />
              </div>
            </div>

            <Menu
              className="h-screen"
              theme="dark"
              mode="inline"
              subMenuCloseDelay={2}
              onClick={() => setCollapsed(!collapsed)}
              defaultSelectedKeys={[urlpath.pathname]}
              items={items_mod}
            ></Menu>
          </Sider>
          <Layout>
            <Header
              style={{
                padding: 0,
                background: colorBgContainer,
              }}
              className="flex"
            >
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                className="hidden lg:block"
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => mobileCLickMenu()}
                className="block lg:hidden"
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
              <p>{currentItem ? currentItem.name : ""}</p>
            </Header>
            <Content
              className="min-h-screen h-full w-full  min-w-full my-6 mx-1 p-2"
              style={{
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/user" element={<User_all />} />
                <Route path="/user/:peopleId" element={<Userdetail />}></Route>
                <Route path="/credit" element={<Credit />} />
                <Route path="/transaction" element={<Transaction />} />
                <Route path="/trading" element={<Trading />} />
                <Route path="/verify" element={<VerifyUser />} />
                <Route path="/payrate" element={<Payrate />} />
                <Route path="/setting" element={<Contact />} />
                <Route path="/management" element={<Admin_list />} />
                <Route path="/account" element={<Account />} />
              </Routes>
            </Content>
            <Footer
              style={{
                textAlign: "center",
              }}
              className="text-xs"
            >
              ©{new Date().getFullYear()} . ALL RIGHTS RESERVED.
            </Footer>
          </Layout>
        </Layout>
      </DashboardContext.Provider>
    </WebSocketProvider>
  );
}

export default Dashboard;
