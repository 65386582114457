import React, { useEffect, useRef } from 'react';

const CryptoCarousel = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    // script.src = "https://s3.tradingview.com/external-embedding/embed-widget-tickers.js";
    // script.src = "https://s3.tradingview.com/external-embedding/embed-widget-single-quote.js";
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = JSON.stringify({
      "symbols": [
        {
          "proName": "FX:EURUSD",
          "title": "EUR/USD"
        },
        {
          "proName": "FX:GBPUSD",
          "title": "GBP/USD"
        },

        {
          "proName": "BINANCE:BTCUSDT",
          "title": "Bitcoin"
        },
        {
          "proName": "BINANCE:ETHUSDT",
          "title": "Ethereum"
        },
        {
          "proName": "BINANCE:SHIBUSDT",
          "title": "Shiba Inu"
        },
        {
          "proName": "BINANCE:BNBUSDT",
          "title": "Binance Coin"
        },
        {
          "proName": "BINANCE:DOGEUSDT",
          "title": "Dogecoin"
        },
        {
          "proName": "BINANCE:SOLUSDT",
          "title": "Solana"
        },
        {
          "proName": "BINANCE:XRPUSDT",
          "title": "XRP"
        },
        {
          "proName": "BINANCE:ADAUSDT",
          "title": "Cardano"
        },
        {
          "proName": "BINANCE:DOTUSDT",
          "title": "Polkadot"
        },
        {
          "proName": "BINANCE:LTCUSDT",
          "title": "Litecoin"
        },
        {
          "proName": "BINANCE:LINKUSDT",
          "title": "Chainlink"
        },
        {
          "proName": "BINANCE:AVAXUSDT",
          "title": "Avalanche"
        },
        {
          "proName": "BINANCE:MATICUSDT",
          "title": "Polygon"
        },
        {
          "proName": "BINANCE:ATOMUSDT",
          "title": "Cosmos"
        },
        {
            "proName": "FX:AUDUSD",
            "title": "AUD/USD"
          },
          {
            "proName": "FX:USDJPY",
            "title": "USD/JPY"
          },
          {
            "proName": "FX:USDCAD",
            "title": "USD/CAD"
          },
        
      ],
      "showSymbolLogo": true,
      "colorTheme": "dark",
      "isTransparent": true,
      "displayMode": "adaptive",
      "locale": "en",
    });

    containerRef.current.appendChild(script);

    return () => {
      if (containerRef.current) {
        const scriptElement = containerRef.current.querySelector('script');
        if (scriptElement) {
          containerRef.current.removeChild(scriptElement);
        }
      }
    };
  }, []);

  return (
    <div className="tradingview-widget-container" ref={containerRef} style={{ height: "56px" }}>
      <div className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright">
      </div>
    </div>
  );
};

export default CryptoCarousel;