// VerticalCarousel.js
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const VerticalCarousel = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [transitioning, setTransitioning] = useState(false);
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    setCardData(
      Array(6)
        .fill()
        .map(() => ({
          avatar: getRandomAvatar(),
          name: getRandomName(),
          rating: getRandomRating(),
          comment: getRandomComment(),
          
          
        }))
    );
  }, []);
  const getRandomAvatar = () =>
    `https://i.pravatar.cc/40?img=${Math.floor(Math.random() * 70)}`;

  const getRandomName = () => {
    const names = [
      "Alex",
      "Sam",
      "Jordan",
      "Taylor",
      "Casey",
      "Morgan",
      "Jamie",
      "Riley",
      "Avery",
      "Quinn",
      "Skyler",
      "Parker",
      "Blake",
      "Charlie",
      "Emerson",
    ];
    return names[Math.floor(Math.random() * names.length)];
  };

  const getRandomRating = () => Math.floor(Math.random() * 2) + 4;
  const getRandomComment = () => {
    const comments = [
      `${t("comments1")}`,
      `${t("comments2")}`,
      `${t("comments3")}`,
      `${t("comments4")}`,
      `${t("comments5")}`,
      `${t("comments6")}`,
      `${t("comments7")}`,
      `${t("comments8")}`,
      `${t("comments9")}`,
      `${t("comments10")}`,
      `${t("comments11")}`,
      `${t("comments12")}`,
      `${t("comments13")}`,
      `${t("comments14")}`,
      `${t("comments15")}`,
    ];
    return comments[Math.floor(Math.random() * comments.length)];
  };


  useEffect(() => {
    if (transitioning) {
      const timer = setTimeout(() => setTransitioning(false), 500);
      return () => clearTimeout(timer);
    }
  }, [transitioning]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % 6);
      setTransitioning(true);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const goToSlide = (index) => {
    if (index !== activeIndex) {
      setTransitioning(true);
      setActiveIndex(index);
    }
  };

  return (
    <div className="flex items-center justify-center h-80">
      <div className="relative w-full md:w-72 h-48 shadow-lg rounded-lg overflow-hidden bg-white">
        {cardData.map((card, index) => (
          <div
            key={index}
            className={`absolute top-0 left-0 w-full h-full flex flex-col justify-between p-4 transition-opacity duration-500 ${
              index === activeIndex
                ? transitioning
                  ? "opacity-0"
                  : "opacity-100"
                : "opacity-0 pointer-events-none"
            }`}
          >
            {/* <h1 className="text-center text-lg font-bold font-mono">CUSTOMER REVIEW</h1> */}
            <div className="flex items-center">
              <img
                src={card.avatar}
                alt="Avatar"
                className="w-12 h-12 rounded-full mr-3"
              />
              <div>
                <span className="font-semibold text-lg">{card.name}</span>
                <div className="flex">
                  {[...Array(5)].map((_, i) => (
                    <svg
                      key={i}
                      className={`w-8 h-8 ${
                        i < card.rating ? "text-yellow-400" : "text-gray-300"
                      }`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                  ))}
                </div>
              </div>
            </div>
            <p className="text-lg text-gray-600 mt-3 flex-grow">
              {card.comment}
            </p>
          </div>
        ))}
      </div>
      <div className="flex flex-col items-center ml-4">
        {Array(6)
          .fill()
          .map((_, index) => (
            <button
              key={index}
              onClick={() => goToSlide(index)}
              className={`w-3 h-3 rounded-full my-1 transition-colors duration-300 ${
                index === activeIndex
                  ? "bg-blue-500"
                  : "bg-gray-300 hover:bg-gray-400"
              }`}
            />
          ))}
      </div>
    </div>
  );
};

export default VerticalCarousel;
