import React, { useState, useEffect } from "react";
import { OutlinedInput, Divider, IconButton } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import API_URL from "../../../config/api";
import OpenNotification from "../../../Toast/OpenNotification";

import bankic from "../../../icon/bankic.webp";
import social1 from "../../../icon/fb_logo.png";
import social2 from "../../../icon/line.webp";
import social3 from "../../../icon/website.png";
import notice_img from "../../../icon/notice_icon.png";
import aboutus_img from "../../../icon/aboutus_icon.png";
import social4 from "../../../icon/gmail.png";
import { getCurrentUser } from "../../../services/auth.service";
import apiForm from "../../../config/apiForm";
import config from "../../../config/configapi";

import CampaignIcon from "@mui/icons-material/Campaign";
function Contact() {
  const URL_HOST = `${config.API_SERVER}`;
  const [facebookURL, setFacebookURL] = useState("");
  const [lineURL, setLineURL] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [gmailURL, setGmailURL] = useState("");
  const [idbank, setIdbank] = useState("");
  const [codebank, setCodebank] = useState("");
  const [namebank, setNamebank] = useState("");
  const [bankdetail, setBankdetail] = useState("");
  const [webname, setWebname] = useState("");
  const [aboutus, setAboutus] = useState("");
  const [notice, setNotice] = useState("");

  const [imgbank, setImgbank] = useState({});
  const [imagePreviewbank, setImagePreviewbank] = useState(null);
  const [imgbankBackup, setImgbankBackup] = useState({});
  const [checkimgbank, setCheckimgbank] = useState(false);

  const [imglogoweb, setImglogoweb] = useState({});
  const [imagePreviewlogoweb, setImagePreviewlogoweb] = useState(null);
  const [imglogowebBackup, setImglogowebBackup] = useState({});
  const [checkimglogoweb, setCheckimglogoweb] = useState(false);

  const [token] = useState(getCurrentUser());

  useEffect(() => {
    clearForm();
    getWebUrl();
  }, []);

  const clearForm = async () => {
    setFacebookURL("");
    setLineURL("");
    setWebsiteURL("");
    setGmailURL("");
    setIdbank("");
    setCodebank("");
    setNamebank("");
    setBankdetail("");
    setWebname("");
    setAboutus("");
    setNotice("");
    setImgbankBackup({});
    setImglogowebBackup({});
  };

  const getWebUrl = async () => {
    await API_URL.get(`api/weburl/getallweburl`)
      .then((res) => {
        const allweburl = res.data;
        if (allweburl.length !== 0) {
          const resFacebook = allweburl.find(({ name }) => name === "facebook");
          setFacebookURL(resFacebook.nameurl);
          const resLine = allweburl.find(({ name }) => name === "line");
          setLineURL(resLine.nameurl);
          const resWebsite = allweburl.find(({ name }) => name === "website");
          setWebsiteURL(resWebsite.nameurl);
          const resGmail = allweburl.find(({ name }) => name === "gmail");
          setGmailURL(resGmail.nameurl);
          const residbank = allweburl.find(({ name }) => name === "idbank");
          setIdbank(residbank.nameurl);
          const rescodebank = allweburl.find(({ name }) => name === "codebank");
          setCodebank(rescodebank.nameurl);
          const resnamebank = allweburl.find(({ name }) => name === "namebank");
          setNamebank(resnamebank.nameurl);
          const resimgbank = allweburl.find(({ name }) => name === "imgbank");
          setImagePreviewbank(
            resimgbank.nameurl !== null
              ? `${URL_HOST}${resimgbank.nameurl}`
              : null
          );
          setImgbankBackup(resimgbank.nameurl);
          const resimglogoweb = allweburl.find(
            ({ name }) => name === "imglogoweb"
          );
          setImagePreviewlogoweb(
            resimglogoweb.nameurl !== null
              ? `${URL_HOST}${resimglogoweb.nameurl}`
              : null
          );
          setImglogowebBackup(resimglogoweb.nameurl);
          const reswebname = allweburl.find(({ name }) => name === "webname");
          setWebname(reswebname.nameurl);
          const resbankdetail = allweburl.find(
            ({ name }) => name === "bankdetail"
          );
          setBankdetail(resbankdetail.nameurl);
          const resaboutus = allweburl.find(({ name }) => name === "aboutus");
          setAboutus(resaboutus.nameurl);
          const resnotice = allweburl.find(({ name }) => name === "notice");
          setNotice(resnotice.nameurl);
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdateWebUrl = async (e) => {
    e.preventDefault();
    try {
      if (checkimgbank && imgbankBackup !== null) {
        let string = "";
        const array = imgbankBackup.split("\\");
        string = "./" + array.join("/");

        await API_URL.post(`api/weburl/deleteimgbank`, {
          imgbankbackup: string,
        });
      }
    } catch (e) {}
    try {
      if (checkimglogoweb && imglogowebBackup !== null) {
        let string = "";
        const array = imglogowebBackup.split("\\");
        string = "./" + array.join("/");

        await API_URL.post(`api/weburl/deleteimglogoweb`, {
          imglogowebbackup: string,
        });
      }
    } catch (e) {}

    const formData = new FormData();
    formData.append("imgbank", imgbank);
    formData.append("imglogoweb", imglogoweb);
    formData.append("facebookURL", facebookURL);
    formData.append("websiteURL", websiteURL);
    formData.append("lineURL", lineURL);
    formData.append("gmailURL", gmailURL);
    formData.append("idbank", idbank);
    formData.append("codebank", codebank);
    formData.append("namebank", namebank);
    formData.append("bankdetail", bankdetail);
    formData.append("aboutus", aboutus);
    formData.append("webname", webname);
    formData.append("notice", notice);

    formData.append("checkimgbank", checkimgbank);
    formData.append("checkimglogoweb", checkimglogoweb);

    await apiForm
      .put(`api/weburl/updateweburl`, formData)
      .then((res) => {
        clearForm();
        OpenNotification({ message: "แก้ไขเรียบร้อย", type: 1 });
        window.location.reload();

        return res.data;
      })
      .catch((err) => {
        OpenNotification({ message: "มีข้อผิดพลาด", type: 3 });
      });
  };

  const handleUploadImgbank = (e) => {
    if (e.target.files[0].size > 20315594) {
      OpenNotification({ message: "ขนาดรูปภาพต้องไม่เกิน 20 MB", type: 3 });
      return;
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImgbank(file);
      setImagePreviewbank(reader.result);
      setCheckimgbank(true);
    };
    reader.readAsDataURL(file);
  };
  const handleDelImgbank = (e) => {
    setImgbank({});
    setImagePreviewbank(null);
    setCheckimgbank(true);
  };
  const handleUploadImglogoweb = (e) => {
    if (e.target.files[0].size > 20315594) {
      OpenNotification({ message: "ขนาดรูปภาพต้องไม่เกิน 20 MB", type: 3 });
      return;
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImglogoweb(file);
      setImagePreviewlogoweb(reader.result);
      setCheckimglogoweb(true);
    };
    reader.readAsDataURL(file);
  };
  const handleDelImglogoweb = (e) => {
    setImglogoweb({});
    setImagePreviewlogoweb(null);
    setCheckimglogoweb(true);
  };

  return (
    <div className="flex justify-center">
      <form className="w-full md:w-4/6 bg-white p-1 lg:p-5 rounded-md">
        {token != null && token.roles[0] === "ROLE_MOD" ? (
          <>
            <div className="flex justify-start mb-4">
              <img
                loading="lazy"
                src={social3}
                className="w-10  mx-5 my-auto inline "
              />
              <label className="block  text-lg text-gray-600 my-auto">
                ข้อมูลเว็บไซต์
              </label>
            </div>
            <div className="my-auto block lg:flex align-middle items-center">
              <label className="inline-block  text-black">
                อัพโหลด Logo เว็บไซต์ (แนะนำขนาด 512 x 512 px)
              </label>
              <IconButton onClick={handleDelImglogoweb}>
                <DeleteForeverIcon color="error" />
              </IconButton>
            </div>

            <div className="w-full mx-auto my-5 grid grid-cols-1 lg:grid-cols-3 gap-2">
              <div className="">
                <div className="flex items-center justify-center w-full">
                  {imagePreviewlogoweb ? (
                    <img
                      className="rounded-sm h-20 lg:h-44 w-full object-contain"
                      src={imagePreviewlogoweb && imagePreviewlogoweb}
                      alt="trade"
                    />
                  ) : (
                    <label className="flex flex-col w-full h-48 border-4 border-blue-200 rounded-md border-dashed hover:bg-gray-100 cursor-pointer hover:border-gray-300">
                      <div className="flex flex-col items-center justify-center pt-7 align-middle mt-10">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          />
                        </svg>
                        <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                          เลือกไฟล์รูป
                        </p>
                      </div>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        className="opacity-0"
                        name="imgbank"
                        onChange={handleUploadImglogoweb}
                      />
                    </label>
                  )}
                </div>
              </div>
              <div className="my-auto col-span-2">
                <p className="my-1">ชื่อเว็บไซต์ (ไม่เกิน 18 ตัว)</p>
                <OutlinedInput
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  value={webname}
                  inputProps={{ maxLength: 18 }}
                  onChange={(e) => setWebname(e.target.value)}
                  className="block w-full  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  aria-describedby="outlined-weight-helper-text"
                  autoComplete="off"
                  fullWidth
                  size="small"
                />
              </div>
            </div>
            <div className="h-0.5 bg-slate-600 my-2 rounded-full"></div>
            <div className="flex justify-start mb-4">
              <img
                loading="lazy"
                src={notice_img}
                className="w-10  mx-5 my-auto inline "
              />
              <label className="block  text-lg text-gray-600 my-auto">
                ป้ายประกาศ
              </label>
            </div>
            <div className="my-auto col-span-2">
              <textarea
                name="notice"
                rows="3"
                value={notice}
                onChange={(e) => setNotice(e.target.value)}
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-sky-500"
                placeholder="ป้ายประกาศ"
              ></textarea>
            </div>
            <div className="h-0.5 bg-slate-600 my-2 rounded-full"></div>
            <div className="my-auto col-span-2">
              <div className="flex justify-start mb-4">
                <img
                  loading="lazy"
                  src={aboutus_img}
                  className="w-10  mx-5 my-auto inline "
                />
                <label className="block  text-lg text-gray-600 my-auto">
                  เกี่ยวกับเรา
                </label>
              </div>
              <textarea
                name="aboutus"
                rows="8"
                value={aboutus}
                onChange={(e) => setAboutus(e.target.value)}
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-sky-500"
                placeholder="เกี่ยวกับเรา"
              ></textarea>
            </div>
            <div className="h-0.5 bg-slate-600 my-2 rounded-full"></div>

            <div className="flex justify-start mb-4">
              <img
                loading="lazy"
                src={bankic}
                className="w-10  mx-5 my-auto inline "
              />
              <label className="block  text-lg text-gray-600 my-auto">
                USDT Payment
              </label>
            </div>
            <div className="w-full mx-auto my-5 grid grid-cols-1 lg:grid-cols-3 gap-2">
              <div className="">
                <label className="inline-block mb-2 text-black">
                  อัพโหลด QR
                </label>
                <IconButton onClick={handleDelImgbank}>
                  <DeleteForeverIcon color="error" />
                </IconButton>
                <div className="flex items-center justify-center w-full">
                  {imagePreviewbank ? (
                    <img
                      className="rounded-sm h-96 w-full object-contain"
                      src={imagePreviewbank && imagePreviewbank}
                      alt="trade"
                    />
                  ) : (
                    <label className="flex flex-col w-full h-56 border-4 border-blue-200 rounded-md border-dashed hover:bg-gray-100 cursor-pointer hover:border-gray-300">
                      <div className="flex flex-col items-center justify-center pt-7 mt-16">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          />
                        </svg>
                        <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                          เลือกไฟล์รูป
                        </p>
                      </div>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        className="opacity-0"
                        name="imgbank"
                        onChange={handleUploadImgbank}
                      />
                    </label>
                  )}
                </div>
              </div>
              <div className="my-auto col-span-2">
                <p className="my-1">รายละเอียด</p>
                <textarea
                  name="bankdetail"
                  rows="8"
                  value={bankdetail}
                  onChange={(e) => setBankdetail(e.target.value)}
                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-sky-500"
                  placeholder="รายละเอียด"
                ></textarea>
              </div>
            </div>
            {/* <div className="w-full mx-auto my-5">
              <div className="flex w-full md:w-6/6 mx-auto my-5">
                <FormControl className="w-full text-left">
                  <InputLabel className="my-auto">เลือกธนาคาร</InputLabel>
                  <Select
                    name="idbank"
                    size="small"
                    label="เลือกธนาคาร"
                    defaultValue={idbank}
                    value={idbank}
                    onChange={(e) => setIdbank(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value={1}>
                      <i className="bank bank-kbank xxxl mr-4"></i>
                      ธนาคารกสิกรไทย
                    </MenuItem>
                    <MenuItem value={2}>
                      <i className="bank bank-bbl xxxl mr-4"></i>ธนาคารกรุงเทพ
                    </MenuItem>
                    <MenuItem value={3}>
                      <i className="bank bank-scb xxxl mr-4"></i>
                      ธนาคารไทยพาณิชย์
                    </MenuItem>
                    <MenuItem value={4}>
                      <i className="bank bank-ktb xxxl mr-4"></i>ธนาคารกรุงไทย
                    </MenuItem>
                    <MenuItem value={5}>
                      <i className="bank bank-bay xxxl mr-4"></i>ธนาคารกรุงศรี
                    </MenuItem>
                    <MenuItem value={6}>
                      <i className="bank bank-tmb xxxl mr-4"></i>
                      ธนาคารทหารไทยธนชาต
                    </MenuItem>
                    <MenuItem value={7}>
                      <i className="bank bank-lhb xxxl mr-4"></i>
                      ธนาคารแลนด์แอนด์เฮ้าส์
                    </MenuItem>
                    <MenuItem value={8}>
                      <i className="bank bank-gsb xxxl mr-4"></i>ธนาคารออมสิน
                    </MenuItem>
                    <MenuItem value={9}>
                      <i className="bank bank-kk xxxl mr-4"></i>
                      ธนาคารเกียรตินาคินภัทร
                    </MenuItem>
                    <MenuItem value={10}>
                      <i className="bank bank-citi xxxl mr-4"></i>
                      ธนาคารซิตี้แบงก์
                    </MenuItem>
                    <MenuItem value={11}>
                      <i className="bank bank-baac xxxl mr-4"></i>
                      ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร
                    </MenuItem>
                    <MenuItem value={12}>
                      <i className="bank bank-uob xxxl mr-4"></i>ธนาคารยูโอบี
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <TextField
                name="codebank"
                value={codebank}
                onChange={(e) => setCodebank(e.target.value)}
                id="outlined-textarea"
                type={"tel"}
                size="small"
                label="หมายเลขบัญชี"
                autoComplete="off"
                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                inputProps={{ maxLength: 13 }}
                className="w-6/6"
                fullWidth
              />
              <div className="my-2">
                <TextField
                  name="namebank"
                  value={namebank}
                  onChange={(e) => setNamebank(e.target.value)}
                  id="outlined-textarea"
                  size="small"
                  label="ชื่อ - นามสกุลบัญชี"
                  autoComplete="off"
                  className="w-6/6"
                  fullWidth
                />
              </div>
            </div> */}
          </>
        ) : (
          <></>
        )}

        <Divider
          style={{ background: "black" }}
          className="text-red-900"
          sx={{ borderBottomWidth: 3 }}
        />
        <div className="w-full mx-auto my-5">
          <div className="flex justify-start mb-4">
            <img
              loading="lazy"
              src={social1}
              className="w-10  mx-5 my-auto inline "
            />
            <label className="block  text-lg text-gray-600 my-auto">
              ลิงค์ Facebook
            </label>
          </div>
          <OutlinedInput
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            value={facebookURL}
            onChange={(e) => setFacebookURL(e.target.value)}
            className="block w-full  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
            aria-describedby="outlined-weight-helper-text"
            autoComplete="off"
            fullWidth
            size="small"
          />
        </div>

        <Divider className="" sx={{ borderBottomWidth: 3 }} />

        <div className="w-full mx-auto my-5">
          <div className="flex justify-start mb-4">
            <img
              loading="lazy"
              src={social2}
              className="w-10  mx-5 my-auto inline "
            />
            <label className="block  text-lg text-gray-600 my-auto">
              ลิงค์ Line
            </label>
          </div>
          <OutlinedInput
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            value={lineURL}
            onChange={(e) => setLineURL(e.target.value)}
            className="block w-full  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
            aria-describedby="outlined-weight-helper-text"
            autoComplete="off"
            fullWidth
            size="small"
          />
        </div>

        <Divider className="" sx={{ borderBottomWidth: 3 }} />

        <div className="w-full mx-auto my-5">
          <div className="flex justify-start mb-4">
            <img
              loading="lazy"
              src={social3}
              className="w-10  mx-5 my-auto inline "
            />
            <label className="block  text-lg text-gray-600 my-auto">
              ลิงค์เว็บไซต์
            </label>
          </div>
          <OutlinedInput
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            value={websiteURL}
            onChange={(e) => setWebsiteURL(e.target.value)}
            className="block w-full  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
            aria-describedby="outlined-weight-helper-text"
            autoComplete="off"
            fullWidth
            size="small"
          />
        </div>

        <Divider className="" sx={{ borderBottomWidth: 3 }} />

        <div className="w-full mx-auto my-5">
          <div className="flex justify-start mb-4">
            <img
              loading="lazy"
              src={social4}
              className="w-10  mx-5 my-auto inline "
            />
            <label className="block  text-lg text-gray-600 my-auto">
              ลิงค์ Gmail
            </label>
          </div>
          <OutlinedInput
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            value={gmailURL}
            onChange={(e) => setGmailURL(e.target.value)}
            className="block w-full  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
            aria-describedby="outlined-weight-helper-text"
            autoComplete="off"
            fullWidth
            size="small"
          />
        </div>

        <Divider className="" sx={{ borderBottomWidth: 3 }} />

        <div className="w-full mx-auto my-5"></div>
        <div className="text-center">
          <button
            onClick={(e) => UpdateWebUrl(e)}
            className="text-center text-white font-semibold  px-20 py-4 text-md rounded-md bg-gray-900 hover:bg-gray-800 shadow-md"
          >
            บันทึก
          </button>
        </div>
      </form>
    </div>
  );
}

export default Contact;
