import React, { useState } from "react";
import ErrorMessage from "../../Toast/ErrorMessage";
import { Typography, FormControl, Button } from "@mui/material";
import { signin } from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import Toast from "../../Toast/Toast";
import logo_main from "../../image/Bitcoin-SV-logo-white.png";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  UserOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { Input } from "antd";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Logoweb from "../../Function/Logoweb";
import Nameweb from "../../Function/Nameweb";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const submitLogin = async () => {
    await signin(username, password)
      .then((res) => {
        Toast.fire({
          icon: "success",
          title: "เข้าสู่ระบบแล้ว",
        });
        navigate("/admin");
        window.location.reload();
      })
      .catch((err) => {
        setErrorMessage("ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitLogin();
  };

  return (
    <div
      className="w-full h-screen bg-zinc-600"
      style={{
        // backgroundImage: `url(${bg1})`,
        backgroundSize: "cover",
      }}
    >
      <div className="p-4 w-full h-screen">
        <div className=" mb-5">
          <div className="my-auto  text-center  h-14 lg:h-20 mx-auto object-contain flex justify-center">
            <Logoweb />
          </div>
          <div className="text-white my-auto mx-auto truncate w-32 lg:w-full text-center">
            <Nameweb />
          </div>
        </div>

        <form
          onSubmit={handleSubmit}
          className="p-5 mx-auto text-center rounded-lg bg-gray-200 w-full md:w-1/3"
        >
          <Typography
            variant="h5"
            sx={{ mb: 2 }}
            component="div"
            style={{ color: "black" }}
          >
            เข้าสู่ระบบ
          </Typography>
          <div className=" mx-auto my-3 ">
            <Input
              size="large"
              className="ant-input-affix-wrapper form-input h-10 w-full rounded-md"
              autoComplete="off"
              placeholder="Username"
              prefix={<UserOutlined className="site-form-item-icon" />}
              defaultValue={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className="mx-auto my-5">
            <Input.Password
              size="large"
              autoComplete="off"
              className="rounded-md h-10"
              placeholder="รหัสผ่าน"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              prefix={<LockOutlined className="site-form-item-icon" />}
              defaultValue={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <FormControl sx={{ m: 1, width: "80%" }} variant="standard">
            {errorMessage ? <ErrorMessage message={errorMessage} /> : <></>}
          </FormControl>
          <FormControl sx={{ m: 1, width: "75%" }} variant="standard">
            <Button
              variant="contained"
              size="large"
              type="submit"
              sx={{
                backgroundColor: "#000000",
                ":hover": {
                  bgcolor: "#000033",
                },
                borderRadius: "10px",
              }}
              className=""
            >
              เข้าสู่ระบบ
            </Button>
          </FormControl>
        </form>
      </div>
      <footer>
        <div
          className="text-white text-center py-2 lg:py-4 w-full fixed bottom-0"
          style={{ backgroundColor: "rgba(0, 0, 0, 1)" }}
        >
          <p className="text-xs lg:text-xs">
            © 2024 BITCOINSV-OPTIONEX. ALL RIGHTS RESERVED.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Login;
