import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import dayjs from "dayjs";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import numberFormat from "../../../Function/NumberFormat2float";



import API_URL from "../../../config/api";
import SlipModal from "./SlipModal";
import OpenNotification from "../../../Toast/OpenNotification";


function History_Deposit({ selectIndex }) {
  const [usercredit, setUsercredit] = useState({});
  const [allcredit, setAllcredit] = useState([]);
  const [allcreditBackup, setAllcreditBackup] = useState([]);
  const [isSlipModal, setIsSlipModal] = useState(false);
  const [inputSearch, setInputSearch] = useState("");

  const [timestart, setTimestart] = useState(dayjs().startOf('month').format("YYYY-MM-DD"));
  const [timeend, setTimeend] = useState(
    dayjs().endOf('month').format("YYYY-MM-DD")
  );
  const [sumDeposit, setSumDeposit] = useState(0);
  const [sumWithdraw, setSumWithdraw] = useState(0);

  useEffect(() => {
    get_AllWithdraw();
    // if (selectIndex === 2) {
    //   get_AllDeposit();
    // } else if (selectIndex === 3) {
    //   get_AllWithdraw();
    // } else if (selectIndex === 4) {
    //   get_AllHistory();
    // }

    // const interval = setInterval(() => {
    //   if (selectIndex === 1) {
    //     get_AllDeposit();
    //   } else if (selectIndex === 2) {
    //     get_AllWithdraw();
    //   } else if (selectIndex === 3) {
    //     get_AllHistory();
    //   }
    // }, 30000);
    // return () => clearInterval(interval);
  }, [selectIndex]);

  const showSlipModal = (id) => {
    setIsSlipModal(!isSlipModal);
    setUsercredit(
      allcredit.find((obj) => {
        return obj.id === id;
      })
    );
  };
  const showSlipModalClose = (id) => {
    setIsSlipModal(!isSlipModal);
    setUsercredit({});
  };

  const get_AllDeposit = async () => {
    setAllcredit([]);
    setAllcreditBackup([]);
    let sumde = 0;
    let sumwith = 0;
    await API_URL.get(`api/deposit/allDeposit`)
      .then((res) => {
        const getalluser = res.data;
        if (getalluser.length !== 0) {
          getalluser.map((e) => {
            e.amounttxt = e.amount.toString();
            e.sliptimetxt = dayjs(e.sliptime)
              .format("DD/MM/YYYY HH:mm")
              .toString();
            if (e.type === 1 && e.status === 1) sumde += e.amount;
            if (e.type === 2 && e.status === 1) sumwith += e.amount;
          });
          setSumDeposit(sumde);
          setSumWithdraw(sumwith);
          setAllcredit(getalluser);
          setAllcreditBackup(getalluser);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const get_AllWithdraw = async () => {
    setAllcredit([]);
    setAllcreditBackup([]);
    let sumde = 0;
    let sumwith = 0;
    await API_URL.get(`api/deposit/allWithdraw`)
      .then((res) => {
        const getalluser = res.data;
        if (getalluser.length !== 0) {
          getalluser.map((e) => {
            e.amounttxt = e.amount.toString();
            e.sliptimetxt = dayjs(e.sliptime)
              .format("DD/MM/YYYY HH:mm")
              .toString();
            if (e.type === 1 && e.status === 1) sumde += e.amount;
            if (e.type === 2 && e.status === 1) sumwith += e.amount;
          });
          setSumDeposit(sumde);
          setSumWithdraw(sumwith);
          setAllcredit(getalluser);
          setAllcreditBackup(getalluser);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const get_AllHistory = async () => {
    setAllcredit([]);
    setAllcreditBackup([]);
    let sumde = 0;
    let sumwith = 0;
    await API_URL.get(`api/deposit/allhistory`)
      .then((res) => {
        const getalluser = res.data;
        if (getalluser.length !== 0) {
          getalluser.map((e) => {
            e.amounttxt = e.amount.toString();
            e.sliptimetxt = dayjs(e.sliptime)
              .format("DD/MM/YYYY HH:mm")
              .toString();
            if (e.type === 1 && e.status === 1) sumde += e.amount;
            if (e.type === 2 && e.status === 1) sumwith += e.amount;
          });
          setSumDeposit(sumde);
          setSumWithdraw(sumwith);
          setAllcredit(getalluser);
          setAllcreditBackup(getalluser);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns_alluser = [
    {
      title: "Uid",
      width: "10%",
      render: (allcredit) => (
        <p className="text-xs text-black my-auto px-2">
          {allcredit.people.uid}
        </p>
      ),
    },
    {
      title: "ประเภท",
      align: "center",
      width: "8%",
      render: (allcredit) => (
        <>
          {allcredit.type === 1 ? (
            <div className="bg-green-400 px-2 py-2 w-2/4 mx-auto rounded-lg">
              <p className="wsn text-xs text-black my-auto  font-semibold">
                ฝาก
              </p>
            </div>
          ) : (
            <div className="bg-red-500 px-2 py-2 w-2/4 mx-auto rounded-lg">
              <p className="wsn text-xs text-white my-auto  font-semibold">
                ถอน
              </p>
            </div>
          )}
        </>
      ),
    },
    {
      title: "สถานะ",
      align: "center",
      width: "8%",
      render: (allcredit) => (
        <>
          {allcredit.status === 0 ? (
            <Tag color="geekblue">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                รออนุมัติ
              </p>
            </Tag>
          ) : allcredit.status === 1 ? (
            <Tag color="green">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                อนุมัติ
              </p>
            </Tag>
          ) : (
            <Tag color="volcano">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                ไม่อนุมัติ
              </p>
            </Tag>
          )}
        </>
      ),
    },
    // {
    //   title: "สลิป",
    //   align: "center",
    //   width: "5%",
    //   render: (allcredit) => (
    //     <>
    //       {allcredit.type === 1 ? (
    //         <button
    //           onClick={() => showSlipModal(allcredit.id)}
    //           className="text1line text-center py-2 md:py-2 px-2 rounded-md shadow-xl bg-orange-500 hover:bg-orange-700 text-white text-sm md:text-md mx-auto"
    //         >
    //           ดูสลิป
    //         </button>
    //       ) : (
    //         <p>-</p>
    //       )}
    //     </>
    //   ),
    // },
    {
      title: "ชื่อ - นามสกุล",
      width: "10%",
      render: (allcredit) => (
        <div className="text-xs  max-w-xl my-auto">
          {allcredit.people.firstname}
          <br />
          {allcredit.people.lastname}
        </div>
      ),
    },
    {
      title: "เวลา",
      align: "center",
      render: (allcredit) => (
        <p className=" text-xs text-black my-auto px-2">
          {dayjs(allcredit.sliptime).format("DD/MM/YYYY")} เวลา{" "}
          {dayjs(allcredit.sliptime).format("HH:mm")}
        </p>
      ),
    },

    {
      title: "ยอดถอน",
      align: "center",
      render: (allcredit) => (
        <p className="wsn text-xs text-black my-auto px-2">
          {numberFormat(allcredit.amount)}
        </p>
      ),
    },
    {
      title: "ยอดเดิม",
      align: "center",
      render: (allcredit) => (
        <p className="wsn text-xs text-black my-auto px-2">
          {numberFormat(allcredit.preamount)}
        </p>
      ),
    },
    {
      title: "คงเหลือ",
      align: "center",
      render: (allcredit) => (
        <>
          {allcredit.type === 1 ? (
            <Tag color="green">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                {numberFormat(allcredit.net)}
              </p>
            </Tag>
          ) : (
            <Tag color="volcano">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                {numberFormat(allcredit.net)}
              </p>
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Admin",
      width: "10%",
      align: "center",
      ellipsis: {
        showTitle: true,
      },
      render: (allcredit) => (
        <p className="text-xs text-black my-auto px-2">
          {allcredit.user !== null ? allcredit.user.name : "-"}
        </p>
      ),
    },
    {
      title: "หมายเหตุ",
      align: "center",
      width: "15%",
      render: (allcredit) => (
          <p className="text-xs text-black my-auto font-semibold text-hover-show-admin">
            {allcredit.note}
          </p>
  
      ),
    },
  ];
  const timeFormat = (time) => {
    return dayjs(time).format("YYYY-MM-DD");
  };

  const changDatestart = (e) => {
    if (e.target.value >= timeend) {
      setTimestart(dayjs(timeend).add(-1, "day").format("YYYY-MM-DD"));
    } else {
      setTimestart(e.target.value);
    }
  };
  const changDateend = (e) => {
    if (e.target.value <= timestart) {
      setTimeend(dayjs(timestart).add(1, "day").format("YYYY-MM-DD"));
    } else {
      setTimeend(e.target.value);
    }
  };

  const handleChageByDate = () => {
    let data = [];
    let sumde = 0;
    let sumwith = 0;
    allcreditBackup.map((all) => {
      if (inputSearch.length === 0) {
        if (
          timeFormat(all.sliptime) >= timestart &&
          timeFormat(all.sliptime) <= timeend
        ) {
          data.push(all);
          if (all.type === 1 && all.status === 1) sumde += all.amount;
          if (all.type === 2 && all.status === 1) sumwith += all.amount;
        }
      } else {
        if (
          timeFormat(all.sliptime) >= timestart &&
          timeFormat(all.sliptime) <= timeend &&
          all.people.uid === inputSearch
        ) {
          data.push(all);
          if (all.type === 1 && all.status === 1) sumde += all.amount;
          if (all.type === 2 && all.status === 1) sumwith += all.amount;
        }
      }
    });
    setSumDeposit(sumde);
    setSumWithdraw(sumwith);
    OpenNotification({
      message: "ค้นหาเสร็จสิ้น",
      type: 1,
    });

    setAllcredit(data);
  };

  return (
    <div>
      <div className="w-full md:flex justify-start my-1">
        <div className="w-full md:w-1/3   md:flex">
          <TextField
            className=" rounded-md"
            name="UID"
            size="small"
            color="secondary"
            id="outlined-textarea"
            autoComplete="off"
            label="UID"
            InputProps={{
              endAdornment: <SearchIcon position="end"></SearchIcon>,
            }}
            fullWidth
            value={inputSearch}
            onChange={(e) => {
              const currValue = e.target.value;
              setInputSearch(currValue);
              const filteredData = allcreditBackup.filter((entry) =>
                entry.people.uid
                  .toLowerCase()
                  .includes(currValue.toLowerCase())
              );
              setAllcredit(filteredData);
            }}
          />
        </div>
        <div className="w-full lg:w-2/3 flex flex-wrap my-4 mx-0 md:mx-3 md:my-0">
          <div className="w-1/2 lg:w-1/5 px-1  rounded-md">
            <TextField
              id="date"
              label="เริ่มต้น"
              type="date"
              size="small"
              value={timestart}
              onChange={(e) => changDatestart(e)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </div>
          <div className="w-1/2 lg:w-1/5 px-1  rounded-md">
            <TextField
              id="date"
              label="สิ้นสุด"
              type="date"
              size="small"
              fullWidth
              value={timeend}
              onChange={(e) => changDateend(e)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <button
            onClick={handleChageByDate}
            className="w-full lg:w-1/12 my-1 md:my-0 px-4 py-2 bg-green-600 hover:bg-green-800 rounded-md shadow-lg text-white wsn"
          >
            ค้นหา
          </button>
          {/* <FormControl className="w-full md:w-1/5 bg-white rounded-md">
            <InputLabel className="my-auto">เดือน</InputLabel>
            <Select
              name="idbank"
              size="small"
              label="เดือน"
              defaultValue={monthvalue}
              value={monthvalue}
              onChange={(e) => setMonthvalue(e.target.value)}
              fullWidth
            >
              <MenuItem value={1}>มกราคม</MenuItem>
              <MenuItem value={2}>กุมภาพันธ์</MenuItem>
              <MenuItem value={3}>มีนาคม</MenuItem>
              <MenuItem value={4}>เมษายน</MenuItem>
              <MenuItem value={5}>พฤษภาคม</MenuItem>
              <MenuItem value={6}>มิถุนายน</MenuItem>
              <MenuItem value={7}>กรกฎาคม</MenuItem>
              <MenuItem value={8}>สิงหาคม</MenuItem>
              <MenuItem value={9}>กันยายน</MenuItem>
              <MenuItem value={10}>ตุลาคม</MenuItem>
              <MenuItem value={11}>พฤศจิกายน</MenuItem>
              <MenuItem value={12}>ธันวาคม</MenuItem>
            </Select>
          </FormControl> */}
          {/* <FormControl className="w-full md:w-1/5 bg-white rounded-md" >
            <InputLabel className="my-auto">ปี</InputLabel>
            <Select
              name="yearvalue"
              size="small"
              label="ปี"
              defaultValue={yearvalue}
              value={yearvalue}
              onChange={(e) => setyearvalue(e.target.value)}
              fullWidth
            >
              <MenuItem value={1}>2022</MenuItem>
              <MenuItem value={2}>2023</MenuItem>
              <MenuItem value={3}>2024</MenuItem>
              <MenuItem value={4}>2025</MenuItem>
              <MenuItem value={5}>2026</MenuItem>
              <MenuItem value={6}>2027</MenuItem>
              <MenuItem value={7}>2028</MenuItem>
              <MenuItem value={8}>2029</MenuItem>
              <MenuItem value={9}>2030</MenuItem>
            </Select>
          </FormControl> */}
        </div>
      </div>
      <Table
        className="table-striped-rows"
        size="middle"
        scroll={{
          x: 1200,
        }}
        pagination={{ pageSize: 15 }}
        rowKey="id"
        columns={columns_alluser}
        dataSource={allcredit}
      />
      <div className="w-full flex justify-end my-2">
        <div className="w-full lg:w-1/4 bg-slate-200 px-5  py-2 divide-y divide-gray-400 rounded-lg text-base truncate">
          {/* <div className="flex justify-between my-2">
            <p>รวมยอดฝาก :</p>
            <p className="font-semibold">฿ {numberFormat(sumDeposit)}</p>
          </div> */}
          <div className="flex justify-between my-2">
            <p>รวมยอดถอน :</p>
            <p className="font-semibold"> {numberFormat(sumWithdraw)} USDT</p>
          </div>
        </div>
      </div>
      {isSlipModal ? (
        <SlipModal
          showSlipModal={showSlipModal}
          isSlipModal={isSlipModal}
          showSlipModalClose={showSlipModalClose}
          allcredit={usercredit}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default History_Deposit;
