import config from "../config/configapi";
import API from "../config/api";
export default async function authHeader() {
  let user = null;
  try {
     user = JSON.parse(localStorage.getItem("awesomeLeadsToken"));
     await API.post("/api/test/user", {
      xaccesstoken: user.accessToken,
    })
      .then((res) => {

        if (res.data.message === "Login Finish.") {

          return true;
        } else {
          localStorage.removeItem("awesomeLeadsToken");
          window.location.href = `/admin`;
      
          return false;
        }
      })
      .catch((err) => {
        localStorage.removeItem("awesomeLeadsToken");
        window.location.href = `/admin`;
      });
  } catch (error) {
    localStorage.removeItem("awesomeLeadsToken");
    window.location.href = `/`;
  }

 

 
}
