import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './icon/banks.css'

import reportWebVitals from './reportWebVitals';
import frFR from 'antd/es/locale/th_TH';
import en_US from 'antd/es/locale/en_US';
import { ConfigProvider } from 'antd';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie';

import App from './App';
import Back_main from './components/back/Back_main';
import Front_main from './components/front/Front_main'


import './i81n'
import Login_user from './components/front/Login_user';
import Dashboard from './components/front/Dashboard';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CookiesProvider>
    {/* <React.StrictMode> */}
    <ConfigProvider locale={en_US} theme={{
      token: {
        fontFamily: 'Sarabun',
      },
    }}>
      <BrowserRouter>
        <Routes>

          <Route path="/" element={<App />} ></Route>
          <Route path="/login/*" element={<Front_main />} >
            
          </Route>
          {/* <Route path='/login/:id' element={<Login_user />}></Route> */}
          {/* 
          <Route path='/login' element={<Login_user />}></Route>
          <Route path='/login' element={<Dashboard />}></Route> */}
          {/* <Route path="/login/deposit" element={<Deposit />}></Route> */}

          <Route path="/admin/*" element={<Back_main />} ></Route>

          {/* <Route path="/admin/all_user/:peopleId" element={<Userdetail />} >
            </Route> */}

        </Routes>
      </BrowserRouter>
    </ConfigProvider>
    {/* </React.StrictMode> */}
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
