import React, { useState, useEffect } from "react";
import numberFormat from "../../../Function/NumberFormat2float";
import API_URL from "../../../config/api";
import SearchIcon from "@mui/icons-material/Search";
import { Table, Spin, Tag } from "antd";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import OpenNotification from "../../../Toast/OpenNotification";

function AllTrade() {
  const [alltrade, setAlltrade] = useState([]);
  const [alltradeBackup, setAlltradeBackup] = useState([]);
  const [alltradeloading, setAlltradeLoading] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const [timestart, setTimestart] = useState(
    dayjs().startOf("month").format("YYYY-MM-DD")
  );
  const [timeend, setTimeend] = useState(
    dayjs().endOf("month").format("YYYY-MM-DD")
  );

  useEffect(() => {
    get_UserAllTradeAdmin();
  }, []);

  const get_UserAllTradeAdmin = async () => {
    setAlltrade([]);
    setAlltradeBackup([]);

    setAlltradeLoading(true);
    await API_URL.get(`api/tradelist/getuseralltradehistoryadmin`)
      .then((res) => {
        const getalluser = res.data;

        if (getalluser.length !== 0) {
          setAlltrade(getalluser);
          setAlltradeBackup(getalluser);
        }
      })
      .catch((err) => {
        OpenNotification({ message: "เกิดข้อผิดพลาด", type: 4 });
      });
    setAlltradeLoading(false);
  };
  const columns_trading = [
    {
      title: `ลำดับ`,
      align: "center",
      width: "8%",
      render: (alltrade, record, index) => (
        <>
          <p className=" text-xs text-black my-auto px-2">{index + 1}</p>
        </>
      ),
    },
    {
      title: `UID`,
      align: "center",
      width: "8%",
      render: (alltrade) => (
        <p className=" text-xs text-black my-auto px-2">
          {alltrade.people.uid} - {alltrade.people.firstname}
        </p>
      ),
    },

    {
      title: `หุ้น`,
      align: "center",
      render: (alltrade) => (
        <p className=" text-xs text-black my-auto px-2">
          {alltrade.symbol} ( {numberFormat(alltrade.amount)} USDT )
        </p>
      ),
    },

    {
      title: `ปรับ`,
      align: "center",
      render: (alltrade) => (
        <p
          className={
            "wsn text-xs text-black my-auto px-0.5 py-0.5 border-2 rounded-md  " +
            (alltrade.adminstatus === 0
              ? "border-stone-400 bg-stone-50"
              : alltrade.adminstatus === 1
              ? "border-blue-400 bg-blue-50"
              : "border-red-400 bg-red-50")
          }
        >
          {alltrade.adminstatus === 0
            ? "ไม่ได้ set"
            : alltrade.adminstatus === 1
            ? "ชนะ"
            : "แพ้"}
        </p>
      ),
    },

    {
      title: `ประเภทซื้อ`,
      align: "center",
      render: (alltrade) => (
        <>
          {alltrade.type_order === 1 ? (
            <Tag color="green">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                ขึ้น
              </p>
            </Tag>
          ) : (
            <Tag color="volcano">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                ลง
              </p>
            </Tag>
          )}
        </>
      ),
    },
    {
      title: `เวลาเปิดออเดอร์`,
      align: "center",
      render: (alltrade) => (
        <p className="wsn text-xs text-black my-auto px-2">
          {dayjs(alltrade.opening_time).format("DD/MM/YYYY HH:mm:ss")}
        </p>
      ),
    },
    {
      title: `เปิด-ราคา`,
      align: "center",
      render: (alltrade) => (
        <p className="wsn text-xs text-black my-auto px-2">
          {numberFormat(alltrade.opening_price)}
        </p>
      ),
    },
    {
      title: `เวลาปิดออเดอร์`,
      align: "center",
      render: (alltrade) => (
        <p className="wsn text-xs text-black my-auto px-2">
          {dayjs(alltrade.closing_time).format("DD/MM/YYYY HH:mm:ss")}
        </p>
      ),
    },
    {
      title: `ปิด-ราคา`,
      align: "center",
      render: (alltrade) => (
        <p className="wsn text-xs text-black my-auto px-2">
          {numberFormat(alltrade.closing_price)}
        </p>
      ),
    },
    {
      title: `ผลลัพธ์`,
      align: "center",
      render: (alltrade) => (
        <p
          className={
            "wsn text-xs my-auto px-2 " +
            (alltrade.trade_result === 0 ? "text-green-600" : "text-red-600")
          }
        >
          {alltrade.trade_result === 0 ? "+" : "-"} {alltrade.net} USDT
        </p>
      ),
    },
  ];
  const timeFormat = (time) => {
    return dayjs(time).format("YYYY-MM-DD");
  };

  const changDatestart = (e) => {
    if (e.target.value >= timeend) {
      setTimestart(dayjs(timeend).add(-1, "day").format("YYYY-MM-DD"));
    } else {
      setTimestart(e.target.value);
    }
  };
  const changDateend = (e) => {
    if (e.target.value <= timestart) {
      setTimeend(dayjs(timestart).add(1, "day").format("YYYY-MM-DD"));
    } else {
      setTimeend(e.target.value);
    }
  };

  const handleChageByDate = () => {
    let data = [];

    alltradeBackup.map((all) => {
      if (inputSearch.length === 0) {
        if (
          timeFormat(all.opening_time) >= timestart &&
          timeFormat(all.opening_time) <= timeend
        ) {
          data.push(all);
        }
      } else {
        if (
          (timeFormat(all.opening_time) >= timestart &&
            timeFormat(all.opening_time) <= timeend &&
            all.people.uid === inputSearch) ||
          all.symbol.toLowerCase() === inputSearch.toLowerCase()
        ) {
          data.push(all);
        }
      }
    });

    setAlltrade(data);
    OpenNotification({
      message: "ค้นหาเสร็จสิ้น",
      type: 1,
    });
  };
  return (
    <div>
      <div className="w-full md:flex justify-start my-1">
        <div className="w-full md:w-1/3   md:flex">
          <TextField
            className=" rounded-md"
            name="UID"
            size="small"
            color="secondary"
            id="outlined-textarea"
            autoComplete="off"
            label="UID"
            InputProps={{
              endAdornment: <SearchIcon position="end"></SearchIcon>,
            }}
            fullWidth
            value={inputSearch}
            onChange={(e) => {
              const currValue = e.target.value;
              setInputSearch(currValue);
              const filteredData = alltradeBackup.filter(
                (entry) =>
                  entry.people.uid
                    .toLowerCase()
                    .includes(currValue.toLowerCase()) ||
                  entry.symbol.toLowerCase().includes(currValue.toLowerCase())
              );
              setAlltrade(filteredData);
            }}
          />
        </div>
        <div className="w-full lg:w-2/3 flex flex-wrap my-4 mx-0 md:mx-3 md:my-0">
          <div className="w-1/2 lg:w-1/5 px-1  rounded-md">
            <TextField
              id="date"
              label="เริ่มต้น"
              type="date"
              size="small"
              value={timestart}
              onChange={(e) => changDatestart(e)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </div>
          <div className="w-1/2 lg:w-1/5 px-1  rounded-md">
            <TextField
              id="date"
              label="สิ้นสุด"
              type="date"
              size="small"
              fullWidth
              value={timeend}
              onChange={(e) => changDateend(e)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <button
            onClick={handleChageByDate}
            className="w-full lg:w-1/12 my-1 md:my-0 px-4 py-2 bg-green-600 hover:bg-green-800 rounded-md shadow-lg text-white wsn"
          >
            ค้นหา
          </button>
          <button
            onClick={() => setAlltrade(alltradeBackup)}
            className="w-full lg:w-1/12 my-1 md:my-0 px-4 py-2  text-blue-700 wsn"
          >
            ล้าง
          </button>
        </div>
      </div>
      <Table
        className="table-striped-rows  "
        size="middle"
        scroll={{
          x: 1200,
        }}
        pagination={{ pageSize: 15 }}
        rowKey="id"
        columns={columns_trading}
        dataSource={alltrade}
        loading={{
          indicator: (
            <div>
              <Spin size="large" />
            </div>
          ),
          spinning: alltradeloading,
        }}
      />
    </div>
  );
}

export default AllTrade;
