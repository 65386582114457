import React, { useState, useEffect } from "react";

import { UserAddOutlined, TeamOutlined } from "@ant-design/icons";
import api from "../../../config/api";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
} from "@mui/material";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import numberFormat from "../../../Function/NumberFormat2float";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PaymentsIcon from "@mui/icons-material/Payments";
import AddCardIcon from '@mui/icons-material/AddCard';
import AssessmentIcon from "@mui/icons-material/Assessment";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1F2937",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.common.white,
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function Home() {
  const [allUser, setAllUser] = useState([]);
  const [todaypeople, setTodaypeople] = useState(0);
  const [monthpeople, setMonthpeople] = useState(0);
  const [todaydeposit, setTodaydeposit] = useState(0);
  const [monthdeposit, setMonthdeposit] = useState(0);
  const [todaywithdraw, setTodaywithdraw] = useState(0);
  const [monthwithdraw, setMonthwithdraw] = useState(0);
  const [allpeople, setAllpeople] = useState(0);
  const [tradingpeople, setTradingpeople] = useState(0);

  useEffect(() => {
    get_Alluser();
    get_CountPeople();
  }, []);

  const get_CountPeople = async () => {
    await api
      .get(`api/people/getcountuser`)
      .then((res) => {
        const count = res.data;

        setTodaypeople(count.todaypeople);
        setMonthpeople(count.monthpeople);
        setAllpeople(count.allpeople);
        setTradingpeople(count.tradingpeople);
        setTodaydeposit(count.todaydeposit);
        setMonthdeposit(count.monthdeposit);
        setTodaywithdraw(count.todaywithdraw);
        setMonthwithdraw(count.monthwithdraw)
      })
      .catch((err) => {});
  };

  const get_Alluser = async () => {
    await api
      .get(`api/people/getlastuser`)
      .then((res) => {
        const getalluser = res.data;
        if (getalluser.length !== 0) {
          setAllUser(getalluser);
        }
      })
      .catch((err) => {});
  };
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="w-full  px-1 md:px-3">
          <div className="w-full py-1 lg:py-2">
            <div className="bg-gradient-to-b from-red-100 to-red-50 border-b-4 border-red-300 rounded-lg shadow-md px-5 py-2 lg:p-5">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className="rounded-full p-0.5 lg:p-5 bg-red-500 ">
                    <TeamOutlined className="text-white text-2xl" />
                  </div>
                </div>
                <div className="flex-1 text-right md:text-center">
                  <h2 className="font-bold text-sm lg:text-xl text-gray-600">
                    User ทั้งหมด
                  </h2>
                  <p className="font-bold text-sm lg:text-xl">
                    {numberFormat(allpeople)}
                    <span className="text-pink-500">
                      <i className="fas fa-exchange-alt"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/************************card*******************************/}
        <div className="w-full px-1 md:px-3">
          <div className="w-full py-1 lg:py-2">
            <div className="bg-gradient-to-b from-green-200 to-green-100 border-b-4 border-green-400 rounded-lg shadow-md px-5 py-2 lg:p-5">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className="rounded-full p-0.5 lg:p-5 bg-green-500 ">
                    <UserAddOutlined className="text-white text-2xl" />
                  </div>
                </div>
                <div className="flex-1 text-right md:text-center">
                  <h2 className="font-bold text-sm lg:text-xl text-gray-600">
                    User ใหม่วันนี้
                  </h2>
                  <p className="font-bold text-sm lg:text-xl">
                    {numberFormat(todaypeople)}
                    <span className="text-pink-500">
                      <i className="fas fa-exchange-alt"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/************************card*******************************/}
        <div className="w-full px-1 md:px-3">
          <div className="w-full py-1 lg:py-2">
            <div className="bg-gradient-to-b from-purple-200 to-purple-100 border-b-4 border-purple-400 rounded-lg shadow-md px-5 py-2 lg:p-5">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className="rounded-full p-0.5 lg:p-5 bg-purple-500 ">
                    <CalendarMonthIcon className="text-white  scale-75 lg:scale-100" />
                  </div>
                </div>
                <div className="flex-1 text-right md:text-center">
                  <h2 className="font-bold text-sm lg:text-xl text-gray-600">
                    User รวมเดือนนี้
                  </h2>
                  <p className="font-bold text-sm lg:text-xl">
                    {numberFormat(monthpeople)}
                    <span className="text-pink-500">
                      <i className="fas fa-exchange-alt"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/************************card*******************************/}
        <div className="w-full px-1 md:px-3">
          <div className="w-full py-1 lg:py-2">
            <div className="bg-gradient-to-b from-yellow-100 to-yellow-50 border-b-4 border-yellow-200 rounded-lg shadow-md px-5 py-2 lg:p-5">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className="rounded-full p-0.5 lg:p-5 bg-yellow-500 ">
                    <AssessmentIcon className="text-white  scale-75 lg:scale-100" />
                  </div>
                </div>
                <div className="flex-1 text-right md:text-center">
                  <h2 className="font-bold text-sm lg:text-xl text-gray-600">
                    กำลังเทรดอยู่ตอนนี้
                  </h2>
                  <p className="font-bold text-sm lg:text-xl">
                    {numberFormat(tradingpeople)}
                    <span className="text-pink-500">
                      <i className="fas fa-exchange-alt"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
 
        {/************************card*******************************/}
        <div className="w-full px-1 md:px-3">
          <div className="w-full py-1 lg:py-2 grid grid-cols-2 gap-2">
            <div className="bg-gradient-to-b from-cyan-100 to-cyan-50 border-b-4 border-cyan-200 rounded-lg shadow-md px-1 py-2 lg:p-5">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className="rounded-full p-0.5 lg:p-5 bg-cyan-500 ">
                    <AddCardIcon className="text-white scale-75 lg:scale-100" />
                  </div>
                </div>
                <div className="flex-1 text-right md:text-center">
                  <h2 className="font-bold  text-xs lg:text-xl text-gray-600 whitespace-nowrap">
                    ยอดเพิ่มเครดิตวันนี้
                  </h2>
                  <p className="font-bold text-sm lg:text-xl">
                    {numberFormat(todaydeposit)} $
                    <span className="text-pink-500">
                      <i className="fas fa-exchange-alt"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-gradient-to-b from-cyan-100 to-cyan-50 border-b-4 border-cyan-200 rounded-lg shadow-md px-1 py-2 lg:p-5">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className="rounded-full p-0.5 lg:p-5 bg-cyan-500 ">
                    <AddCardIcon className="text-white  scale-75 lg:scale-100" />
                  </div>
                </div>
                <div className="flex-1 text-right md:text-center">
                  <h2 className="font-bold  text-xs lg:text-xl text-gray-600 whitespace-nowrap">
                    ยอดเพิ่มเครดิตเดือนนี้
                  </h2>
                  <p className="font-bold text-sm lg:text-xl">
                    {numberFormat(monthdeposit)} $
                    <span className="text-pink-500">
                      <i className="fas fa-exchange-alt"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/************************card*******************************/}
        <div className="w-full px-1 md:px-3">
          <div className="w-full py-1 lg:py-2 grid grid-cols-2 gap-2">
            <div className="bg-gradient-to-b from-orange-100 to-orange-50 border-b-4 border-orange-200 rounded-lg shadow-md px-1 py-2 lg:p-5">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className="rounded-full p-0.5 lg:p-5 bg-orange-500 ">
                    <AccountBalanceIcon className="text-white  scale-75 lg:scale-100" />
                  </div>
                </div>
                <div className="flex-1 text-right md:text-center">
                  <h2 className="font-bold  text-xs lg:text-xl text-gray-600 whitespace-nowrap">
                    ยอดถอนวันนี้
                  </h2>
                  <p className="font-bold text-sm lg:text-xl">
                    {numberFormat(todaywithdraw)} $
                    <span className="text-pink-500">
                      <i className="fas fa-exchange-alt"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-gradient-to-b from-orange-100 to-orange-50 border-b-4 border-orange-200 rounded-lg shadow-md px-1 py-2 lg:p-5">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className="rounded-full p-0.5 lg:p-5 bg-orange-500 ">
                    <AccountBalanceIcon className="text-white  scale-75 lg:scale-100" />
                  </div>
                </div>
                <div className="flex-1 text-right md:text-center">
                  <h2 className="font-bold  text-xs lg:text-xl text-gray-600 whitespace-nowrap">
                    ยอดถอนรวมเดือนนี้
                  </h2>
                  <p className="font-bold text-sm lg:text-xl">
                    {numberFormat(monthwithdraw)} $
                    <span className="text-pink-500">
                      <i className="fas fa-exchange-alt"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>





      </div>
      <div className="w-full md:w-4/6 mx-auto">
        <p className="text-center text-xs lg:text-sm font-bold my-4">
          User ล่าสุด
        </p>
        <TableContainer component={Paper}>
          <Table aria-label="customized table" className="tablesty1">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">ลำดับ</StyledTableCell>
                <StyledTableCell>UID</StyledTableCell>
                <StyledTableCell align="left">ชื่อ - นามสกุล</StyledTableCell>
                <StyledTableCell align="center">ยืนยันตัวตน</StyledTableCell>
                <StyledTableCell align="center">เบอร์โทร</StyledTableCell>
                <StyledTableCell align="center">เครดิต</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allUser.length !== 0 ? (
                <>
                  {allUser.map((alluser, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">
                        <p className="text-xs lg:text-sm font-bold m-0 text-gray-700">
                          {index + 1}
                        </p>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <p className="text-xs lg:text-sm font-bold m-0 text-gray-700">
                          {alluser.uid}
                        </p>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <p className="text-xs lg:text-sm font-bold m-0 text-gray-700">
                          {alluser.firstname} {alluser.lastname}
                        </p>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div className="text-xs font-bold m-0 text-gray-700">
                          {alluser.verify_status === 0 ? (
                            <div className="flex align-middle justify-center">
                              <DoNotDisturbOnIcon className="text-red-600 mr-2" />
                              <p className="my-auto">ไม่ได้ยืนยัน</p>
                            </div>
                          ) : alluser.verify_status === 1 ? (
                            <div className="flex align-middle justify-center">
                              <CheckCircleIcon className="text-green-600 mr-2" />
                              <p className="my-auto">ยืนยันแล้ว</p>
                            </div>
                          ) : alluser.verify_status === 2 ? (
                            <div className="flex align-middle justify-center">
                              <CancelIcon className="text-red-600 mr-2" />
                              <p className="my-auto">ไม่ผ่าน</p>
                            </div>
                          ) : (
                            <div className="flex align-middle justify-center">
                              <ChangeCircleIcon className="text-orange-400 mr-2" />
                              <p className="my-auto">รออนุมัติ</p>
                            </div>
                          )}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <p className="text-xs lg:text-sm font-bold m-0 text-gray-700">
                          {alluser.phone}
                        </p>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <p className="text-xs lg:text-sm font-bold m-0 text-gray-700">
                          {numberFormat(alluser.credit)}
                        </p>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  {" "}
                  <TableCell
                    colSpan={7}
                    sx={{ textAlign: "center", fontSize: "20px" }}
                  >
                    ยังไม่มี User
                  </TableCell>{" "}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default Home;
