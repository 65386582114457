import React, { useState, useEffect } from "react";
import AddCardIcon from "@mui/icons-material/AddCard";
import PaymentsIcon from "@mui/icons-material/Payments";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { useCookies } from "react-cookie";
import Creditadmin from "./Creditadmin";
import Deposit from "./Deposit";
import Withdraw from "./Withdraw";



function MainTransaction() {
  const [openTabTranCK, setOpenTabTranCK] = useCookies(["openTabTranCK"]);
  const [openTab, setOpenTab] = useState(
    Number(openTabTranCK.openTabTranCK) || 1
  );

  const datatab = [
    {
      id: 1,
      tabname: "ถอน",
    },
    {
      id: 2,
      tabname: "เพิ่ม - ลดเครดิตวันนี้",
    },
    // {
    //   id: 3,
    //   tabname: "เพิ่ม - ลดเครดิตวันนี้",
    // },
  ];

  return (
    <div style={{minHeight:"600px"}}>
      <div className="w-full">
        <div className="w-full px-1 md:px-3">
          <ul
            className="flex mb-0 list-none  pt-3 pb-0.5 flex-row"
            role="tablist"
          >
            {datatab.map((data, index) => (
              <li
                className="-mb-px mr-2 last:mr-0 flex-auto text-center"
                key={index}
              >
                <a
                  className={
                    "rounded-t-md myFont text-sm md:text-md my-auto font-bold uppercase px-5 py-2 lg:py-4 shadow-lg  block leading-normal " +
                    (openTab === data.id
                      ? "text-white bg-blue-900 "
                      : "text-blue-600  bg-white hover:text-blue-900 hover:bg-gray-100")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTabTranCK("openTabTranCK", data.id, { path: `/` });
                    setOpenTab(data.id);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  {data.id === 1 ? (
                    <PaymentsIcon className="my-auto" />
                  // ) : data.id === 2 ? (
                  //   <ContactEmergencyIcon />
                  ) : (
                    <CurrencyExchangeIcon />
                  )}{" "}
                  {data.tabname}
                </a>
              </li>
            ))}
          </ul>
          <div className="h-1 bg-blue-800"></div>
          <div className="relative flex flex-col min-w-0 break-words  w-full mb-6  ">
            <div className="px-0.5 lg:px-1 py-2 flex-auto">
              <div className="tab-content tab-space h-full" >
                {/* tab1 */}

                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                <Withdraw/>
                </div>

                {/* tab2 */}

                {/* <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                <VerifyUser/>
                </div> */}

                {/* tab3 */}

                <div className={openTab === 2 ? "block" : "hidden"} id="link3">
                <Creditadmin />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainTransaction;
