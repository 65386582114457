import React from "react";
import { Table, Tag } from "antd";
import { useState, useEffect } from "react";
import CreditadminModal from "./CreditadminModal";

import API_URL from "../../../config/api";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import OpenNotification from "../../../Toast/OpenNotification";
import numberFormat from "../../../Function/NumberFormat2float";

function History_Creditadmin() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [allcredit, setAllcredit] = useState([]);
  const [allcreditBackup, setAllcreditBackup] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inputSearch, setInputSearch] = useState("");

  const [timestart, setTimestart] = useState(
    dayjs().startOf("month").format("YYYY-MM-DD")
  );
  const [timeend, setTimeend] = useState(
    dayjs().endOf("month").format("YYYY-MM-DD")
  );
  const [sumDeposit, setSumDeposit] = useState(0);
  const [sumWithdraw, setSumWithdraw] = useState(0);

  useEffect(() => {
    get_AllCreditAdmin();
    // const interval = setInterval(() => {
    //   get_AllCreditAdmin();
    // }, 30000);
    // return () => clearInterval(interval);
  }, []);

  const get_AllCreditAdmin = async () => {
    setAllcredit([]);
    setAllcreditBackup([]);
    let sumde = 0;
    let sumwith = 0;
    setLoading(true);
    await API_URL.get(`api/creditadmin/AllCreditadmin`)
      .then((res) => {
        const getalluser = res.data;
        if (getalluser.length !== 0) {
          getalluser.map((e) => {
            e.amountxt = e.amount.toString();
            e.createdAttxt = dayjs(e.createdAt)
              .format("DD/MM/YYYY HH:mm")
              .toString();
            if (e.credittype === 1) sumde += e.amount;
            if (e.credittype === 2) sumwith += e.amount;
          });
          setSumDeposit(sumde);
          setSumWithdraw(sumwith);
          setAllcredit(getalluser);
          setAllcreditBackup(getalluser);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const showModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const columns_alluser = [
    {
      title: "ชื่อ - นามสกุล",

      render: (allcredit) => (
        <div className="text-xs  max-w-xl my-auto">
          {allcredit.people.firstname}
          <br />
          {allcredit.people.lastname}
        </div>
      ),
    },
    {
      title: "UID",
      render: (allcredit) => (
        <p className="text-xs text-black my-auto px-2">
          {allcredit.people.uid}
        </p>
      ),
    },
    {
      title: "เบอร์โทร",
      render: (allcredit) => (
        <p className="text-xs text-black my-auto px-2">
          {allcredit.people.phone}
        </p>
      ),
    },
    {
      title: "ประเภทเพิ่ม / ลด",
      align: "center",
      render: (allcredit) => (
        <>
          {allcredit.credittype === 1 ? (
            <Tag color="green">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                เพิ่ม
              </p>
            </Tag>
          ) : (
            <Tag color="volcano">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                ลด
              </p>
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "เครดิต",
      align: "center",
      render: (allcredit) => (
        <p className="text-xs text-black my-auto px-2">
          {numberFormat(allcredit.amount)}
        </p>
      ),
    },
    {
      title: "ยอดเดิม",
      align: "center",
      render: (allcredit) => (
        <p className="text-xs text-black my-auto px-2">
          {numberFormat(allcredit.preamount)}
        </p>
      ),
    },
    {
      title: "คงเหลือ",
      align: "center",
      render: (allcredit) => (
        <Tag color="green">
          <p className="text-xs text-black my-auto px-2 py-1">
            {allcredit.credittype === 1 ? (
              <>{numberFormat(allcredit.amount + allcredit.preamount)}</>
            ) : (
              <>{numberFormat(allcredit.preamount - allcredit.amount)}</>
            )}
          </p>
        </Tag>
      ),
    },
    {
      title: "หมายเหตุ",
      width: "15%",
      ellipsis: {
        showTitle: true,
      },
      render: (allcredit) => (
        <p className="text-xs text-black my-auto px-2">
          {allcredit.note === "" ? "-" : allcredit.note}
        </p>
      ),
    },
    {
      title: "Admin",
      width: "10%",
      align: "center",
      ellipsis: {
        showTitle: true,
      },
      render: (allcredit) => (
        <p className="text-xs text-black my-auto px-2">{allcredit.user.name}</p>
      ),
    },
    {
      title: "เวลา",
      align: "center",
      render: (allcredit) => (
        <p className="text-xs text-black my-auto px-2">
          {dayjs(allcredit.createdAt).format("DD/MM/YYYY HH:mm")}
        </p>
      ),
    },
  ];

  const timeFormat = (time) => {
    return dayjs(time).format("YYYY-MM-DD");
  };

  const changDatestart = (e) => {
    if (e.target.value >= timeend) {
      setTimestart(dayjs(timeend).add(-1, "day").format("YYYY-MM-DD"));
    } else {
      setTimestart(e.target.value);
    }
  };
  const changDateend = (e) => {
    if (e.target.value <= timestart) {
      setTimeend(dayjs(timestart).add(1, "day").format("YYYY-MM-DD"));
    } else {
      setTimeend(e.target.value);
    }
  };

  const handleChageByDate = () => {
    let data = [];
    let sumde = 0;
    let sumwith = 0;
    allcreditBackup.map((all) => {
      if (inputSearch.length === 0) {
        if (
          timeFormat(all.createdAt) >= timestart &&
          timeFormat(all.createdAt) <= timeend
        ) {
          data.push(all);
          if (all.credittype === 1) sumde += all.amount;
          if (all.credittype === 2) sumwith += all.amount;
        }
      } else {
        if (
          timeFormat(all.createdAt) >= timestart &&
          timeFormat(all.createdAt) <= timeend &&
          all.people.uid === inputSearch
        ) {
          data.push(all);
          if (all.credittype === 1) sumde += all.amount;
          if (all.credittype === 2) sumwith += all.amount;
        }
      }
    });
    setSumDeposit(sumde);
    setSumWithdraw(sumwith);
    OpenNotification({
      message: "ค้นหาเสร็จสิ้น",
      type: 1,
    });

    setAllcredit(data);
  };

  return (
    <div>
      {isModalVisible ? (
        <CreditadminModal
          isModalVisible={isModalVisible}
          showModal={showModal}
          get_AllCreditAdmin={get_AllCreditAdmin}
        />
      ) : (
        <></>
      )}
      <div className="grid grid-cols-2">
        <div className="flex justify-start">
          <button
            onClick={showModal}
            className=" justify-center text-center py-2 md:py-2 px-10 rounded-md shadow-lg bg-red-500 hover:bg-red-700 text-white text-sm md:text-md"
          >
            เพิ่ม / ลดเครดิต
          </button>
        </div>
        <div></div>
      </div>
      <div className="my-3">
        <div className="w-full md:flex justify-start my-1">
          <div className="w-full md:w-1/3   md:flex">
            <TextField
              className="bg-white rounded-md"
              name="password"
              size="small"
              color="secondary"
              id="outlined-textarea"
              autoComplete="off"
              label="UID,เบอร์โทร"
              InputProps={{
                endAdornment: <SearchIcon position="end"></SearchIcon>,
              }}
              fullWidth
              value={inputSearch}
              onChange={(e) => {
                const currValue = e.target.value;
                setInputSearch(currValue);
                const filteredData = allcreditBackup.filter((entry) =>
                  entry.people.uid
                    .toLowerCase()
                    .includes(currValue.toLowerCase())||
                  entry.people.phone
                    .toLowerCase()
                    .includes(currValue.toLowerCase())
                );
                setAllcredit(filteredData);
              }}
            />
          </div>
          <div className="w-full lg:w-2/3 flex flex-wrap my-4 mx-0 md:mx-3 md:my-0">
            <div className="w-1/2 md:w-1/5 px-1 bg-white rounded-md">
              <TextField
                id="date"
                label="เริ่มต้น"
                type="date"
                size="small"
                value={timestart}
                onChange={(e) => changDatestart(e)}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </div>
            <div className="w-1/2 md:w-1/5 px-1 bg-white rounded-md">
              <TextField
                id="date"
                label="สิ้นสุด"
                type="date"
                size="small"
                fullWidth
                value={timeend}
                onChange={(e) => changDateend(e)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <button
              onClick={handleChageByDate}
              className="w-full lg:w-1/12 my-1 md:my-0 px-4 py-2 bg-green-600 hover:bg-green-800 rounded-md shadow-lg text-white wsn"
            >
              ค้นหา
            </button>
          </div>
        </div>
        <Table
          className="table-striped-rows"
          size="middle"
          scroll={{
            x: 900,
          }}
          pagination={{ pageSize: 15 }}
          rowKey="id"
          columns={columns_alluser}
          dataSource={allcredit}
        />
        <div className="w-full flex justify-end my-2">
          <div className="w-full lg:w-1/4 bg-slate-200 px-5  py-2 divide-y divide-gray-400 rounded-lg text-base truncate">
            <div className="flex justify-between my-2">
              <p>รวมยอดเพิ่ม :</p>
              <p className="font-semibold">฿ {numberFormat(sumDeposit)}</p>
            </div>
            <div className="flex justify-between my-2">
              <p>รวมยอดลด :</p>
              <p className="font-semibold">฿ {numberFormat(sumWithdraw)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default History_Creditadmin;
