import React, { useState, useEffect } from "react";

import {
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import API_URL_form from "../../.././config/apiForm";

import { Modal } from "antd";

import OpenNotification from "../../../Toast/OpenNotification";
import ErrorMessage from "../../../Toast/ErrorMessage";

import PasswordIcon from "@mui/icons-material/Password";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { useWebSocket } from "../WebSocketContext";


function Adduser({
  isModalUser,
  showModalUser,
  get_Oneuser,
  findone,
  getId,

}) {
  const {sendMessage }   = useWebSocket();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [uid, setUid] = useState("");
  const [password, setPassword] = useState("");

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [idbank, setIdbank] = useState(0);
  const [codebank, setCodebank] = useState("");
  const [verify_status, setVertify_status] = useState(0);
  // const [firstnamebank, setFirstnamebank] = useState("");
  // const [lastnamebank, setLastnamebank] = useState("");

  const [addressnow, setAddressnow] = useState("");

  const [msgErr1, setMsgErr1] = useState("");
  const [msgErr2, setMsgErr2] = useState("");

  const [msgErr4, setMsgErr4] = useState("");

  useEffect(() => {
    clearForm();
    if (getId != null) {
      setForm(findone);
    }
  }, []);

  const setForm = (findone) => {
    setFirstname(findone.firstname);
    setLastname(findone.lastname);
    setUid(findone.uid);
    setPassword(findone.password);
    setIdbank(findone.idbank);
    setCodebank(findone.codebank);
    setVertify_status(findone.verify_status);
    setPhone(findone.phone);
    setEmail(findone.email);
    setAddressnow(findone.addressnow);
  };

  const clearForm = () => {
    setFirstname("");
    setLastname("");
    setUid("");
    setPassword("");
    setPhone("");
    setEmail("");
    setIdbank(0);
    setVertify_status(0);
    // setFirstnamebank("");
    // setLastnamebank("");
    setCodebank("");
  };

  const CreateUser = async (e) => {
    if (phone.length !== 10) {
      setMsgErr1("กรุณากรอกเบอร์โทรให้ถูกต้องและครบถ้วน");

      return;
    }
    setMsgErr1("");

    if (password === "" || password.length < 8) {
      setMsgErr2("กรุณากรอกรหัสผ่านให้ถูกต้องและมี 8 ตัวอักษรขึ้นไป");
      return;
    }
    setMsgErr2("");

    // if (codebank < 10 ) {
    //   setMsgErr4("กรุณากรอกบัญชีธนาคารให้ครบถ้วน");
    //   setValue("2");
    //   return;
    // }

    // setMsgErr4("");

    const formData = new FormData();

    formData.append("firstname", firstname.length === 0 ? "" : firstname);
    formData.append("lastname", lastname.length === 0 ? "" : lastname);
    formData.append("password", password);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("idbank", idbank === 0 ? 0 : idbank);
    formData.append("codebank", codebank);
    formData.append("addressnow", addressnow);
    formData.append("verify_status", verify_status);

    e.preventDefault();

    await API_URL_form.post(`api/people/newPeople`, formData)
      .then((res) => {
        OpenNotification({ message: "เพิ่ม User เรียบร้อย", type: 1 });
        try {
          sendMessage({ type: "refreshUsers" });
        } catch (error) {}
        showModalUser();
        clearForm();
      })
      .catch((err) => {
        err.response.data.status === 400
          ? OpenNotification({ message: "เบอร์นี้ถูกใช้งานแล้ว", type: 3 })
          : OpenNotification({ message: "เกิดข้อผิดพลาด", type: 4 });
      });
  };

  const UpdateUser = async (e) => {
    e.preventDefault();
    if (phone.length !== 10) {
      setMsgErr1("กรุณากรอกเบอร์โทรให้ถูกต้องและครบถ้วน");
      return;
    }
    setMsgErr1("");

    if (password === "" || password.length < 8) {
      setMsgErr2("กรุณากรอกรหัสผ่านให้ถูกต้องและมี 8 ตัวอักษรขึ้นไป");
      return;
    }
    setMsgErr2("");

    // if (codebank < 10 ) {
    //   setMsgErr4("กรุณากรอกบัญชีธนาคารให้ครบถ้วน");
    //   setValue("2");
    //   return;
    // }

    // setMsgErr4("");

    const formData = new FormData();

    formData.append("firstname", firstname.length === 0 ? "" : firstname);
    formData.append("lastname", lastname.length === 0 ? "" : lastname);
    formData.append("password", password);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("idbank", idbank === -1 ? 0 : idbank);
    formData.append("codebank", codebank);
    formData.append("addressnow", addressnow);
    formData.append("verify_status", verify_status);

    // formData.append("firstnamebank", firstnamebank);
    // formData.append("lastnamebank", lastnamebank);

    await API_URL_form.put(`api/people/updatePeople/${getId}`, formData)
      .then((res) => {
        OpenNotification({ message: "แก้ไข user เรียบร้อย", type: 1 });
        showModalUser();
        try {
          sendMessage({ type: "refreshUsers" });
          
        } catch (error) {}
        get_Oneuser();
        clearForm();
        return res.data;
      })
      .catch((err) => {
        OpenNotification({
          message: "เกิดข้อผิดพลาดหรือ เบอร์ นี้มีคนใช้แล้ว",
          type: 3,
        });
      });
  };

  return (
    <div>
      <Modal
        style={{
          top: 10,
        }}
        title={getId === null ? "เพิ่ม user" : "แก้ไข user"}
        open={isModalUser}
        onOk={showModalUser}
        onCancel={showModalUser}
        width={900}
        footer={[
          <div className=" text-center flex justify-center">
            {getId === null ? (
              <button
                onClick={CreateUser}
                className="text-center text-white font-semibold  px-10 py-2 rounded-md bg-blue-900 hover:bg-blue-700 shadow-md"
              >
                บันทึก
              </button>
            ) : (
              <button
                onClick={UpdateUser}
                className="text-center text-white font-semibold  px-10 py-2 rounded-md bg-orange-500 hover:bg-orange-800 shadow-md"
              >
                แก้ไข
              </button>
            )}

            <div className="mx-5 md:mx-16 "></div>
            <button
              onClick={showModalUser}
              className="bg-transparent hover:bg-gray-100 text-black font-semibold  py-2 px-10 border border-gray-900 hover:border-transparent rounded"
            >
              ยกเลิก
            </button>
          </div>,
        ]}
      >
        <form
          className="w-6/6 md:w-4/6 mx-auto mt-5 "
          encType="multipart/form-data"
          method="post"
        >
          <div className="flex w-full md:w-6/6 mx-auto">
            <TextField
              name="firstname"
              autoComplete="off"
              size="small"
              id="outlined-textarea"
              label="ชื่อ"
              placeholder="ชื่อ"
              onKeyPress={(e) => /[0-9]/.test(e.key) && e.preventDefault()}
              className="w-6/6"
              fullWidth
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
            />
            <div className="w-8"></div>
            <TextField
              name="lastname"
              size="small"
              id="outlined-textarea"
              label="นามสกุล"
              onKeyPress={(e) => /[0-9]/.test(e.key) && e.preventDefault()}
              autoComplete="off"
              placeholder="นามสกุล"
              className="w-6/6"
              fullWidth
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
          </div>

          <div className="flex w-full md:w-6/6 mx-auto my-5">
            <TextField
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              size="small"
              type={"tel"}
              id="outlined-textarea"
              label="เบอร์โทร"
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              autoComplete="off"
              placeholder="เบอร์โทร"
              className="w-6/6"
              inputProps={{ maxLength: 10 }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <PhoneAndroidIcon
                    position="start"
                    className="mr-5"
                  ></PhoneAndroidIcon>
                ),
              }}
            />
          </div>
          {msgErr1 ? <ErrorMessage message={msgErr1} /> : <></>}

          <div className="flex w-full md:w-6/6 mx-auto my-5">
            <TextField
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="outlined-textarea"
              size="small"
              label="Password"
              autoComplete="off"
              placeholder="รหัสผ่าน"
              className="w-6/6 "
              fullWidth
              InputProps={{
                startAdornment: (
                  <PasswordIcon
                    position="start"
                    className="mr-5"
                  ></PasswordIcon>
                ),
              }}
            />
          </div>

          {msgErr2 ? <ErrorMessage message={msgErr2} /> : <></>}

          <div className="flex w-full md:w-6/6 mx-auto my-5">
            <TextField
              name="uid"
              value={uid}
              size="small"
              id="outlined-textarea"
              label="uid"
              autoComplete="off"
              placeholder="Username"
              className="w-6/6 bg-gray-200 rounded-md text-black"
              fullWidth
              disabled
            />
          </div>
          <div className="flex w-full md:w-6/6 mx-auto my-5">
            <FormControl className="w-full whitespace-nowrap flex">
              <InputLabel className="my-auto">สถานะยืนยันตัวตน</InputLabel>
              <Select
                name="verify_status"
                size="small"
                className="flex"
                inputProps={{
                  style: { display: "flex", whiteSpace: "nowrap" },
                }}
                label="สถานะยืนยันตัวตน"
                defaultValue={verify_status}
                value={verify_status}
                onChange={(e) => setVertify_status(e.target.value)}
                fullWidth
              >
                <MenuItem value={0} className="flex align-middle">
                  <DoNotDisturbOnIcon className="text-red-600 mr-2" />
                  ไม่ได้ยืนยันตัวตน
                </MenuItem>
                <MenuItem value={1} className="flex align-middle">
                  <CheckCircleIcon className="text-green-600 mr-2" />
                  ยืนยันตัวตนแล้ว
                </MenuItem>
                <MenuItem value={2} className="flex align-middle">
                  <CancelIcon className="text-red-600 mr-2" />
                  ยืนยันตัวตนไม่ผ่าน
                </MenuItem>
                <MenuItem value={3} className="flex align-middle">
                  <ChangeCircleIcon className="text-orange-400 mr-2" />
                  รออนุมัติ
                </MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="flex w-full md:w-6/6 mx-auto">
            <TextField
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="small"
              id="outlined-textarea"
              label="E-mail"
              autoComplete="off"
              placeholder="E-mail"
              className="w-6/6"
              inputProps={{ maxLength: 50 }}
              InputProps={{
                startAdornment: (
                  <MailOutlineIcon
                    position="start"
                    className="mr-5"
                  ></MailOutlineIcon>
                ),
              }}
              fullWidth
            />
          </div>

          <div className="flex w-full md:w-6/6 mx-auto my-5">
            <TextField
              name="addressnow"
              value={addressnow}
              onChange={(e) => setAddressnow(e.target.value)}
              id="outlined-textarea"
              size="small"
              label="ที่อยู่"
              autoComplete="off"
              placeholder="ที่อยู่"
              // multiline
              // maxRows={2}
              className="w-full"
              fullWidth
            />
          </div>

          <div className="flex w-full md:w-6/6 mx-auto my-5">
            <FormControl className="w-full">
              <InputLabel className="my-auto">เลือกธนาคาร</InputLabel>
              <Select
                name="idbank"
                size="small"
                label="โปรดเลือกธนาคาร"
                defaultValue={idbank}
                value={idbank}
                onChange={(e) => setIdbank(e.target.value)}
                fullWidth
              >
               <MenuItem value={0}>None</MenuItem>
              <MenuItem value={1}>
                <i className="bank bank-kbank xxxl mr-4"></i>ธนาคารกสิกรไทย
                (KBank)
              </MenuItem>
              <MenuItem value={2}>
                <i className="bank bank-bbl xxxl mr-4"></i>ธนาคารกรุงเทพ (BBL)
              </MenuItem>
              <MenuItem value={3}>
                <i className="bank bank-scb xxxl mr-4"></i>ธนาคารไทยพาณิชย์
                (SCBB)
              </MenuItem>
              <MenuItem value={4}>
                <i className="bank bank-ktb xxxl mr-4"></i>ธนาคารกรุงไทย (KTB)
              </MenuItem>
              <MenuItem value={5}>
                <i className="bank bank-bay xxxl mr-4"></i>ธนาคารกรุงศรี (BAY)
              </MenuItem>
              <MenuItem value={6}>
                <i className="bank bank-tmb xxxl mr-4"></i>ธนาคารทหารไทยธนชาต
                (TTB)
              </MenuItem>
              <MenuItem value={7}>
                <i className="bank bank-lhb xxxl mr-4"></i>
                ธนาคารแลนด์แอนด์เฮ้าส์ (LH Bank)
              </MenuItem>
              <MenuItem value={8}>
                <i className="bank bank-gsb xxxl mr-4"></i>ธนาคารออมสิน (GSB)
              </MenuItem>
              <MenuItem value={9}>
                <i className="bank bank-kk xxxl mr-4"></i>
                ธนาคารเกียรตินาคินภัทร (KKP)
              </MenuItem>
              <MenuItem value={10}>
                <i className="bank bank-citi xxxl mr-4"></i>ธนาคารซิตี้แบงก์
                (CITI)
              </MenuItem>
              <MenuItem value={11}>
                <i className="bank bank-baac xxxl mr-4"></i>
                ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร (BAAC)
              </MenuItem>
              <MenuItem value={12}>
                <i className="bank bank-uob xxxl mr-4"></i>ธนาคารยูโอบี (UOBT)
              </MenuItem>
            </Select>
            </FormControl>
          </div>

          <div className="flex w-full md:w-6/6 mx-auto my-5">
            <TextField
              name="codebank"
              value={codebank}
              onChange={(e) => setCodebank(e.target.value)}
              id="outlined-textarea"
              type={"tel"}
              size="small"
              label="หมายเลขบัญชี"
              autoComplete="off"
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              inputProps={{ maxLength: 13 }}
              className="w-6/6"
              fullWidth
            />
          </div>
          {msgErr4 ? <ErrorMessage message={msgErr4} /> : <></>}
        </form>
      </Modal>
    </div>
  );
}

export default Adduser;
