import React, { useState, useEffect } from "react";
import { Table, Spin, Tag } from "antd";
import numberFormat from "../../../Function/NumberFormat2float";
import OpenNotification from "../../../Toast/OpenNotification";

import Adduser from "./Adduser";
import API_URL from "../../.././config/api";


import { Link } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { IconButton, TextField } from "@mui/material";
import Swal from "sweetalert2";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import SearchIcon from "@mui/icons-material/Search";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

import { useWebSocket } from "../../back/WebSocketContext";

function User_all() {
  const { sendMessage, websocket } = useWebSocket();

  const [allUser, setAllUser] = useState([]);
  const [allUserBackup, setAllUserBackup] = useState([]);
  const [loading, setLoading] = useState(true);
  const [getId, setGetId] = useState(null);

  const [isModalUser, setIsModalUser] = useState(false);

  const [inputSearch, setInputSearch] = useState("");

  useEffect(() => {
    get_Alluser();
  }, []);

  useEffect(() => {
    if (websocket) {
      websocket.onmessage = (message) => {
        const data = JSON.parse(message.data);

        if (data.type === "refreshUsers") {
          get_Alluser();
        }
      };
    }

    return () => {
      if (websocket) {
        websocket.onmessage = null;
      }
    };
  }, [websocket]);

  const showModalUser = () => {
    setIsModalUser(!isModalUser);
    if (isModalUser === true) {
      setGetId(null);
    }
  };

  const get_Alluser = async () => {
    setAllUser([]);
    setAllUserBackup([]);
    setLoading(true);
    await API_URL.get(`api/people/allPeople`)
      .then((res) => {
        const getalluser = res.data;
        if (getalluser.length !== 0) {
          getalluser.map((e) => {
            e.credit = e.credit.toString();
          });
          getalluser.sort((a, b) => (b.createdAt > a.createdAt ? 1 : -1));
          setAllUser(getalluser);
          setAllUserBackup(getalluser);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const showDeleteConfirm = (id, user) => {
    Swal.fire({
      title: "ยืนยันที่จะลบ user นี้?",
      text: user.firstname + "\t\t\t" + user.lastname,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ยืนยันการลบ",
      cancelButtonText: "ยกเลิก",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (user.imagefrontcard !== null) {
            let string = "";
            const array = user.imagefrontcard.split("\\");
            string = "./" + array.join("/");

            await API_URL.post(`api/people/deleteimagefrontcard`, {
              id: getId,
              imageFrontCardBackup: string,
            });
          }
        } catch (e) {}
        try {
          if (user.imagebackcard !== null) {
            let string = "";
            const array = user.imagebackcard.split("\\");
            string = "./" + array.join("/");

            await API_URL.post(`api/people/deleteimagebackcard`, {
              id: getId,
              imageBackCardBackup: string,
            });
          }
        } catch (e) {}

        await API_URL.delete(`api/people/deletePeople/${id}`)
          .then(() => {
            try {
              sendMessage({ type: "refreshUsers" });
            } catch (error) {
              console.log("Failed:", error);
            }
            OpenNotification({ message: "ลบเรียบร้อย", type: 1 });
          })
          .catch((err) => {
            console.log(err);
            OpenNotification({ message: "เกิดข้อผิดพลาด", type: 4 });
          });
      }
    });
  };

  const columns_alluser = [
    {
      title: "ชื่อ - นามสกุล",

      render: (allUser) => (
        <div className="text-xs  max-w-xl my-auto">
          {allUser.firstname}
          <br />
          {allUser.lastname}
        </div>
      ),
      responsive: ["xs"],
    },
    {
      title: "ชื่อ",
      dataIndex: "firstname",
      render: (firstname) => (
        <p className="text-xs  max-w-xl my-auto"> {firstname}</p>
      ),
      responsive: ["sm"],
    },

    {
      title: "นามสกุล",
      dataIndex: "lastname",

      render: (lastname) => (
        <p className="text-xs text-black my-auto px-2">{lastname}</p>
      ),
      responsive: ["sm"],
    },
    {
      title: "UID",
      dataIndex: "uid",
      render: (uid) => <p className="text-xs  my-auto truncate"> {uid}</p>,
    },
    {
      title: "เบอร์โทร",
      dataIndex: "phone",
      align: "center",

      render: (phone) => (
        <p className="text-xs text-black my-auto px-2">{phone}</p>
      ),
    },
    {
      title: "password",
      dataIndex: "password",
      align: "center",

      render: (password) => (
        <p className="text-xs text-black my-auto px-2">{password}</p>
      ),
    },
    {
      title: "email",
      dataIndex: "email",
      align: "center",

      render: (email) => (
        <p className="text-xs text-black my-auto px-2">{email}</p>
      ),
    },
    {
      title: "ยืนยันตัวตน",
      dataIndex: "verify_status",
      align: "center",
      render: (verify_status) => (
        <div className="text-xs">
          {verify_status === 0 ? (
            <div className="flex align-middle justify-center">
              <DoNotDisturbOnIcon className="text-red-600 mr-2" />
              <p className="my-auto">ไม่ได้ยืนยัน</p>
            </div>
          ) : verify_status === 1 ? (
            <div className="flex align-middle justify-center">
              <CheckCircleIcon className="text-green-600 mr-2" />
              <p className="my-auto">ยืนยันแล้ว</p>
            </div>
          ) : verify_status === 2 ? (
            <div className="flex align-middle justify-center">
              <CancelIcon className="text-red-600 mr-2" />
              <p className="my-auto">ไม่ผ่าน</p>
            </div>
          ) : (
            <div className="flex align-middle justify-center">
              <ChangeCircleIcon className="text-orange-400 mr-2" />
              <p className="my-auto">รออนุมัติ</p>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "เครดิต",
      dataIndex: "credit",
      align: "center",

      render: (credit) => (
        <Tag color="green">
          <p className="text-xs text-black my-auto px-2">
            {numberFormat(credit)}
          </p>
        </Tag>
      ),
    },
    {
      title: "การจัดการ",
      align: "center",
      dataIndex: "id",
      key: "id",
      render: (id, allUser) => (
        <div className="flex justify-center">
          <Link className="my-auto" to={`/admin/user/${id}`}>
            <SearchIcon fontSize="medium" color="warning" />
          </Link>
          <IconButton onClick={() => showDeleteConfirm(id, allUser)}>
            <DeleteForeverIcon fontSize="small" color="error" />
          </IconButton>
        </div>
      ),
    },
  ];
  return (
    <div>
      {isModalUser ? (
        <Adduser
          isModalUser={isModalUser}
          showModalUser={showModalUser}
          get_Alluser={get_Alluser}
          allUser={allUser}
          getId={getId}
        />
      ) : (
        <></>
      )}
      <div className="my-2 md:flex items-end">
        <div className="w-full md:w-2/3 hidden md:flex items-center justify-center mb-3 md:mb-0">
          <div className=" w-1/2 flex my-auto">
            <div className="w-5/6 flex bg-sky-600 rounded-md  px-2 py-1 shadow-lg">
              <div className="py-3">
                <PermContactCalendarIcon
                  fontSize="small"
                  className="text-white mx-5 my-auto"
                />
              </div>
              <div className="ml-7 my-auto py-1 h-auto">
                <p className="text-left text-sm font-semibold text-white drop-shadow-2xl shadow-black my-auto mr-4">
                  จำนวน User ทั้งหมด
                </p>
                <p className="text-left truncate text-md my-auto md:text-sm font-semibold text-white drop-shadow-2xl shadow-black">
                  {allUser.length}
                </p>
              </div>
            </div>
          </div>
          <div className="my-auto w-1/2  mx-auto md:ml-10 text-center">
            <button
              onClick={showModalUser}
              className=" justify-center text-center py-3 md:py-2 px-10 rounded-md shadow-lg bg-red-500 hover:bg-red-700 text-white text-sm md:text-md"
            >
              <AddCircleIcon className="my-auto mr-1 md:mr-5" />
              เพิ่ม User
            </button>
          </div>
        </div>

        <div className="w-full  flex md:hidden items-center justify-center mb-3">
          <div className=" w-1/2 flex bg-sky-600 rounded-md  px-2 shadow-lg my-auto mr-1">
            <div className="py-3 w-1/6">
              <PermContactCalendarIcon
                fontSize="small"
                className="text-white mx-5 my-auto"
              />
            </div>
            <div className="ml-7 my-auto py-1 h-auto w-5/6">
              <p className="text-left text-xs font-semibold text-white drop-shadow-2xl shadow-black my-auto mr-4 txtinfo">
                User ทั้งหมด
              </p>
              <p className="text-left truncate text-md my-auto md:text-sm font-semibold text-white drop-shadow-2xl shadow-black txtinfo">
                {allUser.length}
              </p>
            </div>
          </div>
          <div className="my-auto w-1/2  mx-auto md:ml-10 text-center">
            <button
              onClick={showModalUser}
              className=" w-full justify-center text-center py-3 md:py-2 px-10 rounded-md shadow-lg bg-red-500 hover:bg-red-700 text-white text-xs txtinfo"
            >
              <div className="flex justify-center">
                <AddCircleIcon
                  fontSize="small"
                  className="my-auto mr-1 md:mr-5"
                />
                <p className="txtinfo my-auto">เพิ่ม User</p>
              </div>
            </button>
          </div>
        </div>

        <div className="w-full md:w-1/3 mx-auto  flex">
          <TextField
            className="bg-white rounded-md"
            name="password"
            size="small"
            color="secondary"
            id="outlined-textarea"
            autoComplete="off"
            label="ค้นหาชื่อ ,e-mail,เครดิต และอื่นๆ"
            // value={newpass}
            // onChange={(e) => setNewpass(e.target.value)}
            InputProps={{
              endAdornment: <SearchIcon position="end"></SearchIcon>,
            }}
            fullWidth
            value={inputSearch}
            onChange={(e) => {
              const currValue = e.target.value;
              setInputSearch(currValue);
              const filteredData = allUserBackup.filter(
                (entry) =>
                  entry.firstname
                    .toLowerCase()
                    .includes(currValue.toLowerCase()) ||
                  entry.lastname
                    .toLowerCase()
                    .includes(currValue.toLowerCase()) ||
                  entry.uid.toLowerCase().includes(currValue.toLowerCase()) ||
                  entry.phone.toLowerCase().includes(currValue.toLowerCase()) ||
                  entry.password
                    .toLowerCase()
                    .includes(currValue.toLowerCase()) ||
                  entry.email.toLowerCase().includes(currValue.toLowerCase()) ||
                  entry.codebank
                    .toLowerCase()
                    .includes(currValue.toLowerCase()) ||
                  entry.credit.includes(currValue)
              );
              setAllUser(filteredData);
            }}
          />
        </div>
      </div>
      <Table
        size="middle"
        scroll={{
          x: 800,
        }}
        className="table-striped-rows"
        pagination={{ pageSize: 15 }}
        rowKey="id"
        columns={columns_alluser}
        dataSource={allUser}
        loading={{
          indicator: (
            <div>
              <Spin size="large" />
            </div>
          ),
          spinning: loading,
        }}
      />
    </div>
  );
}
export default User_all;
