import React,{useState} from 'react'
import Login from './Login'
import './back_style.css'
import Dashboard from './Dashboard'

export default function Back_main() {
    const [token] = useState(localStorage.getItem("awesomeLeadsToken"))
  return (
    <div>{!token ? (<Login/>):(<Dashboard/>)}</div>
  )
}
