import React, { useState, useEffect } from "react";
import { OutlinedInput, Divider } from "@mui/material";

import API_URL from "../../../config/api";
import OpenNotification from "../../../Toast/OpenNotification";
import PercentIcon from "@mui/icons-material/Percent";

function Payrate() {
  const [rate, setRate] = useState([]);
  const min = [3, 5, 15, 30];

  useEffect(() => {
    getPayrate();
  }, []);

  const getPayrate = async () => {
    setRate([]);
    await API_URL.get(`api/percentrate/allpercentrate`)
      .then((res) => {
        const allpayrate = res.data;
        const newStateArray = [];
        if (allpayrate.length !== 0) {
          allpayrate.map((payrate) => {
            // setRate((prevState) => ([
            //   ...prevState,
            //   { id: payrate.id, percent: payrate.percent },
            // ]));
            newStateArray.push({ id: payrate.id, percent: payrate.percent });
          });
        }
        setRate(newStateArray);

        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdateWebUrl = async (e) => {
    e.preventDefault();

    await API_URL.put(`api/percentrate/updatepercentrate`, rate)
      .then((res) => {
        OpenNotification({ message: "แก้ไขเรียบร้อย", type: 1 });
        // window.location.reload();
        getPayrate();
      })
      .catch((err) => {
        OpenNotification({ message: "มีข้อผิดพลาด", type: 3 });
      });
  };

  const updateValue = (e, index) => {
    const newRate = [...rate];
    newRate[index].percent = e.target.value;
    setRate(newRate);
  };

  return (
    <div className="flex justify-center">
      <form className="w-full md:w-4/6 bg-white p-5 rounded-md ">
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 mb-5">
          {rate.length !== 0 &&
            rate.map((data, i) => (
              <div className="w-full mx-auto h-auto bg-slate-200 rounded-md p-5" key={i}>
                <div className="flex justify-start mb-1 lg:mb-4">
                  <label className="block  text-lg text-black my-auto">
                    {min[i]} นาที
                  </label>
                </div>
                <OutlinedInput
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  value={data.percent}
                  onChange={(e) => updateValue(e, i)}
                  className="block w-full  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  aria-describedby="outlined-weight-helper-text"
                  autoComplete="off"
                  fullWidth
                  size="small"
                  endAdornment={
                    <PercentIcon
                      position="end"
                      className=" text-black"
                    ></PercentIcon>
                  }
                />
              </div>
            ))}
        </div>

        <Divider className="" sx={{ borderBottomWidth: 3 }} />

        <div className="w-full mx-auto my-5"></div>
        <div className="text-center">
          <button
            onClick={UpdateWebUrl}
            className="text-center text-white font-semibold  px-20 py-4 text-md rounded-md bg-gray-900 hover:bg-gray-800 shadow-md"
          >
            บันทึก
          </button>
        </div>
      </form>
    </div>
  );
}

export default Payrate;
