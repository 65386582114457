import React, { useState, useEffect } from "react";

import { Table, Spin, Tag } from "antd";
import numberFormat from "../../../../Function/NumberFormat2float";
import dayjs from "dayjs";
import API_URL from "../../../../config/api";


function TradeList({ peopleId }) {
  const [alltrade, setAlltrade] = useState([]);
  const [alltradeloading, setAlltradeLoading] = useState(false);

  useEffect(() => {
    get_OneUserAllTrade();
  }, []);

  const get_OneUserAllTrade = async () => {
    setAlltrade([]);
    setAlltradeLoading(true);
    await API_URL.get(`api/tradelist/getoneuseralltradeadmin/${peopleId}`)
      .then((res) => {
        const getalluser = res.data;

        if (getalluser.length !== 0) {
          getalluser.sort((a, b) => (b.createdAt > a.createdAt ? 1 : -1));
          setAlltrade(getalluser);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setAlltradeLoading(false);
  };
  const columns_trading = [
    {
      title: `ลำดับ`,
      align: "center",
      width: "8%",
      render: (alltrade, record, index) => (
        <p className=" text-xs text-black my-auto px-2">{index + 1}</p>
      ),
    },

    {
      title: `หุ้น`,
      align: "center",
      render: (alltrade) => (
        <p className=" text-xs text-black my-auto px-2">{alltrade.symbol}</p>
      ),
    },

    {
      title: `ประเภทซื้อ`,
      align: "center",
      render: (alltrade) => (
        <>
          {alltrade.type_order === 1 ? (
            <Tag color="green">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                ขึ้น
              </p>
            </Tag>
          ) : (
            <Tag color="volcano">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                ลง
              </p>
            </Tag>
          )}
        </>
      ),
    },
    {
      title: `จำนวน`,
      align: "center",
      render: (alltrade) => (
        <p className="wsn text-xs text-black my-auto px-2">
          {numberFormat(alltrade.amount)}
        </p>
      ),
    },
    {
      title: `เวลาเปิดออเดอร์`,
      align: "center",
      render: (alltrade) => (
        <p className="wsn text-xs text-black my-auto px-2">
          {dayjs(alltrade.opening_time).format("DD/MM/YYYY HH:mm:ss")}
        </p>
      ),
    },
    {
      title: `เปิด-ราคา`,
      align: "center",
      render: (alltrade) => (
        <p className="wsn text-xs text-black my-auto px-2">
          {numberFormat(alltrade.opening_price)}
        </p>
      ),
    },
    {
      title: `เวลาปิดออเดอร์`,
      align: "center",
      render: (alltrade) => (
        <p className="wsn text-xs text-black my-auto px-2">
          {dayjs(alltrade.closing_time).format("DD/MM/YYYY HH:mm:ss")}
        </p>
      ),
    },
    {
      title: "ปิด-ราคา",
      align: "center",
      render: (alltrade) => (
        <p className="wsn text-xs text-black my-auto px-2">
          {numberFormat(alltrade.closing_price)}
        </p>
      ),
    },

    {
      title: "ผลลัพธ์",
      align: "center",
      render: (alltrade) => (
        <p
          className={
            "wsn text-xs my-auto px-2 " +
            (alltrade.trade_result === 0 ? "text-green-600" : "text-red-600")
          }
        >
          {alltrade.trade_result === 0 ? "+" : "-"} {alltrade.net} USDT
        </p>
      ),
    },
  ];
  return (
    <div>
      <Table
        className="table-striped-rows  "
        size="middle"
        scroll={{
          x: 800,
        }}
        pagination={{ pageSize: 15 }}
        rowKey="id"
        columns={columns_trading}
        dataSource={alltrade}
        loading={{
          indicator: (
            <div>
              <Spin size="large" />
            </div>
          ),
          spinning: alltradeloading,
        }}
      />
    </div>
  );
}

export default TradeList;
