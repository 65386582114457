import React from 'react';
const BankSwitch = (type) => {
  switch (type) {
    case 1:
      return (
        <div className='flex items-center'>
          <i className="bank bank-kbank xxxl mr-4"></i>ธนาคารกสิกรไทย (KBank)
        </div>
      );
      case 2:
      return (
        <div className='flex items-center'>
          <i className="bank bank-bbl xxxl mr-4"></i>ธนาคารกรุงเทพ (BBL)
        </div>
      );
      case 3:
      return (
        <div className='flex items-center'>
          <i className="bank bank-scb xxxl mr-4"></i>ธนาคารไทยพาณิชย์ (SCBB)
        </div>
      );
      case 4:
      return (
        <div className='flex items-center'>
          <i className="bank bank-ktb xxxl mr-4"></i>ธนาคารกรุงไทย (KTB)
        </div>
      );
      case 5:
      return (
        <div className='flex items-center'>
          <i className="bank bank-bay xxxl mr-4"></i>ธนาคารกรุงศรี (BAY)
        </div>
      );
      case 6:
      return (
        <div className='flex items-center'>
          <i className="bank bank-tmb xxxl mr-4"></i>ธนาคารทหารไทยธนชาต (TTB)
        </div>
      );
      case 7:
      return (
        <div className='flex items-center'>
          <i className="bank bank-lhb xxxl mr-4"></i>ธนาคารแลนด์แอนด์เฮ้าส์ (LH Bank)
        </div>
      );
      case 8:
      return (
        <div className='flex items-center'>
          <i className="bank bank-gsb xxxl mr-4"></i>ธนาคารออมสิน (GSB)
        </div>
      );
      case 9:
      return (
        <div className='flex items-center'>
          <i className="bank bank-kk xxxl mr-4"></i>ธนาคารเกียรตินาคินภัทร (KKP)
        </div>
      );
      case 10:
      return (
        <div className='flex items-center'>
          <i className="bank bank-citi xxxl mr-4"></i>ธนาคารซิตี้แบงก์ (CITI)
        </div>
      );
      case 11:
      return (
        <div className='flex items-center'>
         <i className="bank bank-baac xxxl mr-4"></i>ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร (BAAC)
        </div>
      );
      case 12:
      return (
        <div className='flex items-center'>
          <i className="bank bank-uob xxxl mr-4"></i>ธนาคารยูโอบี (UOBT)
        </div>
      );
      default : return <p>ไม่มีข้อมูล</p>

  }
};
export default BankSwitch;
