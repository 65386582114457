import React from "react";
import { Table, Spin, Tag } from "antd";
import { useState, useEffect } from "react";
import CreditadminModal from "./CreditadminModal";
import API_URL from "../../../config/api";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";

import numberFormat from "../../../Function/NumberFormat2float";

function Creditadmin() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [allcredit, setAllcredit] = useState([]);
  const [allcreditBackup, setAllcreditBackup] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inputSearch, setInputSearch] = useState("");

  useEffect(() => {
    get_AllCreditAdmin();
    // const interval = setInterval(() => {
    //   get_AllCreditAdmin();
    // }, 30000);
    // return () => clearInterval(interval);
  }, []);

  const get_AllCreditAdmin = async () => {
    setAllcredit([]);
    setAllcreditBackup([]);
    setLoading(true);
    await API_URL.get(`api/creditadmin/AllCreditadminToday`)
      .then((res) => {
        const getalluser = res.data;
        if (getalluser.length !== 0) {
          getalluser.map((e) => {
            e.amountxt = e.amount.toString();
            e.createdAttxt = dayjs(e.createdAt)
              .format("DD/MM/YYYY HH:mm")
              .toString();
          });
          setAllcredit(getalluser);
          setAllcreditBackup(getalluser);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const showModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const columns_alluser = [
    {
      title: "ชื่อ - นามสกุล",

      render: (allcredit) => (
        <div className="text-xs  max-w-xl my-auto">
          {allcredit.people.firstname}
          <br />
          {allcredit.people.lastname}
        </div>
      ),
    },
    {
      title: "Uid",
      render: (allcredit) => (
        <p className="text-xs text-black my-auto px-2">
          {allcredit.people.uid}
        </p>
      ),
    },
    {
      title: "ประเภทเพิ่ม / ลด",
      align: "center",
      render: (allcredit) => (
        <>
          {allcredit.credittype === 1 ? (
            <Tag color="green">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                เพิ่ม
              </p>
            </Tag>
          ) : (
            <Tag color="volcano">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                ลด
              </p>
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "เครดิต",
      align: "center",
      render: (allcredit) => (
        <p className="text-xs text-black my-auto px-2">
          {numberFormat(allcredit.amount)}
        </p>
      ),
    },
    {
      title: "ยอดเดิม",
      align: "center",
      render: (allcredit) => (
        <p className="text-xs text-black my-auto px-2">
          {numberFormat(allcredit.preamount)}
        </p>
      ),
    },
    {
      title: "คงเหลือ",
      align: "center",
      render: (allcredit) => (
        <Tag color="green">
          <p className="text-xs text-black my-auto px-2 py-1">
            {allcredit.credittype === 1 ? (
              <>{numberFormat(allcredit.amount + allcredit.preamount)}</>
            ) : (
              <>{numberFormat(allcredit.preamount - allcredit.amount)}</>
            )}
          </p>
        </Tag>
      ),
    },
    {
      title: "หมายเหตุ",
      width: "15%",
      ellipsis: {
        showTitle: true,
      },
      render: (allcredit) => (
        <p className="text-xs text-black my-auto px-2">
          {allcredit.note === "" ? "-" : allcredit.note}
        </p>
      ),
    },
    {
      title: "Admin",
      width: "10%",
      align: "center",
      ellipsis: {
        showTitle: true,
      },
      render: (allcredit) => (
        <p className="text-xs text-black my-auto px-2">{allcredit.user.name}</p>
      ),
    },
    {
      title: "เวลา",
      align: "center",
      render: (allcredit) => (
        <p className="text-xs text-black my-auto px-2">
          {dayjs(allcredit.createdAt).format("DD/MM/YYYY HH:mm")}
        </p>
      ),
    },
  ];



  return (
    <div>
      {isModalVisible ? (
        <CreditadminModal
          isModalVisible={isModalVisible}
          showModal={showModal}
          get_AllCreditAdmin={get_AllCreditAdmin}
        />
      ) : (
        <></>
      )}
      <div className="grid grid-cols-2">
        <div></div>
        <div className="flex justify-end">
          <button
            onClick={showModal}
            className=" justify-center text-center py-2 md:py-2 px-10 rounded-md shadow-lg bg-red-500 hover:bg-red-700 text-white text-sm md:text-md"
          >
            เพิ่ม / ลดเครดิต
          </button>
        </div>
      </div>
      <div className="my-3">
        <div className="w-full flex justify-end my-1">
          <div className="w-full md:w-1/3   ">
            <TextField
              className="bg-white rounded-md"
              name="password"
              size="small"
              color="secondary"
              id="outlined-textarea"
              autoComplete="off"
              label="ค้นหาชื่อ, Username, วันที่, เครดิต"
              InputProps={{
                endAdornment: <SearchIcon position="end"></SearchIcon>,
              }}
              fullWidth
              value={inputSearch}
              onChange={(e) => {
                const currValue = e.target.value;
                setInputSearch(currValue);
                const filteredData = allcreditBackup.filter(
                  (entry) =>
                    entry.people.firstname
                      .toLowerCase()
                      .includes(currValue.toLowerCase()) ||
                    entry.people.lastname
                      .toLowerCase()
                      .includes(currValue.toLowerCase()) ||
                    entry.people.uid
                      .toLowerCase()
                      .includes(currValue.toLowerCase()) ||
                    entry.user.name
                      .toLowerCase()
                      .includes(currValue.toLowerCase()) ||
                    entry.createdAttxt
                      .toLowerCase()
                      .includes(currValue.toLowerCase()) ||
                    entry.amountxt.includes(currValue)
                );
                setAllcredit(filteredData);
              }}
            />
          </div>
        </div>
        <Table
          className="table-striped-rows"
          size="middle"
          scroll={{
            x: 900,
          }}
          pagination={{ pageSize: 15 }}
          rowKey="id"
          columns={columns_alluser}
          dataSource={allcredit}
        />
      </div>
    </div>
  );
}

export default Creditadmin;
