import React, { useState } from "react";
import { useCookies } from "react-cookie";

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import History_Deposit from "./History_Deposit";
import History_Creditadmin from "./History_Creditadmin";

function MainCredit() {
  const [selectIndexCK, setSelectIndexCK] = useCookies(["selectIndexCK"]);
  const [selectIndex, setSelectIndex] = useState(
    Number(selectIndexCK.selectIndexCK) || 1
  );

  return (
    <div>
      <div className="grid grid-cols-2 lg:grid-cols-5">
        <div className="flex w-full md:w-6/6 mx-auto my-1">

          <FormControl className="w-full">
            <InputLabel className="my-auto">ประเภท</InputLabel>
            <Select
              name="selectIndex"
              className="bg-white"
              size="small"
              label="ประเภท"
              defaultValue={selectIndex}
              value={selectIndex}
              onChange={(e) => {
                setSelectIndexCK("selectIndexCK", e.target.value, {
                  path: `/`,
                });
                setSelectIndex(e.target.value);
              }}
              fullWidth
            >
              <MenuItem value={1}>เครดิต admin</MenuItem>
              <MenuItem value={2}>ถอน</MenuItem>
              {/* <MenuItem value={3}>ฝาก</MenuItem> */}
              {/* <MenuItem value={4}>ฝาก - ถอน</MenuItem> */}
              
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="relative flex flex-col min-w-0 break-words  w-full mb-6  ">
        <div className="px-0.5 lg:px-1 py-1 flex-auto ">
          <div className="tab-content tab-space"style={{minHeight:"600px"}}>
            {/* tab1 */}

         
            <div className={selectIndex === 1 ? "block" : "hidden"} id="link4">
              <History_Creditadmin/>
            </div>
            <div className={selectIndex === 2 ? "block" : "hidden"} id="link1">
              <History_Deposit selectIndex={selectIndex}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainCredit;
