import React, { useState, useEffect } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";

//mainsite jsx
import BitcoinChartTradingView from "./Mainsite/BitcoinChartTradingView";
import CryptoCarousel from "./Mainsite/CryptoCarousel";
import VerticalCarousel from "./Mainsite/VerticalCarousel";
import LanguageSelector from "../language-selector";

// image
import trade_test2 from "../../image/download-trading-partner.png";
import trade_mobilehead from "../../image/mobile-trading-mt4.webp";
import trade_2 from "../../image/trade_2.png";
import trade_3 from "../../image/trade_3.png";
import trade_cover from "../../image/trade_bg.png";
import trade_overlay from "../../image/trade_overlay.svg";
import gift from "../../image/gift_ic.webp";
import social1 from "../../icon/logo-facebook2.webp";
import social2 from "../../icon/line_logo_icon_147270.webp";
import social3 from "../../icon/website-icon-11.png";
import social4 from "../../icon/gmail_ic.png";

import logoWeb from "../../image/Bitcoin-SV-logo-white.png";

//icon
import trade_ic from "../../icon/trade_ic.png";
import {
  PlayIcon,
  FingerPrintIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/solid";
import {
  ChartBarIcon,
  CurrencyDollarIcon,
  CursorArrowRaysIcon,
  Square3Stack3DIcon,
  AcademicCapIcon,
  CogIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import ChatwootComponent from "./ChatwootWidget";
import API_URL from "../../config/api";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Logoweb from "../../Function/Logoweb";
import Nameweb from "../../Function/Nameweb";

export default function MainWebsite() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [facebookURL, setFacebookURL] = useState("");
  const [lineURL, setLineURL] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [gmailURL, setGmailURL] = useState("");
  const [userId] = useState(localStorage.getItem("awesomeLeadsTokenId"));
  const toLogin = () => {
    if(!userId){
      navigate("/login/2");
    }else{
      navigate("/login/");
    }
    
  };
  const toRegister = () => {
    if(!userId){
      navigate("/login/1");
    }else{
      navigate("/login/");
    }
    
  };

  const BenefitItem = ({ icon: Icon, text }) => (
    <li className="flex items-center space-x-3 mb-4">
      <Icon className="h-6 w-6 text-blue-500" />
      <span>{text}</span>
    </li>
  );
  useEffect(() => {
    getWebUrl();
  }, []);

  const getWebUrl = async () => {
    await API_URL.get(`api/weburl/getallweburl`)
      .then((res) => {
        const allweburl = res.data;
        if (allweburl.length !== 0) {
          const resFacebook = allweburl.find(({ name }) => name === "facebook");
          setFacebookURL(resFacebook.nameurl);
          const resLine = allweburl.find(({ name }) => name === "line");
          setLineURL(resLine.nameurl);
          const resWebsite = allweburl.find(({ name }) => name === "website");
          setWebsiteURL(resWebsite.nameurl);
          const resGmail = allweburl.find(({ name }) => name === "gmail");
          setGmailURL(resGmail.nameurl);
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const TradingBenefitsList = () => (
    <ul className="mt-10 text-white space-y-2">
      <BenefitItem icon={ChartBarIcon} text="Access real-time analytics" />
      <BenefitItem
        icon={CurrencyDollarIcon}
        text={`${t("tradingbenefitslist1")}`}
      />
      <BenefitItem
        icon={CursorArrowRaysIcon}
        text={`${t("tradingbenefitslist2")}`}
      />
      <BenefitItem
        icon={Square3Stack3DIcon}
        text={`${t("tradingbenefitslist3")}`}
      />
      <BenefitItem
        icon={AcademicCapIcon}
        text={`${t("tradingbenefitslist4")}`}
      />
      <BenefitItem
        icon={ShieldCheckIcon}
        text={`${t("tradingbenefitslist5")}`}
      />
    </ul>
  );

  return (
    <div>
      {/* nav style */}
      <div className="w-full p-1 h-20 fixed z-50 top-0 main-bg-color shadow-lg overflow-hidden bg-slate-800 border-b-4 border-slate-900 text-white">
        <div className=" flex  items-center justify-between mx-auto">
          <div className="flex justify-start">
            <div className="my-auto h-9 lg:h-11 mr-1">
              <Logoweb />
            </div>
            <div className="text-white my-auto ">
              <Nameweb />
            </div>
          </div>

          <div className="flex justify-end my-4 md:m-4 items-center whitespace-nowrap">
            <button
              onClick={toLogin}
              className="text-shadow-1 bg-gradient-to-r text-xs lg:text-base  from-blue-500 to-blue-700 hover:bg-gradient-to-l text-white font-bold mr-2 py-1.5 lg:py-2 px-3 lg:px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded"
            >
              {t("login")}
            </button>
            <button
              onClick={toRegister}
              className="text-shadow-1 bg-gradient-to-r text-xs lg:text-base from-red-700 to-red-500 hover:bg-blue-400 text-white font-bold py-1.5 lg:py-2 px-3 lg:px-4 border-b-4 border-red-700 hover:border-red-500 rounded mr-1 lg:mr-3"
            >
              {t("register")}
            </button>
            <div className="flex items-center">
              <LanguageSelector />
            </div>
          </div>
        </div>
      </div>
      <div className="w-ful bg-zinc-900">
        {/* content section 1 */}
        <div className="w-full   grid grid-cols-1 lg:grid-cols-5 justify-center mx-auto align-center pt-24">
          <div className="w-full lg:w-1/2 col-span-3 block mx-auto pt-8 lg:pt-20">
            <div className="grid gap-5 whitespace-nowrap lg:gap-9 text-6xl lg:text-8xl text-center  text-white font-semibold  justify-center mx-auto align-center my-auto">
              <p>{t("profitability")} </p>
              <p>{t("ontherise")}</p>
            </div>
            <div className="w-3/4 md:w-2/3 block mx-auto mt-5 lg:mt-20  ">
              <button
                onClick={toLogin}
                className="bg-gradient-to-t from-indigo-700 to-indigo-600 hover:bg-gradient-to-b text-white font-bold p-2 lg:p-4 rounded inline-flex justify-center items-center"
              >
                <LazyLoadImage
                  src={trade_ic}
                  className="w-1/6 md:w-1/12 mr-4"
                  alt=""
                />
                <span className="hidden md:inline">
                  {t("letstarttradingonweb")}
                </span>
                <span className="md:hidden text-base">
                  {t("starttradingnow")}
                </span>
              </button>
            </div>
          </div>
          <div className="  w-full flex justify-center lg:justify-start items-center mx-auto col-span-2">
            <LazyLoadImage
              src={trade_test2}
              className="w-3/4 hidden lg:block"
              alt=""
            />
            {/* <img
              src={trade_mobilehead}
              className="w-3/4 block lg:hidden rounded-md"
              alt=""
            /> */}
          </div>
        </div>
        {/* crypto carousel */}
        <div className="w-full flex mt-2 lg:mt-0 relative">
        <div className="absolute inset-0"></div>
          <CryptoCarousel />
          {/* <iframe
            scrolling="no"
            allowtransparency="true"
            frameborder="0"
            src="https://www.tradingview-widget.com/embed-widget/ticker-tape/?locale=th_TH#%7B%22symbols%22%3A%5B%7B%22title%22%3A%22%22%2C%22proName%22%3A%22FX_IDC%3AEURUSD%22%7D%2C%7B%22title%22%3A%22%22%2C%22proName%22%3A%22FOREXCOM%3AXAUUSD%22%7D%2C%7B%22title%22%3A%22%22%2C%22proName%22%3A%22BITKUB%3AETHTHB%22%7D%2C%7B%22title%22%3A%22%22%2C%22proName%22%3A%22BITKUB%3ABNBTHB%22%7D%2C%7B%22title%22%3A%22%22%2C%22proName%22%3A%22BITKUB%3AXRPTHB%22%7D%2C%7B%22title%22%3A%22%22%2C%22proName%22%3A%22BITKUB%3AADATHB%22%7D%2C%7B%22title%22%3A%22%22%2C%22proName%22%3A%22BITKUB%3ADOGETHB%22%7D%2C%7B%22title%22%3A%22%22%2C%22proName%22%3A%22BITKUB%3ASOLTHB%22%7D%2C%7B%22title%22%3A%22%22%2C%22proName%22%3A%22BITKUB%3AMATICTHB%22%7D%2C%7B%22title%22%3A%22%22%2C%22proName%22%3A%22BITKUB%3ADOTTHB%22%7D%2C%7B%22title%22%3A%22%22%2C%22proName%22%3A%22BINANCE%3ASHIBUSDT%22%7D%2C%7B%22title%22%3A%22%22%2C%22proName%22%3A%22BITKUB%3ATRXTHB%22%7D%2C%7B%22description%22%3A%22%22%2C%22proName%22%3A%22BITKUB%3AAVAXTHB%22%7D%2C%7B%22title%22%3A%22%22%2C%22proName%22%3A%22BITKUB%3AUNITHB%22%7D%2C%7B%22title%22%3A%22%22%2C%22proName%22%3A%22BITKUB%3AATOMTHB%22%7D%2C%7B%22title%22%3A%22%22%2C%22proName%22%3A%22BITKUB%3ALINKTHB%22%7D%5D%2C%22showSymbolLogo%22%3Atrue%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22displayMode%22%3A%22adaptive%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A46%2C%22utm_source%22%3A%22horizen-gold.com%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22ticker-tape%22%2C%22page-uri%22%3A%22horizen-gold.com%2F%22%7D"
            title="ticker tape TradingView widget"
            lang="en"
            className="ifram_tib w-full"
            style={{ height: "40px" }}
          ></iframe> */}
        </div>
        {/* content section 2 */}
        <div className="w-full px-4 md:px-0 container block justify-center mx-auto align-center lg:mt-10">
          <div>
            <h1 className="text-2xl md:text-4xl text-white font-bold md:font-semibold  mx-auto align-center my-auto tracking-widest">
              {t("thelastestfromourbroker")}
            </h1>
            <h1 className="md:mt-4 text-lg md:text-xl text-gray-400 font-medium  mx-auto align-center my-auto tracking-wider">
              {t("newfeatures_lastestwebsiteandmore")}
            </h1>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-8 lg:grid-cols-12 gap-4 mt-4">
            <div className="col-span-1 sm:col-span-2 bg-gradient-to-t from-[#182128] to-[#2d3940] rounded-lg p-4 order-1">
              <BitcoinChartTradingView />
            </div>

            <div className="col-span-2 sm:col-span-5 sm:row-span-2 bg-gradient-to-t from-green-700 via-green-600 to-green-500 rounded-lg p-4 relative overflow-hidden order-3 sm:order-2">
              <div className="relative z-10">
                <h3 className="text-white font-bold mb-2">
                  {t("secure_account")}
                </h3>
                <p className="text-white mb-4">{t("now_your_fingerprint")}</p>
                <FingerPrintIcon className="h-12 w-12 text-white" />
              </div>
              <LazyLoadImage
                src={trade_overlay}
                alt="Phone with fingerprint"
                className="absolute right-0 bottom-0 w-1/4 h-auto object-contain"
              />
            </div>

            <div className="col-span-1 sm:col-span-2 text-white bg-gradient-to-b from-blue-500 via-blue-600 to-indigo-700 rounded-lg p-4 order-4 sm:order-3">
              <h3 className="font-bold mb-2">{t("market_analysis")}</h3>
              <p className="mb-4">{t("understanding_support")}</p>
              <div className="flex items-center">
                <div className="bg-white rounded-full p-2 mr-2">
                  <PlayIcon className="h-6 w-6 text-blue-600" />
                </div>
              </div>
            </div>

            <div className="col-span-1 sm:col-span-2 text-white bg-gradient-to-t from-blue-600 via-blue-500 to-indigo-500 rounded-lg p-4 order-5 sm:order-4">
              <h3 className="font-bold mb-2">{t("risk_management")}</h3>
              <p className="mb-4">{t("masteringthetworule")}</p>
              <div className="flex items-center">
                <ShieldCheckIcon className="h-10 w-10 text-white mr-2" />
              </div>
            </div>

            <div className="col-span-1 sm:col-span-2 text-white bg-gradient-to-t from-blue-700 via-blue-600 to-blue-500 rounded-lg p-4 order-2 sm:order-5">
              <h3 className="font-bold mb-2">{t("education")}</h3>
              <p className="mb-4">{t("howtostarttradingonplatform")}</p>
              <div className="flex items-center">
                <div className="bg-white rounded-full p-2 mr-2">
                  <PlayIcon className="h-6 w-6 text-blue-600" />
                </div>
              </div>
            </div>

            <div className="col-span-2 sm:col-span-4 text-white bg-gradient-to-t from-purple-700 via-purple-600 to-fuchsia-600 rounded-lg p-4 relative overflow-hidden order-6">
              <div className="relative z-10">
                <h3 className="font-bold mb-2">{t("anniversary")}</h3>
                <p>{t("ourbrokerrevolutionizing")}</p>
              </div>
              <LazyLoadImage
                src={gift}
                alt="Anniversary gift"
                className="absolute right-0 bottom-0 w-1/4 h-auto object-contain"
              />
            </div>
          </div>
        </div>
        {/* content section 3 */}
        <div className="w-full px-4 md:px-0 container block justify-center mx-auto align-center mt-10">
          <div className="w-full ">
            <h1 className="text-xl md:text-4xl font-semibold text-white mx-auto align-center my-auto tracking-widest">
              {t("smoothexperienceonanydevice")}
            </h1>
            <h1 className="md:mt-4 text-md md:text-xl text-gray-400 font-medium  mx-auto align-center my-auto tracking-wider">
              {t("whetheryouprefertrading")}
            </h1>
          </div>

          <div className="w-full block md:flex justify-center">
            <div className="w-full md:w-3/4">
              <LazyLoadImage
                src={trade_2}
                className="p-12 lg:px-60 md:py-10"
                alt=""
              />
            </div>
            <div className="w-full lg:w-1/4 block mx-auto py-4 md:py-10">
              <div className="bg-gradient-to-t from-[#182128] to-[#2d3940] p-5 rounded-lg">
                <h1 className="text-md md:text-xl text-white font-medium tracking-wider">
                  {t("custombuiltplatform")}
                </h1>
              </div>
              {/* <VerticalSlide /> */}
              <div className="w-full -mt-10 md:mt-5">
                <VerticalCarousel />
              </div>
            </div>
          </div>
        </div>
        {/* content section 4 */}
        <div className="w-full container flex flex-col sm:flex-row justify-center mx-auto items-center -mt-10 sm:mt-10 px-4 sm:px-6">
          <div className="w-full sm:w-1/2 block mb-8 sm:mb-0">
            <h1 className="text-3xl sm:text-4xl text-white font-semibold text-center sm:text-left tracking-widest mb-6 sm:mb-0">
              {t("teamofexpert")}
            </h1>
            <div className="w-3/4 mb-8 sm:hidden mx-auto">
              <LazyLoadImage
                className="w-full"
                src={trade_3}
                alt="Team illustration"
              />
            </div>
            <TradingBenefitsList />
            <div className="w-full sm:w-1/2 block mt-8 sm:mt-20">
              <button
                onClick={toLogin}
                className="w-full sm:w-auto bg-gradient-to-t from-indigo-700 to-indigo-600 hover:bg-gradient-to-b hover:-translate-y-1 sm:hover:-translate-y-6 text-white hover:scale-105 sm:hover:scale-110 transition-all duration-300 font-bold py-4 px-4 rounded flex justify-center items-center"
              >
                <LazyLoadImage
                  src={trade_ic}
                  className="w-8 sm:w-1/12 mr-4"
                  alt=""
                />
                <span className="text-sm sm:text-base">
                  {t("letstarttradingonweb")}
                </span>
              </button>
            </div>
          </div>
          <div className="hidden sm:flex w-full sm:w-1/2 justify-center">
            <LazyLoadImage
              className="w-auto"
              src={trade_3}
              alt="Team illustration"
            />
          </div>
        </div>
        {/* content section 5 */}
        <div className="w-full container mx-auto px-4 py-12">
          <h1 className="text-4xl text-white font-semibold text-center mb-10 tracking-widest">
            {t("whyus")}
          </h1>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-purple-50 p-6 rounded-lg text-center">
              <div className="bg-purple-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                <CurrencyDollarIcon className="w-8 h-8 text-purple-600" />
              </div>
              <h3 className="text-xl font-semibold mb-2">{t("easytrading")}</h3>
              <p className="text-gray-600 mb-4">{t("ui_with_realtime")}</p>
              <a
                href="#"
                className="text-purple-600 hover:text-purple-700 inline-flex items-center"
              >
                {t("learnmore")}
                <ChevronRightIcon className="w-4 h-4 ml-1" />
              </a>
            </div>

            <div className="bg-purple-50 p-6 rounded-lg text-center">
              <div className="bg-purple-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                <ShieldCheckIcon className="w-8 h-8 text-purple-600" />
              </div>
              <h3 className="text-xl font-semibold mb-2">
                {t("safeandsecure")}
              </h3>
              <p className="text-gray-600 mb-4">{t("advancedencryption")}</p>
              <a
                href="#"
                className="text-purple-600 hover:text-purple-700 inline-flex items-center"
              >
                {t("learnmore")}
                <ChevronRightIcon className="w-4 h-4 ml-1" />
              </a>
            </div>

            <div className="bg-purple-50 p-6 rounded-lg text-center">
              <div className="bg-purple-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                <CogIcon className="w-8 h-8 text-purple-600" />
              </div>
              <h3 className="text-xl font-semibold mb-2">
                {t("customizable")}
              </h3>
              <p className="text-gray-600 mb-4">
                {t("personalize_your_trading_environment")}
              </p>
              <a
                href="#"
                className="text-purple-600 hover:text-purple-700 inline-flex items-center"
              >
                {t("learnmore")}
                <ChevronRightIcon className="w-4 h-4 ml-1" />
              </a>
            </div>
          </div>
        </div>
         {/* content section 6 */}
        <div
          className="w-full bg-cover bg-no-repeat"
          style={{ backgroundImage: `url(${trade_cover})` }}
        >
          <div className=" container block text-center text-white justify-center mx-auto align-center md:mt-20 py-10 md:py-20">
            <h1 className="text-2xl md:text-8xl font-semibold  mx-auto align-center my-auto tracking-widest">
              {t("enterthenewear")}
              <br />
            </h1>
            <h1 className="text-2xl md:text-8xl mt-5 md:mt-10 font-semibold  mx-auto align-center my-auto tracking-widest">
              {t("ofinvesting")}
            </h1>
            <button
              onClick={() => window.$chatwoot.toggle("open")}
              className="mt-10 px-20 md:px-40 py-4 md:py-6 bg-blue-500 hover:bg-blue-400 text-white font-bold  border-b-4 border-blue-700 hover:border-blue-500 rounded-full"
            >
              {t("contactus")}
            </button>
          </div>
          <ChatwootComponent />
        </div>
      </div>
      <footer
        className="px-9 py-5 text-center bg-gray-800"
        style={{
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
        }}
      >
          <div className="flex justify-center">
            <div className="h-10 lg:h-16 mr-1">
              <Logoweb />
            </div>
            <div className="text-white my-auto">
              <Nameweb />
            </div>
          </div>
        <div className="my-2">
          <p className="text-white w-full lg:w-1/2 text-center mx-auto">
            {t("footertxt1")}
          </p>
        </div>
        <div>
          <p className="text-lg text-white font-semibold bg-slate-900 px-3 py-1 rounded-md w-3/6 lg:w-1/6 text-center mx-auto">
            {t("contact")}
          </p>
        </div>

        <div className="grid grid-cols-4 w-full lg:w-2/5 mx-auto mt-2 ">
          <div>
            <a
              className="text-center flex-col text-base font-semibold"
              target="_blank"
              rel="noopener noreferrer"
              href={facebookURL}
            >
              <LazyLoadImage
                loading="lazy"
                src={social1}
                className="w-9 md:w-10  mx-5 my-2 inline"
              />
              <p className="text-white text-xs lg:text-base font-semibold">
                FACEBOOK
              </p>
            </a>
          </div>
          <div>
            <a
              className="text-center flex-col text-base"
              target="_blank"
              rel="noopener noreferrer"
              href={lineURL}
            >
              <LazyLoadImage
                loading="lazy"
                src={social2}
                className="w-9 md:w-10  mx-5 my-2 inline"
              />
              <p className="text-white text-xs lg:text-base font-semibold">
                LINE
              </p>
            </a>
          </div>
          <div>
            <a
              className="text-center flex-col text-base"
              target="_blank"
              rel="noopener noreferrer"
              href={gmailURL}
            >
              <LazyLoadImage
                loading="lazy"
                src={social4}
                className="w-9 md:w-10  mx-5 my-2 inline"
              />
              <p className="text-white text-xs lg:text-base font-semibold">
                GMAIL
              </p>
            </a>
          </div>
          <div>
            <a
              className="text-center flex-col text-base"
              target="_blank"
              rel="noopener noreferrer"
              href={websiteURL}
            >
              <LazyLoadImage
                loading="lazy"
                src={social3}
                className="w-9 md:w-10  mx-5 my-2 inline"
              />
              <p className="text-white text-xs lg:text-base font-semibold">
                WEBSITE
              </p>
            </a>
          </div>
        </div>
        <div className="bg-gray-700 h-0.5 my-5 w-5/6 mx-auto"></div>
        <div className="text-white w-full lg:w-2/3 text-center mx-auto">
          <p className="my-2 underline">{t("warning")}</p>
          <p>● {t("footertxt2")}</p>
        </div>
        <div className="mt-3 text-white text-xs lg:text-base pb-16 lg:pb-1">
          <p>© 2018. ALL RIGHTS RESERVED.</p>
        </div>
        <ChatwootComponent />
      </footer>
    </div>
  );
}
