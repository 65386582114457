import React, { useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LogoutIcon from "@mui/icons-material/Logout";

import numberFormat from "../../../Function/NumberFormat2float";

import { Link } from "react-router-dom";
import OpenNotification from "../../../Toast/OpenNotification";

import API_URL from "../../../config/api";

import CancelIcon from "@mui/icons-material/Cancel";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

import { useTranslation } from "react-i18next";
import CreditcardBG from "../../../image/creditcard_bg2.png";
import MastercardIMG from "../../../image/mastercard_img.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Account = ({ userdata, lineURL }) => {
  const { t } = useTranslation();
  const [userId] = useState(localStorage.getItem("awesomeLeadsTokenId"));
  const logout = () => {
    localStorage.removeItem("awesomeLeadsTokenId");
    localStorage.removeItem("awesomeLeadsTokenUserdata");
    if (window.$chatwoot) {
      window.$chatwoot.reset();
    }
    window.location.href = `/login`;
  };

  return (
    <div className="w-full lg:w-2/3 lg:mx-auto px-1">
      {/* <div className="flex justify-end align-middle items-center mb-3 ">
        <p className="text-sm lg:text-lg text-white py-2 rounded-md px-2 underline">
          UID : {userdata.uid}
        </p>
      </div>
      <div className="text-xs flex items-center justify-between p-4 border rounded-lg shadow-sm bg-gray-800  mb-2 ">
        <div className="flex items-center">
          <div className="p-2 rounded-full bg-gray-700">
  
            <svg
              height="21"
              viewBox="0 0 21 21"
              width="21"
              xmlns="http://www.w3.org/2000/svg"
              className="text-white stroke-2"
            >
              <g fill="none" fillRule="evenodd" transform="translate(3 4)">
                <path
                  d="m.5 2.5h12c1.1045695 0 2 .8954305 2 2v6c0 1.1045695-.8954305 2-2 2h-10c-1.1045695 0-2-.8954305-2-2zm1-2h9c.5522847 0 1 .44771525 1 1v1h-11v-1c0-.55228475.44771525-1 1-1z"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle cx="11.5" cy="7.5" fill="currentColor" r="1" />
              </g>
            </svg>
          </div>
          <div className="ml-3">
            <div className="text-white text-xs">{t("wallet")}</div>
            <div className="text-sm font-semibold text-white">
              {numberFormat(userdata.credit)} USDT
            </div>
          </div>
        </div>
        <div className="flex space-x-4">
          <Link
            to="/login/deposit"
            className="px-4 py-2 text-white bg-blue-600 rounded-full hover:bg-blue-700"
          >
            {t("deposit")}
          </Link>
          <Link
            to="/login/withdraw"
            className="px-4 py-2 text-white bg-blue-600 rounded-full hover:bg-blue-700"
          >
            {t("withdraw")}
          </Link>
        </div>
      </div> */}

      <div className="text-shadow-2 w-full md:w-1/2 lg:w-3/5 h-64 m-auto bg-zinc-800 rounded-xl relative text-white shadow-2xl transition-transform transform ">
        <LazyLoadImage
          className="relative object-cover w-full h-full rounded-xl"
          src={CreditcardBG}
        />

        <div className="w-full px-5 lg:px-8 absolute top-8">
          <div className="flex justify-between">
            <div className="">
              <p className="font-light">{t("name")}</p>
              <p className="font-medium tracking-widest">
                {userdata.firstname} {userdata.lastname}
              </p>
            </div>
            {/* <LazyLoadImage className="w-14 h-14" src={MastercardIMG} /> */}
          </div>
          <div className="pt-2 flex justify-between">
            <div>
              <p className="font-light">{t("phone")}</p>
              <p className="font-medium tracking-more-wider">
                {userdata.phone}
              </p>
            </div>
            <div >
              <p className="font-light text-right">{t("wallet")}</p>
              <p className="font-medium tracking-more-wider ">
                {numberFormat(userdata.credit)} USDT
              </p>
            </div>
          </div>
          <div className="pt-2 pr-6">
            <div className="flex justify-between">
              <div className="">
                <p className="font-light text-xs lg:text-base">UID</p>
                <p className="font-medium tracking-wider text-sm">
                  {userdata.uid}
                </p>
              </div>
              {/* <div className="">
                <p className="font-light text-xs ">{t("expiry")}</p>
                <p className="font-medium tracking-wider text-sm">07/29</p>
              </div> */}

              {/* <div className="">
                <p className="font-light text-xs">CVV</p>
                <p className="font-bold tracking-more-wider text-sm">{Number(userId)+111}</p>
              </div> */}
            </div>
          </div>
          <div className="grid grid-cols-3 gap-x-1 gap-y-2 lg:gap-7 mt-2  justify-center mx-auto">
        <Link
          to="/login/deposit"
          className="px-4 py-2 lg:py-2  text-center text-white  rounded-md lg:rounded-lg btn-home2 flex justify-center items-center text-xs lg:text-base drop-shadow-md"
        >
          {t("deposit")}
        </Link>
        <Link
          to="/login/withdraw"
          className="px-4 py-2 lg:py-2  text-center text-white  rounded-md lg:rounded-lg btn-home2 flex justify-center items-center text-xs lg:text-base drop-shadow-md"
        >
          {t("withdraw")}
        </Link>
        <Link
          to="/login/trade"
          className="px-4 py-2 lg:py-2  text-center text-white  rounded-md lg:rounded-lg btn-home2 flex justify-center items-center text-xs lg:text-base drop-shadow"
        >
          {t("trading_list")}
        </Link>
      </div>
        </div>

      </div>

      <div>
        <Link
          to="/login/verify"
          className="flex justify-center mx-auto w-full my-2 h-50 btn-home text-white duration-200  rounded-md shadow-lg shadow-gray-500/50"
        >
          <div className="w-full p-5">
            <div className="grid grid-cols-1 lg:grid-cols-2">
              <div>
                <p className="text-sm md:text-lg text-center lg:text-left">
                  {t("verify_identity")}
                </p>
              </div>
              <div>
                <div className="flex justify-center lg:justify-end text-sm align-middle items-center mt-2 lg:mt-0 ">
                  <p className="mr-3">Status :</p>
                  {userdata.verify_status === 0 ? (
                    <>
                      <DoNotDisturbOnIcon className="text-red-600 mr-2" />
                      <p>{t("not_verify")}</p>
                    </>
                  ) : userdata.verify_status === 1 ? (
                    <>
                      <CheckCircleIcon className="text-green-600 mr-2" />
                      <p>{t("verify_successfully")}</p>
                    </>
                  ) : userdata.verify_status === 2 ? (
                    <>
                      <CancelIcon className="text-red-600 mr-2" />
                      <p>{t("fail_verify")}</p>
                    </>
                  ) : (
                    <>
                      <ChangeCircleIcon className="text-orange-400 mr-2" />
                      <p>{t("waiting_verify")}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Link>
        <Link
          to="/login/edit_profile"
          className="flex justify-center mx-auto w-full my-2 h-50 btn-home text-white  rounded-md shadow-lg shadow-gray-500/50"
        >
          <div className="w-full p-5">
            <div className="grid grid-cols-2">
              <div>
                <p className="text-sm md:text-lg">{t("edit_profile")}</p>
              </div>
              <div>
                <div className="flex justify-end ">
                  {t("view")} <ExpandMoreIcon className="-rotate-90" />
                </div>
              </div>
            </div>
          </div>
        </Link>
        <Link
          to="/login/transaction_history"
          className="flex justify-center mx-auto w-full my-2 h-50 btn-home text-white  rounded-md shadow-lg shadow-gray-500/50"
        >
          <div className="w-full p-5">
            <div className="grid grid-cols-2">
              <div>
                <p className="text-sm md:text-lg">{t("transaction_history")}</p>
              </div>
              <div>
                <div className="flex justify-end ">
                  {t("view")} <ExpandMoreIcon className="-rotate-90" />
                </div>
              </div>
            </div>
          </div>
        </Link>
        <Link
          to="/login/trade_history"
          className="flex justify-center mx-auto w-full my-2 h-50 btn-home text-white  rounded-md shadow-lg shadow-gray-500/50"
        >
          <div className="w-full p-5">
            <div className="grid grid-cols-2">
              <div>
                <p className="text-sm md:text-lg">{t("trade_history")}</p>
              </div>
              <div>
                <div className="flex justify-end ">
                  {t("view")} <ExpandMoreIcon className="-rotate-90" />
                </div>
              </div>
            </div>
          </div>
        </Link>
        <Link
          to="/login/trade"
          className="flex justify-center mx-auto w-full my-2 h-50 btn-home text-white  rounded-md shadow-lg shadow-gray-500/50"
        >
          <div className="w-full p-5">
            <div className="grid grid-cols-2">
              <div>
                <p className="text-sm md:text-lg">{t("trading_list")}</p>
              </div>
              <div>
                <div className="flex justify-end ">
                  {t("view")} <ExpandMoreIcon className="-rotate-90" />
                </div>
              </div>
            </div>
          </div>
        </Link>
        <div className="h-0.5 bg-slate-600 my-1"></div>
        <Link
          to="/login/aboutus"
          className="flex justify-center mx-auto w-full my-2 h-50 btn-home text-white  rounded-md shadow-lg shadow-gray-500/50"
        >
          <div className="w-full p-5">
            <div className="grid grid-cols-2">
              <div>
                <p className="text-sm md:text-lg">{t("aboutus")}</p>
              </div>
              <div>
                <div className="flex justify-end ">
                  {t("view")} <ExpandMoreIcon className="-rotate-90" />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>

      <div>
        <div className="grid grid-cols-2 gap-3 w-full mt-8 lg:mt-20 p-5  mx-auto  text-center">
          <div className="">
            <div>
              <button
                // onClick={() => (window.location.href = `${lineURL}`)}
                onClick={() => window.$chatwoot.toggle("open")}
                className="w-full text-xs lg:text-sm md:w-1/2 text-sky-600 outline outline-offset-2 outline-2 outline-sky-600 hover:bg-sky-500 hover:text-white rounded-md px-2 md:px-5 py-2"
              >
                <AdminPanelSettingsIcon fontSize="large" />
                {t("contact_admin")}
              </button>
            </div>
          </div>
          <div className="">
            <div>
              <button
                onClick={logout}
                type="button"
                className="w-full text-xs lg:text-sm md:w-1/2 text-red-600 outline outline-offset-2 outline-2 outline-red-600 hover:bg-red-500 hover:text-white rounded-md px-2 md:px-5 py-2"
              >
                <LogoutIcon fontSize="large" />
                {t("logout")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
