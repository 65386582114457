import React, { useState, useEffect, useRef, createContext } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import Lottie from "lottie-react";

import { ConfigProvider, theme } from "antd";
import HomeComponent from "./MainUser/Home";
import loading_anime from "../../Json/loading_animetion3.json";
import Mylogo from "../../image/Bitcoin-SV-logo-white.png";
import Countdown_UI from "./Countdown_UI";

import dayjs from "dayjs";

import Home from "@mui/icons-material/Home";

import AnalyticsIcon from "@mui/icons-material/Analytics";
import RestoreIcon from "@mui/icons-material/Restore";
import InfoIcon from "@mui/icons-material/Info";

import numberFormat from "../../Function/NumberFormat2float";

import social1 from "../../icon/logo-facebook2.webp";
import social2 from "../../icon/line_logo_icon_147270.webp";
import social3 from "../../icon/website-icon-11.png";
import social4 from "../../icon/gmail_ic.png";
import forex_date_icon from "../../icon/forexdate.png";

import API_URL from "../../config/api";
import Account from "./Account/Account";
import config from "../../config/configapi";
import Trade from "./Trade/Trade";
import Deposit from "./MainUser/Deposit";
import History_main from "./Trade_history/History_main";
import Verify from "./Account/Verify";
import Edit_profile from "./Account/Edit_profile";
import Transaction from "./Transaction/Transaction";
import Withdraw from "./Account/Withdraw";
import { WebSocketUserProvider } from "./WebSocketUser";
import LanguageSelector from "../language-selector";
import { useTranslation } from "react-i18next";
import ChatwootComponent from "./ChatwootWidget";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CountdownTime from "./CountdownTime";
import Logoweb from "../../Function/Logoweb";
import Nameweb from "../../Function/Nameweb";
import Aboutus from "./Account/Aboutus";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export const DashboardContext = createContext();
function Dashboard() {
  const { t } = useTranslation();

  const { defaultAlgorithm, darkAlgorithm } = theme;
  const [loading, setLoading] = useState(false);
  const [userdata, setUserdata] = useState({});
  const URL_HOST = `${config.API_SERVER}`;

  const [facebookURL, setFacebookURL] = useState("");
  const [lineURL, setLineURL] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [gmailURL, setGmailURL] = useState("");

  const [payrate, setPayrate] = useState([]);
  const minTime = [3, 5, 15, 30];

  const [idbank, setIdbank] = useState("");
  const [codebank, setCodebank] = useState("");
  const [namebank, setNamebank] = useState("");
  const [imgbank, setImgbank] = useState(null);
  const [bankdetail, setBankdetail] = useState("");
  const [value, setValue] = useState(0);

  const [tradinglist, setTradinglist] = useState([]);
  const [tradinglistloading, setTradinglistLoading] = useState(true);

  const [userId] = useState(
    JSON.parse(localStorage.getItem("awesomeLeadsTokenId"))
  );
  let pathname = useLocation();

  const [checkForexOpen, setCheckForexOpen] = useCookies(["checkForexOpen"]);
  const [isCheckForexOpen, setIsCheckForexOpen] = useState(
    checkForexOpen.checkForexOpen
      ? checkForexOpen.checkForexOpen.toString()
      : "1"
  );
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const now = dayjs().tz("Asia/Bangkok");
  const day = now.day();
  const hour = now.hour();
  const minute = now.minute();

  useEffect(() => {
    get_user();
    getWebUrl();
    getPayrate();
    get_OneUserTrading();
    CheckForexDate();
    window.scrollTo(0, 0);
  }, [pathname]);

  const isForexOpen =
    (day === 1 && (hour > 4 || (hour === 4 && minute >= 0))) ||
    (day >= 2 && day <= 5) ||
    (day === 6 && (hour < 4 || (hour === 4 && minute === 0)));

  const CheckForexDate = async () => {
    if (isForexOpen) {
      setCheckForexOpen("checkForexOpen", "1", {
        path: `/`,
      });
      setIsCheckForexOpen("1");
      return;
    } else if (isCheckForexOpen === "1") {
      await Swal.fire({
        background: "#273c49",
        title: `<img src=${forex_date_icon} class="h-16 lg:h-28 mx-auto mt-5"/>`,
        html: `
                <p class="mb-2 text-white text-sm lg:text-base mt-2">${t(
                  "forex_open_date"
                )} </p>
              `,
        focusConfirm: false,
        showDenyButton: true,
        showConfirmButton: false,
        allowOutsideClick: false,
        denyButtonText: `
               <p class="m-auto text-center px-3">${t("close")}</p>
              `,

        customClass: {
          container: "p-0",
          popup: "p-1",
          title: "p-1",
          htmlContainer: "p-2 m-0",
          confirmButton: "w-1/4",
          footer: "p-1",
        },
      });
      setCheckForexOpen("checkForexOpen", "2", {
        path: `/`,
      });
      setIsCheckForexOpen("2");
    } else {
      return;
    }
  };

  const getPayrate = async () => {
    setPayrate([]);
    await API_URL.get(`api/percentrate/allpercentrate`)
      .then((res) => {
        const allpayrate = res.data;
        const newStateArray = [];
        if (allpayrate.length !== 0) {
          allpayrate.map((payrate, index) => {
            newStateArray.push({
              id: payrate.id,
              percent: payrate.percent,
              countTime: minTime[index],
            });
          });
        }
        setPayrate(newStateArray);

        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getWebUrl = async () => {
    await API_URL.get(`api/weburl/getallweburl`)
      .then((res) => {
        const allweburl = res.data;
        if (allweburl.length !== 0) {
          const resFacebook = allweburl.find(({ name }) => name === "facebook");
          setFacebookURL(resFacebook.nameurl);
          const resLine = allweburl.find(({ name }) => name === "line");
          setLineURL(resLine.nameurl);
          const resWebsite = allweburl.find(({ name }) => name === "website");
          setWebsiteURL(resWebsite.nameurl);
          const resGmail = allweburl.find(({ name }) => name === "gmail");
          setGmailURL(resGmail.nameurl);
          const residbank = allweburl.find(({ name }) => name === "idbank");
          setIdbank(residbank.nameurl);
          const rescodebank = allweburl.find(({ name }) => name === "codebank");
          setCodebank(rescodebank.nameurl);
          const resnamebank = allweburl.find(({ name }) => name === "namebank");
          setNamebank(resnamebank.nameurl);
          const resimgbank = allweburl.find(({ name }) => name === "imgbank");
          setImgbank(URL_HOST + resimgbank.nameurl);
          const resbankdetail = allweburl.find(
            ({ name }) => name === "bankdetail"
          );
          setBankdetail(resbankdetail.nameurl);
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const get_user = async () => {
    // setLoading(true);
    await API_URL.get(`api/people/oneUserdata/${userId}`)
      .then((res) => {
        const getoneuser = res.data.user;

        setUserdata(getoneuser);
      })
      .catch((err) => {
        logout();
      });
    // setLoading(false);
  };

  const logout = () => {
    localStorage.removeItem("awesomeLeadsTokenId");
    window.location.href = `/login`;
  };

  const get_OneUserTrading = async () => {
    setTradinglist([]);
    setTradinglistLoading(true);
    await API_URL.get(`api/tradelist/getoneusertrading/${userId}`)
      .then((res) => {
        const getalluser = res.data;

        if (getalluser.length !== 0) {
          getalluser.sort((a, b) => (b.createdAt > a.createdAt ? 1 : -1));
          setTradinglist(getalluser);
        }
      })

      .catch((err) => {
        console.log(err);
      });
    setTradinglistLoading(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <WebSocketUserProvider>
      <ConfigProvider
        theme={{
          algorithm: darkAlgorithm,
        }}
      >
        <DashboardContext.Provider
          value={{
            payrate,
            userId,
            tradinglist,
            tradinglistloading,
            get_OneUserTrading,
            get_user,
            userdata,
          }}
        >
          {tradinglist.length !== 0 &&
            tradinglist.map((trading, index) => (
              <div key={trading.id}>
                <CountdownTime
                  date={
                    Date.now() +
                    Number(dayjs(trading.closing_time).diff(dayjs()))
                  }
                  id={trading.id}
                />
              </div>
            ))}

          <div className="bg-zinc-900">
            <div className="flex">
              <nav className="flex justify-between w-full h-14 md:h-14 z-50 bg-slate-800 lg:fixed top-0 shadow-lg overflow-hidden ">
                <Link to="/login/" className="flex items-center">
                  <div className="flex">
                    <div className="  text-center  h-8 lg:h-11 mx-2 object-contain ">
                      <Logoweb />
                    </div>
                    <div className="text-white my-auto">
                      <Nameweb />
                    </div>
                  </div>
                </Link>
                <div className=" flex">
                  <div className="hidden lg:grid grid-cols-4  self-center mr-5 gap-x-1 w-96">
                    <Link
                      to="/login/"
                      className={
                        (pathname.pathname === "/login/"
                          ? "text-white bg-slate-700"
                          : "text-stone-400 ") +
                        " m-auto hover:bg-slate-600 rounded-md w-full"
                      }
                    >
                      <div className="block mx-auto text-center ">
                        <Home />
                        <p className="text-sm">{t("home")}</p>
                      </div>
                    </Link>
                    <Link
                      to="/login/trade"
                      className={
                        (pathname.pathname === "/login/trade"
                          ? "text-white bg-slate-700"
                          : "text-stone-400 ") +
                        " m-auto hover:bg-slate-600 rounded-md w-full"
                      }
                    >
                      <div className="block mx-auto text-center ">
                        <AnalyticsIcon />
                        <p className="text-sm">{t("trade")}</p>
                      </div>
                    </Link>
                    <Link
                      to="/login/trade_history"
                      className={
                        (pathname.pathname === "/login/trade_history"
                          ? "text-white bg-slate-700"
                          : "text-stone-400 ") +
                        " m-auto hover:bg-slate-600 rounded-md w-full"
                      }
                    >
                      <div className="block mx-auto text-center ">
                        <RestoreIcon />
                        <p className="text-sm">{t("trade_history")}</p>
                      </div>
                    </Link>
                    <Link
                      to="/login/account"
                      className={
                        (pathname.pathname === "/login/account"
                          ? "text-white bg-slate-700"
                          : "text-stone-400 ") +
                        " m-auto hover:bg-slate-600 rounded-md w-full"
                      }
                    >
                      <div className="block mx-auto text-center ">
                        <InfoIcon />
                        <p className="text-sm">{t("info")}</p>
                      </div>
                    </Link>
                  </div>
                  <div className="bg-gray-900  my-1 mr-3 rounded-xl hidden lg:flex justify-center align-middle">
                    <p className="text-white px-5 my-auto">
                      {t("wallet")} : &nbsp;&nbsp;
                      {numberFormat(userdata.credit)} USDT &nbsp;
                    </p>
                  </div>
                  <div className="my-auto mr-1">
                    <LanguageSelector />
                  </div>
                </div>
              </nav>
            </div>
            <div className="block lg:hidden">
              <nav
                style={{ boxShadow: "0px -1px 3px #a4bcff" }}
                className=" w-full h-14 z-50 bg-slate-800 fixed bottom-0 shadow-lg overflow-hidden flex align-middle rounded-t-3xl py-5"
              >
                <div className="grid grid-cols-4  self-center w-full">
                  <Link
                    to="/login/"
                    className={
                      (pathname.pathname === "/login/"
                        ? "text-white bg-slate-700"
                        : "text-stone-400 ") +
                      " m-auto hover:bg-slate-600 rounded-lg w-11/12"
                    }
                  >
                    <div className="block mx-auto text-center ">
                      <Home />
                      <p className="text-xs whitespace-nowrap">{t("home")}</p>
                    </div>
                  </Link>
                  <Link
                    to="/login/trade"
                    className={
                      (pathname.pathname === "/login/trade"
                        ? "text-white bg-slate-700"
                        : "text-stone-400 ") +
                      " m-auto hover:bg-slate-600 rounded-lg w-11/12"
                    }
                  >
                    <div className="block mx-auto text-center ">
                      <AnalyticsIcon />
                      <p className="text-xs whitespace-nowrap">{t("trade")}</p>
                    </div>
                  </Link>
                  <Link
                    to="/login/trade_history"
                    className={
                      (pathname.pathname === "/login/trade_history"
                        ? "text-white bg-slate-700"
                        : "text-stone-400 ") +
                      " m-auto hover:bg-slate-600 rounded-lg w-11/12"
                    }
                  >
                    <div className="block mx-auto text-center ">
                      <RestoreIcon />
                      <p className="text-xs whitespace-nowrap">
                        {t("trade_history")}
                      </p>
                    </div>
                  </Link>
                  <Link
                    to="/login/account"
                    className={
                      (pathname.pathname === "/login/account"
                        ? "text-white bg-slate-700"
                        : "text-stone-400 ") +
                      " m-auto hover:bg-slate-600 rounded-lg w-11/12"
                    }
                  >
                    <div className="block mx-auto text-center ">
                      <InfoIcon />
                      <p className="text-xs whitespace-nowrap">{t("info")}</p>
                    </div>
                  </Link>
                </div>
              </nav>
            </div>

            <div
              className="h-auto pt-1 lg:mt-16 "
              style={{
                // backgroundImage: `/img/bg10.jpg`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
                minHeight: "100vh",
              }}
            >
              {loading ? (
                <div className=" w-1/6 mx-auto flex justify-center items-center min-h-screen">
                  <Lottie animationData={loading_anime} loop={true} />
                </div>
              ) : (
                <Routes>
                  <Route
                    path="/"
                    element={<HomeComponent userdata={userdata} />}
                  />
                  <Route
                    path="/deposit"
                    element={
                      <Deposit
                        userdata={userdata}
                        bankdetail={bankdetail}
                        imgbank={imgbank}
                      />
                    }
                  />
                  <Route
                    path="/withdraw"
                    element={<Withdraw userdata={userdata} />}
                  />

                  <Route path="/trade" element={<Trade />} />
                  <Route
                    path="/trade_history"
                    element={<History_main userdata={userdata} />}
                  />
                  <Route
                    path="/transaction_history"
                    element={<Transaction />}
                  />
                  <Route
                    path="/account"
                    element={
                      <Account
                        userdata={userdata}
                        get_user={get_user}
                        lineURL={lineURL}
                      />
                    }
                  />
                  <Route
                    path="/verify"
                    element={<Verify userdata={userdata} />}
                  />
                  <Route
                    path="/edit_profile"
                    element={<Edit_profile userdata={userdata} />}
                  />
                  <Route path="/aboutus" element={<Aboutus />} />
                </Routes>
              )}
            </div>
          </div>
          <footer
            className="px-9 py-5 text-center bg-gray-800"
            style={{
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "scroll",
              backgroundSize: "cover",
            }}
          >
            <div>
              <div className="flex justify-center">
                <div className="  text-center  h-10 lg:h-16 mx-2 object-contain ">
                  <Logoweb />
                </div>
                <div className="text-white my-auto">
                  <Nameweb />
                </div>
              </div>
            </div>
            <div className="my-2">
              {tradinglist.length !== 0 &&
                tradinglist.map((trading, index) => (
                  <Countdown_UI
                    date={
                      Date.now() +
                      Number(dayjs(trading.closing_time).diff(dayjs()))
                    }
                    id={trading.id}
                    key={index}
                  />
                ))}

              <p className="text-white w-full lg:w-1/2 text-center mx-auto">
                {t("footertxt1")}
              </p>
            </div>
            <div>
              <p className="text-lg text-white font-semibold bg-slate-900 px-3 py-1 rounded-md w-3/6 lg:w-1/6 text-center mx-auto">
                {t("contact")}
              </p>
            </div>

            <div className="grid grid-cols-4 w-full lg:w-2/5 mx-auto mt-2 ">
              <div>
                <a
                  className="text-center flex-col text-base font-semibold"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={facebookURL}
                >
                  <LazyLoadImage
                    loading="lazy"
                    src={social1}
                    className="w-9 md:w-10  mx-5 my-2 inline"
                  />
                  <p className="text-white text-xs lg:text-base font-semibold">
                    FACEBOOK
                  </p>
                </a>
              </div>
              <div>
                <a
                  className="text-center flex-col text-base"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={lineURL}
                >
                  <LazyLoadImage
                    loading="lazy"
                    src={social2}
                    className="w-9 md:w-10  mx-5 my-2 inline"
                  />
                  <p className="text-white text-xs lg:text-base font-semibold">
                    LINE
                  </p>
                </a>
              </div>
              <div>
                <a
                  className="text-center flex-col text-base"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={gmailURL}
                >
                  <LazyLoadImage
                    loading="lazy"
                    src={social4}
                    className="w-9 md:w-10  mx-5 my-2 inline"
                  />
                  <p className="text-white text-xs lg:text-base font-semibold">
                    GMAIL
                  </p>
                </a>
              </div>
              <div>
                <a
                  className="text-center flex-col text-base"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={websiteURL}
                >
                  <LazyLoadImage
                    loading="lazy"
                    src={social3}
                    className="w-9 md:w-10  mx-5 my-2 inline"
                  />
                  <p className="text-white text-xs lg:text-base font-semibold">
                    WEBSITE
                  </p>
                </a>
              </div>
            </div>
            <div className="bg-gray-700 h-0.5 my-5 w-5/6 mx-auto"></div>
            <div className="text-white w-full lg:w-2/3 text-center mx-auto">
              <p className="my-2 underline">{t("warning")}</p>
              <p>● {t("footertxt2")}</p>
            </div>
            <div className="mt-3 text-white text-xs lg:text-base pb-16 lg:pb-1">
              <p>© 2018. ALL RIGHTS RESERVED.</p>
            </div>
            <ChatwootComponent />
          </footer>
        </DashboardContext.Provider>
      </ConfigProvider>
    </WebSocketUserProvider>
  );
}

export default Dashboard;
