import React, { useContext, useState, useEffect } from "react";
import { DashboardContext } from "../Dashboard";
import ErrorMessage from "../../../Toast/ErrorMessage";

import {
  TextField,
  InputAdornment,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import PasswordIcon from "@mui/icons-material/Password";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import API_URL from "../../../config/api";
import OpenNotification from "../../../Toast/OpenNotification";

import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

function Edit_profile({userdata}) {
  const { t } = useTranslation();
  const {  get_user } = useContext(DashboardContext);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [addressnow, setAddressnow] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [msgErr1, setMsgErr1] = useState("");
  const [msgErr2, setMsgErr2] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const [showPassword, setShowpassword] = useState(false);

  const [idbank, setIdbank] = useState(userdata ? userdata.idbank : 0);
  const [codebank, setCodebank] = useState(userdata ? userdata.codebank : "");

  useEffect(() => {
    Setinput();
  }, []);

  const Setinput = () => {
    setFirstname(userdata.firstname);
    setLastname(userdata.lastname);
    // setUid(userdata.uid);
    setPhone(userdata.phone);
    setEmail(userdata.email);
    setAddressnow(userdata.addressnow);
  };
  const UpdateUserPassword = async (e) => {
    e.preventDefault();
    if (password !== userdata.password) {
      setMsgErr2(`${t("passwordisincorrect")}`);
      return;
    }
    if (newPassword !== newPassword2) {
      setMsgErr2(`${t("newpasswordnotmatch")}`);
      return;
    }
    if (newPassword.length < 8) {
      setMsgErr2(`${t("password8char")}`);
      return;
    }
    setMsgErr2("");

    const datainsert = {
      password: password,
      newpassword: newPassword,
      newpassword2: newPassword2,
    };
    await API_URL.post(
      `api/people/userupdatepassword/${userdata.id}`,
      datainsert
    )
      .then((res) => {
        Swal.fire({
          title: `${t("changepasswordsuccess")}`,
          text: `${t("pleaseloginagain")}`,
          icon: "warning",
          // showCancelButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: "#d33",
          confirmButtonText: `${t("logout")}`,
          allowOutsideClick:false
        }).then((result) => {
          if (result.isConfirmed) {
            // OpenNotification({ message: `${t("changepasswordsuccess")}`, type: 1 });
            localStorage.removeItem("awesomeLeadsTokenId");
            localStorage.removeItem("awesomeLeadsTokenUserdata");
            if (window.$chatwoot) {
              window.$chatwoot.reset();
            }
            window.location.href = `/login`;
            return res.data;
          }

        });

      })
      .catch((err) => {
        OpenNotification({
          message: `${t("data_transmission_error")}`,
          type: 4,
        });
      });
  };
  const UpdateUser = async () => {
    if (firstname === "" || lastname === "" || phone.length !== 10) {
      setMsgErr1(`${t("fillintheinfocom")}`);
      return;
    }
    if (idbank === 0 || codebank === "") {
      setMsgErr1(`${t("fillinyourbank")}`);
      return;
    }
    setMsgErr1("");
    const datainsert = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      addressnow: addressnow,
      phone: phone,
      phoneold: userdata.phone,
      idbank: idbank,
      codebank: codebank,
    };

    await API_URL.post(`api/people/userupdateinfo/${userdata.id}`, datainsert)
      .then((res) => {
        OpenNotification({ message: `${t("data_edit_sucess")}`, type: 1 });
        get_user();
        return res.data;
      })
      .catch((err) => {
        err.response.data.status === 400
          ? OpenNotification({
              message: `${t("thisphoneinuse")}`,
              type: 3,
            })
          : OpenNotification({
              message: `${t("data_transmission_error")}`,
              type: 4,
            });
      });
  };

  return (
    <div className="w-full lg:w-3/6 bg-zinc-800 rounded-xl shadow-md mx-auto p-1 lg:px-5 lg:py-2 text-white">
      <div
        className="w-full  mx-auto mt-0 lg:mt-5 bg-white px-2 py-3 lg:py-5 rounded-md"
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 w-full md:w-full mx-auto">
          <TextField
            name="firstname"
            autoComplete="off"
            size="small"
            id="outlined-textarea"
            label={t("firstname")}
            placeholder={t("firstname")}
            onKeyPress={(e) => /[0-9]/.test(e.key) && e.preventDefault()}
            className="w-full"
            fullWidth
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />

          <TextField
            name="lastname"
            size="small"
            id="outlined-textarea"
            label={t("lastname")}
            onKeyPress={(e) => /[0-9]/.test(e.key) && e.preventDefault()}
            autoComplete="off"
            placeholder={t("lastname")}
            className="w-full"
            fullWidth
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
        </div>

        <div className="flex w-full md:w-6/6 mx-auto my-5">
          <TextField
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            size="small"
            type={"tel"}
            id="outlined-textarea"
            label={t("phone")}
            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            autoComplete="off"
            placeholder={t("phone")}
            className="w-6/6"
            inputProps={{ maxLength: 10 }}
            fullWidth
            InputProps={{
              startAdornment: (
                <PhoneAndroidIcon
                  position="start"
                  className="mr-5"
                ></PhoneAndroidIcon>
              ),
            }}
          />
        </div>

        <div className="flex w-full md:w-6/6 mx-auto">
          <TextField
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            size="small"
            id="outlined-textarea"
            label={t("email")}
            autoComplete="off"
            placeholder={t("email")}
            className="w-6/6"
            inputProps={{ maxLength: 50 }}
            InputProps={{
              startAdornment: (
                <MailOutlineIcon
                  position="start"
                  className="mr-5"
                ></MailOutlineIcon>
              ),
            }}
            fullWidth
          />
        </div>

        <div className="flex w-full mx-auto mt-5">
          <TextField
            name="addressnow"
            value={addressnow}
            onChange={(e) => setAddressnow(e.target.value)}
            id="outlined-textarea"
            size="small"
            label={t("address")}
            autoComplete="off"
            placeholder={t("address")}
            // multiline
            // maxRows={2}
            className="w-full"
            fullWidth
          />
        </div>
        <div className="w-full  mx-auto  bg-white px-2  border-t-2 border-gray-500 mt-2">
          <div className=" w-full md:w-full mx-auto my-5">
            <InputLabel className="my-auto text-white">
              {t("select_bank")}
            </InputLabel>
            <Select
              name="idbank"
              size="small"
              label={t("select_bank")}
              defaultValue={idbank}
              value={idbank}
              onChange={(e) => setIdbank(e.target.value)}
              fullWidth
            >
              <MenuItem value={0}>None</MenuItem>
              <MenuItem value={1}>
                <i className="bank bank-kbank xxxl mr-4"></i>ธนาคารกสิกรไทย
                (KBank)
              </MenuItem>
              <MenuItem value={2}>
                <i className="bank bank-bbl xxxl mr-4"></i>ธนาคารกรุงเทพ (BBL)
              </MenuItem>
              <MenuItem value={3}>
                <i className="bank bank-scb xxxl mr-4"></i>ธนาคารไทยพาณิชย์
                (SCBB)
              </MenuItem>
              <MenuItem value={4}>
                <i className="bank bank-ktb xxxl mr-4"></i>ธนาคารกรุงไทย (KTB)
              </MenuItem>
              <MenuItem value={5}>
                <i className="bank bank-bay xxxl mr-4"></i>ธนาคารกรุงศรี (BAY)
              </MenuItem>
              <MenuItem value={6}>
                <i className="bank bank-tmb xxxl mr-4"></i>ธนาคารทหารไทยธนชาต
                (TTB)
              </MenuItem>
              <MenuItem value={7}>
                <i className="bank bank-lhb xxxl mr-4"></i>
                ธนาคารแลนด์แอนด์เฮ้าส์ (LH Bank)
              </MenuItem>
              <MenuItem value={8}>
                <i className="bank bank-gsb xxxl mr-4"></i>ธนาคารออมสิน (GSB)
              </MenuItem>
              <MenuItem value={9}>
                <i className="bank bank-kk xxxl mr-4"></i>
                ธนาคารเกียรตินาคินภัทร (KKP)
              </MenuItem>
              <MenuItem value={10}>
                <i className="bank bank-citi xxxl mr-4"></i>ธนาคารซิตี้แบงก์
                (CITI)
              </MenuItem>
              <MenuItem value={11}>
                <i className="bank bank-baac xxxl mr-4"></i>
                ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร (BAAC)
              </MenuItem>
              <MenuItem value={12}>
                <i className="bank bank-uob xxxl mr-4"></i>ธนาคารยูโอบี (UOBT)
              </MenuItem>
            </Select>
          </div>
          <div className="flex w-full md:w-6/6 mx-auto my-5">
            <TextField
              name="codebank"
              value={codebank}
              onChange={(e) => setCodebank(e.target.value)}
              id="outlined-textarea"
              
              size="small"
              label={t("account_no")}
              autoComplete="off"
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              inputProps={{ maxLength: 13 }}
              className="w-full  "
              fullWidth
            />
          </div>
        </div>
        {msgErr1 ? <ErrorMessage message={msgErr1} className="my-1" /> : <></>}
        <div className="text-center my-1">
          <button
            onClick={() => UpdateUser()}
            className="text-center  text-white font-semibold mx-auto w-full lg:w-2/6  py-2 rounded-md bg-blue-700 hover:bg-blue-900 shadow-md"
          >
            {t("changeinfo")}
          </button>
        </div>

        <div className="bg-slate-700 h-1 my-5"></div>
        <div>
          <p className="text-center text-lg text-black">
            {t("changepassword")}
          </p>
          <div className="flex w-full mx-auto mt-5">
            <TextField
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="outlined-textarea"
              size="small"
              label={t("password")}
              autoComplete="off"
              placeholder={t("password")}
              className="w-6/6 "
              type={showPassword ? "text" : "password"}
              fullWidth
              InputProps={{
                startAdornment: (
                  <PasswordIcon
                    position="start"
                    className="mr-5"
                  ></PasswordIcon>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowpassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="flex w-full mx-auto mt-5">
            <TextField
              name="New-Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              id="outlined-textarea"
              size="small"
              label={t("newpassword")}
              type={showPassword ? "text" : "password"}
              autoComplete="off"
              placeholder={t("newpassword")}
              className="w-6/6 "
              fullWidth
              InputProps={{
                startAdornment: (
                  <PasswordIcon
                    position="start"
                    className="mr-5"
                  ></PasswordIcon>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowpassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="flex w-full mx-auto mt-5">
            <TextField
              name="Confirm New Password"
              value={newPassword2}
              onChange={(e) => setNewPassword2(e.target.value)}
              id="outlined-textarea"
              size="small"
              type={showPassword ? "text" : "password"}
              label={t("confirmnewpassword")}
              autoComplete="off"
              placeholder={t("confirmnewpassword")}
              className="w-6/6 "
              fullWidth
              InputProps={{
                startAdornment: (
                  <PasswordIcon
                    position="start"
                    className="mr-5"
                  ></PasswordIcon>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowpassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {msgErr2 ? (
            <ErrorMessage message={msgErr2} className="my-1" />
          ) : (
            <></>
          )}
          <div className="text-center my-1">
            <button
              onClick={UpdateUserPassword}
              className="text-center  text-white font-semibold mx-auto w-full lg:w-2/6 py-2 rounded-md bg-orange-600 hover:bg-orange-800 shadow-md"
            >
              {t("changepassword")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit_profile;
