import React, { useState, useEffect } from "react";

import AddCardIcon from "@mui/icons-material/AddCard";
import PaymentsIcon from "@mui/icons-material/Payments";

import TransactinoList from "./TransactinoList";
import dayjs from "dayjs";

import API_URL from "../../../config/api";
import { useTranslation } from "react-i18next";




function Transaction() {
  const { t } = useTranslation();
  const [openTab, setOpenTab] = useState(1);

  const [userId] = useState(localStorage.getItem("awesomeLeadsTokenId"));
  const [allcredit, setAllcredit] = useState([]);

  useEffect(() => {
    if (openTab === 1) {
      get_AllDeposit();
    } else {
      get_AllWithdraw();
    }
  }, [openTab]);



  const get_AllDeposit = async () => {
    setAllcredit([]);

    await API_URL.get(`api/creditadmin/getAllDepositUserHistory/${userId}`)
      .then((res) => {
        const getalluser = res.data;
        if (getalluser.length !== 0) {
          getalluser.map((e) => {
            e.net= e.amount+e.preamount;
            e.status = 1;
            e.amounttxt = e.amount.toString();
            e.createdAt = dayjs(e.sliptime)
              .format("DD/MM/YYYY HH:mm")
              .toString();
          });
          setAllcredit(getalluser);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const get_AllWithdraw = async () => {
    setAllcredit([]);

    await API_URL.get(`api/deposit/getOneUserWithdraw/${userId}`)
      .then((res) => {
        const getalluser = res.data;
        if (getalluser.length !== 0) {
          getalluser.map((e) => {
            e.amounttxt = e.amount.toString();
            e.sliptimetxt = dayjs(e.sliptime)
              .format("DD/MM/YYYY HH:mm")
              .toString();
          });
          setAllcredit(getalluser);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const datatab = [
    {
      id: 1,
      tabname:`${t("deposit_history")}`,
    },
    {
      id: 2,
      tabname:`${t("withdrawal_history")}`,
    },
  ];

  return (
    <div className="pt-1 ">

      <div>
        <div className="w-full">
          <div className="w-full px-1 md:px-8">
            <ul
              className="flex mb-0 list-none  pt-3 pb-0 flex-row "
              role="tablist"
            >
              {datatab.map((data, index) => (
                <li
                  className="-mb-px mr-2 last:mr-0 flex-auto text-center "
                  key={index}
                >
                  <a
                    className={
                      "text1line text-xs md:text-md font-bold uppercase px-2 py-2 lg:py-4 shadow-lg rounded-t-lg  block leading-normal " +
                      (openTab === data.id
                        ? "text-white border-blue-700 border-2 bg-indigo-950"
                        : " text-gray-100   hover:text-blue-300  border-2 border-blue-900")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(data.id);
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    {data.id === 1 ? (
                      <AddCardIcon className="my-auto" />
                    ) : (
                      <PaymentsIcon />
                    )}{" "}
                    {data.tabname}
                  </a>
                </li>
              ))}
            </ul>
            <div className="h-1 bg-blue-900"></div>
            <div className="relative flex flex-col min-w-0 break-words  w-full mb-6  ">
              <div className="px-0.5 lg:px-1 py-1 flex-auto">
                <div className="tab-content tab-space">
                  {/* tab1 */}

                  <div
                    className={openTab === 1 ? "block" : "hidden"}
                    id="link1"
                  >
                    <TransactinoList allcredit={allcredit} />
                  </div>

                  {/* tab2 */}

                  <div
                    className={openTab === 2 ? "block" : "hidden"}
                    id="link2"
                  >
                    <TransactinoList allcredit={allcredit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transaction;
