import React, { useState, useEffect } from "react";
import config from "../../../config/configapi";
import IDcardModal from "./IDcardModal";
import API_URL from "../../../config/api";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { LazyLoadImage } from "react-lazy-load-image-component";
import imageEmpty from "../../../image/emptyimg.png";
import { Pagination } from "@mui/material";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import OpenNotification from "../../../Toast/OpenNotification";

function VerifyUser() {
  const URL_HOST = `${config.API_SERVER}`;
  const [allUser, setAllUser] = useState([]);
  const [allUserBackup, setAllUserBackup] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [isIDcardMoal, setIsIDcardMoal] = useState(false);
  const [urlimagefront, setUrlimagefront] = useState(null);
  const [urlimageback, setUrlimageback] = useState(null);
  const itemcount = 9;

  useEffect(() => {
    get_AllVerify();
    // const interval = setInterval(() => {
    //   get_AllVerify();
    // }, 10000);
    // return () => clearInterval(interval);
  }, []);

  const showIDCradModal = (urlfront, urlback) => {
    setIsIDcardMoal(!isIDcardMoal);
    setUrlimagefront(urlfront);
    setUrlimageback(urlback);
  };
  const showIDCradModalClose = () => {
    setIsIDcardMoal(!isIDcardMoal);
    setUrlimagefront(null);
    setUrlimageback(null);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    if (value > 1) {
      setAllUser(
        allUserBackup.slice((value - 1) * itemcount, itemcount * value)
      );
    } else {
      setAllUser(allUserBackup.slice(0, itemcount));
    }
  };

  const get_AllVerify = async () => {
    setAllUser([]);
    setAllUserBackup([]);
    await API_URL.get(`api/people/allpeopleverify`)
      .then((res) => {
        const getalluser = res.data;
        if (getalluser.length !== 0) {
          // for (let i = 0; i < 30; i++) {
          //   getalluser.push(getalluser[0]);
          // }
          setAllUser(getalluser.slice(0, itemcount));
          setAllUserBackup(getalluser);
          setPageCount(
            getalluser.length / itemcount < 1
              ? 1
              : Math.ceil(getalluser.length / itemcount)
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const UpdateVerify = async (data, status) => {
    if (status === 1) {
      await API_URL.post(`api/people/sendverifysucces`, {
        id: data.id,
      })
        .then((res) => {
          OpenNotification({ message: "เสร็จสิ้น", type: 1 });
          get_AllVerify();
          return res.data;
        })
        .catch((err) => {
          OpenNotification({ message: "เกิดข้อผิดพลาด", type: 4 });
        });
    } else {
      let frontT = "";
      let backT = "";
      if (data.imagefrontcard && data.imagebackcard) {
        const array = data.imagefrontcard.split("\\");
        const array2 = data.imagebackcard.split("\\");
        frontT = "./" + array.join("/");
        backT = "./" + array2.join("/");
      } else {
        frontT = null;
        backT = null;
      }

      await API_URL.post(`api/people/sendverifyfailed`, {
        id: data.id,
        imagefrontcard: frontT,
        imagebackcard: backT,
      })
        .then((res) => {
          OpenNotification({ message: "เสร็จสิ้น", type: 1 });
          get_AllVerify();
          return res.data;
        })
        .catch((err) => {
          OpenNotification({ message: "เกิดข้อผิดพลาด", type: 4 });
        });
    }
  };
  return (
    <div>
      {isIDcardMoal ? (
        <IDcardModal
          showIDCradModal={showIDCradModal}
          isIDcardMoal={isIDcardMoal}
          showIDCradModalClose={showIDCradModalClose}
          urlimagefront={urlimagefront}
          urlimageback={urlimageback}
        />
      ) : (
        <></>
      )}
      <button
        onClick={get_AllVerify}
        className="bg-black hover:bg-gray-800 text-white shadow-md font-bold py-2 px-4 rounded inline-flex items-center my-2"
      >
        <AutorenewIcon className="fill-current w-4 h-4 mr-2"></AutorenewIcon>
        <span>โหลดข้อมูลใหม่</span>
      </button>
      {allUser.length === 0 ? (
        <div className="mx-auto flex-col justify-center w-full text-center my-10">
          <FolderOffIcon fontSize="large" />
          <p className="text-2xl text-center font-bold">ไม่มีรายการ</p>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3">
            {allUser.map((res, index) => (
              <div key={index} className="w-full px-1 lg:px-2 py-2  ">
                <div className="bg-white w-full rounded-md shadow-lg lg:flex justify-center px-1 lg:px-2 py-2">
                  <div className="w-full lg:w-2/4 px-1 mx-auto">
                    <LazyLoadImage
                      onClick={() =>
                        showIDCradModal(
                          `${URL_HOST}${res.imagefrontcard}`,
                          `${URL_HOST}${res.imagebackcard}`
                        )
                      }
                      className="rounded-md cursor-pointer text-center mx-auto imgDepositpending h-52 object-contain"
                      src={
                        `${URL_HOST}${res.imagefrontcard}` !== null
                          ? `${URL_HOST}${res.imagefrontcard}`
                          : null && res.imagefrontcard !== null
                          ? `${URL_HOST}${res.imagefrontcard}`
                          : null
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror =
                          res.imagefrontcard !== null
                            ? `${URL_HOST}${res.imagefrontcard}`
                            : null;
                        currentTarget.src = imageEmpty;
                      }}
                      alt="ID Card"
                    />
                  </div>
                  <div className="flex-col w-full lg:w-2/4">
                    <div className="h-4/5 w-full items-center flex my-auto ">
                      <div className="w-full px-4 lg:px-0">
                        <div className="bg-green-100  py-1 lg:py-2 px-2 rounded-md w-full">
                          <div className="flex my-0.5   mx-auto justify-start items-center">
                            {" "}
                            <p className="wsn font-semibold text-xs">
                              ชื่อ : {res.firstname} {res.lastname}
                            </p>
                          </div>

                          <div className="flex my-0.5  mx-auto justify-start items-center">
                            {" "}
                            <p className="wsn font-semibold text-xs">
                              เบอร์ : {res.phone}
                            </p>
                          </div>
                        </div>
                        <div className="flex mt-3 mx-auto justify-start">
                          {" "}
                          <p className="wsn font-semibold text-sm my-auto">
                            Uid :&nbsp;
                          </p>
                          <p className="wsn text-base truncate">{res.uid}</p>
                        </div>
                        <div className="flex my-1  mx-auto justify-start">
                          {" "}
                          <p className="wsn font-semibold  text-sm my-auto">
                            ประเภทบัตร :&nbsp;
                            {res.cardtype === 0
                              ? "บัตรประชาชน"
                              : res.cardtype === 1
                              ? "Passport"
                              : res.cardtype === 2
                              ? "บัตร SSN"
                              : "บัตรใบขับขี่"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="h-1/5 flex items-center w-full">
                      <div className="grid grid-cols-2 w-full">
                        <button
                          onClick={() => UpdateVerify(res, 1)}
                          className="mx-1 py-2 bg-green-600 rounded-md hover:bg-green-700 shadow-md text-white font-medium"
                        >
                          อนุมัติ
                        </button>
                        <button
                          onClick={() => UpdateVerify(res, 2)}
                          className="mx-1 py-2 text-red-800 rounded-md bg-transparent hover:bg-red-800 hover:text-white border border-red-800 font-semibold"
                        >
                          ไม่อนุมัติ
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Pagination
            count={pageCount}
            page={page}
            onChange={handleChangePage}
            size="large"
            color="primary"
            className="flex justify-center mt-5"
          />
        </>
      )}
    </div>
  );
}

export default VerifyUser;
