import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import {
  OutlinedInput,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import API_URL from "../../../config/api";

import { getCurrentUser } from "../../../services/auth.service";
import OpenNotification from "../../../Toast/OpenNotification";
import ErrorMessage from "../../../Toast/ErrorMessage";

function CreditadminModal({ isModalVisible, showModal, get_AllCreditAdmin }) {
  const token = getCurrentUser();

  const [phone, setPhone] = useState("");
  const [credittype, setCredittype] = useState(1);
  const [amount, setAmount] = useState(0);
  const [note, setNote] = useState("");
  const [msgErr1, setMsgErr1] = useState("");

  useEffect(() => {}, []);
  const handleChange = (event) => {
    setCredittype(event.target.value);
  };

  const handleUpdateCredit = async () => {
    if (amount <= 0) {
      setMsgErr1("กรุณากรอกตัวเลขให้ถูกต้อง");
      return;
    }
    setMsgErr1("");

    await API_URL.post(`api/creditadmin/addCreditadmin`, {
      credittype: credittype,
      amount: amount,
      note: note,
      phone: phone,
      userId: token.id,
    })
      .then((res) => {
        OpenNotification({ message: "เสร็จสิ้น", type: 1 });
        get_AllCreditAdmin();
        showModal();
        return res.data;
      })
      .catch((err) => {
        err.response.data.status === 400
          ? OpenNotification({ message: "ไม่มี Username นี้", type: 3 })
          : OpenNotification({ message: "เกิดข้อผิดพลาด", type: 4 });
      });
  };

  return (
    <Modal
      style={{
        top: 10,
      }}
      title={"เพิ่ม / ลดเครดิต"}
      open={isModalVisible}
      onOk={showModal}
      onCancel={showModal}
      width={700}
      key={0}
      footer={[
        <div className=" text-center flex justify-center">
          <button
            onClick={handleUpdateCredit}
            className="text-center text-white font-semibold  px-10 py-2 rounded-md bg-blue-900 hover:bg-blue-700 shadow-md"
          >
            ยืนยัน
          </button>

          <div className="mx-5 md:mx-16 "></div>
          <button
            onClick={showModal}
            className="bg-transparent hover:bg-gray-100 text-black font-semibold  py-2 px-10 border border-gray-900 hover:border-transparent rounded"
          >
            ยกเลิก
          </button>
        </div>,
      ]}
    >
      <div className="w-full py-4 ">
        <div className="w-full ">
          <InputLabel id="demo-simple-select-label">Phone</InputLabel>
          <div className="flex relative">
            <OutlinedInput
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="block w-full  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md   "
              aria-describedby="outlined-weight-helper-text"
              autoComplete="off"
              size="small"
              fullWidth
      
            />
          </div>
        </div>
        <div className="w-full  flex justify-start ">
          <div className="w-2/6 ">
            <InputLabel>ประเภทเครดิต</InputLabel>
            <Select
              name="credittype"
              value={credittype}
              label="ประเภทเครดิต"
              onChange={handleChange}
              size="small"
              fullWidth
            >
              <MenuItem value={1}>
                <p className="text-green-600 font-semibold">+ เพิ่ม</p>
              </MenuItem>
              <MenuItem value={2}>
                <p className="text-red-600 font-semibold">- ลด</p>
              </MenuItem>
            </Select>
          </div>
          <div className="w-2"></div>
          <div className="w-4/6 ">
            <InputLabel id="demo-simple-select-label">เครดิต</InputLabel>
            <div className="flex relative">
              <OutlinedInput
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="block w-full  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md   "
                aria-describedby="outlined-weight-helper-text"
                onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
                autoComplete="off"
                size="small"
                fullWidth
                inputProps={{ maxLength: 10 }}
              />
              <div className="absolute right-2  bottom-0 top-0 flex items-center">
                <p className="my-auto text-xl font-medium ">USDT</p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex-col justify-start ">
          <InputLabel>หมายเหตุ</InputLabel>

          <OutlinedInput
            value={note}
            onChange={(e) => setNote(e.target.value)}
            className="block w-full  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md"
            autoComplete="off"
            size="small"
            maxRows={2}
          />
        </div>
      </div>
      {msgErr1 ? <ErrorMessage message={msgErr1} /> : <></>}
    </Modal>
  );
}

export default CreditadminModal;
