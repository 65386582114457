import React, { useEffect, useRef } from "react";

const BitcoinChartTradingView = () => {
  const container = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbol: "BITSTAMP:BTCUSD",
      width: "100%",
      height: "100%",
      locale: "en",
      dateRange: "1D",
      colorTheme: "dark",
      trendLineColor: "rgba(41, 98, 255, 1)",
      underLineColor: "rgba(41, 98, 255, 0.3)",
      underLineBottomColor: "rgba(41, 98, 255, 0)",
      isTransparent: true,
      autosize: true,
      largeChartUrl: "",
    //   hideSymbolLogo: true,
    //   hideDateRanges: true,
    //   hideMarketStatus: true,
    //   hideVolume: true,
    //   scalePosition: "no",
    //   scaleMode: "Normal",
    //   fontFamily: "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
    //   fontSize: "10",
    //   noTimeScale: false,
    //   valuesTracking: "1",
    //   changeMode: "price-and-percent",
    //   chartOnly: false
    });

    if (container.current) {
      container.current.appendChild(script);
    }

    return () => {
      if (container.current) {
        const scriptElement = container.current.querySelector("script");
        if (scriptElement) {
          container.current.removeChild(scriptElement);
        }
      }
    };
  }, []);

  return (
    <div className="relative" style={{ height: '150px' }}>
      <div ref={container} className="tradingview-widget-container h-full w-full">
        <div className="tradingview-widget-container__widget"></div>
      </div>
      <div 
        className="absolute inset-0" 
        style={{ pointerEvents: 'auto' }}
        onClick={(e) => e.preventDefault()}
      ></div>
    </div>

  );
};

export default BitcoinChartTradingView;
