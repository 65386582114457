import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import API_URL from "../../../../config/api";
import dayjs from "dayjs";
import numberFormat from "../../../../Function/NumberFormat2float";

function WithdrawList({ peopleId }) {
  const [allcredit, setAllcredit] = useState([]);
  useEffect(() => {
    get_AllWithdraw();
  }, []);
  const get_AllWithdraw = async () => {
    setAllcredit([]);

    await API_URL.get(`api/deposit/getOneUserWithdraw/${peopleId}`)
      .then((res) => {
        const getalluser = res.data;
        if (getalluser.length !== 0) {
          //   getalluser.map((e) => {
          //     e.amounttxt = e.amount.toString();
          //     e.sliptimetxt = dayjs(e.sliptime)
          //       .format("DD/MM/YYYY HH:mm")
          //       .toString();
          //   });
          setAllcredit(getalluser);
        }
      })
      .catch((err) => {});
  };
  const columns_alluser = [
    {
      title: "สถานะ",
      align: "center",
      width: "8%",
      render: (allcredit) => (
        <>
          {allcredit.status === 0 ? (
            <Tag color="geekblue">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                รออนุมัติ
              </p>
            </Tag>
          ) : allcredit.status === 1 ? (
            <Tag color="green">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                อนุมัติ
              </p>
            </Tag>
          ) : (
            <Tag color="volcano">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                ไม่อนุมัติ
              </p>
            </Tag>
          )}
        </>
      ),
    },

    {
      title: "เวลา",
      align: "center",
      render: (allcredit) => (
        <p className=" text-xs text-black my-auto px-2">
          {dayjs(allcredit.sliptime).format("DD/MM/YYYY")} เวลา{" "}
          {dayjs(allcredit.sliptime).format("HH:mm")}
        </p>
      ),
    },

    {
      title: "ยอดถอน",
      align: "center",
      render: (allcredit) => (
        <p className="wsn text-xs text-black my-auto px-2">
          {numberFormat(allcredit.amount)}
        </p>
      ),
    },
    {
      title: "ยอดเดิม",
      align: "center",
      render: (allcredit) => (
        <p className="wsn text-xs text-black my-auto px-2">
          {numberFormat(allcredit.preamount)}
        </p>
      ),
    },
    {
      title: "คงเหลือ",
      align: "center",
      render: (allcredit) => (
        <>
          {allcredit.type === 1 ? (
            <Tag color="green">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                {numberFormat(allcredit.net)}
              </p>
            </Tag>
          ) : (
            <Tag color="volcano">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                {numberFormat(allcredit.net)}
              </p>
            </Tag>
          )}
        </>
      ),
    },

    {
      title: "Admin",
      width: "10%",
      align: "center",
      ellipsis: {
        showTitle: true,
      },
      render: (allcredit) => (

        <p className="text-xs text-black my-auto px-2">
          {allcredit.user !== null ? allcredit.user.name : "-"}
        </p>
      ),
    },
    {
        title: "หมายเหตุ",
        align: "center",
        width: "20%",
        render: (allcredit) => (
            <p className="text-xs text-black my-auto font-semibold text-hover-show-admin">
              {allcredit.note}
            </p>
    
        ),
      },
  ];
  return (
    <div>
      <Table
        className="table-striped-rows"
        size="middle"
        scroll={{
          x: 1200,
        }}
        pagination={{ pageSize: 15 }}
        rowKey="id"
        columns={columns_alluser}
        dataSource={allcredit}
      />
    </div>
  );
}

export default WithdrawList;
