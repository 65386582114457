import React, { useState, useEffect } from "react";
import API_URL from "../../../config/api";

function Aboutus() {
  const [aboutus, setAboutus] = useState("");
  useEffect(() => {
    getWebUrl();
  }, []);
  const getWebUrl = async () => {
    await API_URL.get(`api/weburl/getaboutus`)
      .then((res) => {
        const allweburl = res.data;
        if (allweburl.length !== 0) {
          const resaboutus = allweburl.find(({ name }) => name === "aboutus");
          setAboutus(resaboutus.nameurl);
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="p-2 lg:p-1">
      <div className="w-full lg:w-3/6 bg-zinc-800 rounded-xl shadow-md mx-auto px-2 py-1 lg:px-5 lg:py-2 text-white">
        <p>{aboutus}</p>
      </div>
    </div>
  );
}

export default Aboutus;
