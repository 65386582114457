import React from "react";
import { useParams, useNavigate } from "react-router-dom";
// import logoweb from "../../../icon/Logoeorder.webp";

import API_URL from "../../../../config/api";

import bg from "../../../../image/bg2.jpg";
import Adduser from "../Adduser";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import authHeader from "../../../../services/auth-header";

import PaymentsIcon from "@mui/icons-material/Payments";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { useCookies } from "react-cookie";
import numberFormat from "../../../../Function/NumberFormat2float";
import BankSwitch from "../../../../Function/BankSwitch";

import CancelIcon from "@mui/icons-material/Cancel";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "../../../../config/configapi";
import imageEmpty from "../../../../image/emptyimg.png";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import WithdrawList from "./WithdrawList";
import TradeList from "./TradeList";

const datatab = [

  {
    id: 1,
    tabname: "ประวัติการถอน",
  },
  {
    id: 2,
    tabname: "ประวัติการเทรด",
  },
  // {
  //   id: 3,
  //   tabname: "เครดิต",
  // },
];
function Userdetail({ getpeopleId }) {
  const URL_HOST = `${config.API_SERVER}`;
  let { peopleId } = useParams() || getpeopleId;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [userdata, setUserdata] = useState(null);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [uid, setUid] = useState("");
  const [getId, setGetId] = useState(null);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [codebank, setCodebank] = useState("");
  const [idbank, setIdbank] = useState(0);
  const [addressnow, setAddressnow] = useState("");
  const [status, setStatus] = useState(0);
  const [imageFrontCard, setImageFrontCard] = useState(null);
  const [imageBackCard, setImageBackCard] = useState(null);

  const [alltrade, setAlltrade] = useState([]);

  const [refcode, setRefcode] = useState("");

  const [isModalUser, setIsModalUser] = useState(false);

  const [credit, setCredit] = useState(0);

  const [openTabCK, setOpenTabCK] = useCookies(["openTabCK"]);
  const [openTab, setOpenTab] = useState(Number(openTabCK.openTabCK) || 1);

  useEffect(() => {
    // setOpenTab( Number(openTabCK.openTabCK));
    authHeader();

    if (peopleId != null) {
      get_Oneuser();
    }
  }, []);

  const get_Oneuser = async () => {
    setUserdata(null);
    setLoading(true);

    await API_URL.get(`api/people/getOneUserAdmin/${peopleId}`)
      .then(async (res) => {
        const findone = res.data.user;
        setUid(findone.uid);
        setRefcode(findone.refcode);
        setUserdata(findone);
        setFirstname(findone.firstname);
        setLastname(findone.lastname);
        setIdbank(findone.idbank);
        setCodebank(findone.codebank);
        setGetId(findone.id);
        setPhone(findone.phone);
        setEmail(findone.email);
        setCredit(findone.credit);
        setAddressnow(findone.addressnow);
        setStatus(findone.verify_status);
        setImageFrontCard(`${URL_HOST}${findone.imagefrontcard}`);
        setImageBackCard(URL_HOST + findone.imagebackcard);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };



  const showModalUser = () => {
    setIsModalUser(!isModalUser);
    if (isModalUser === true) {
      setGetId(null);
    }
  };

  const showModalEdit = () => {
    showModalUser();
  };

  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        minHeight: "100vh",
        height: "100%",
      }}
    >
      {isModalUser ? (
        <Adduser
          isModalUser={isModalUser}
          showModalUser={showModalUser}
          get_Oneuser={get_Oneuser}
          findone={userdata}
          getId={peopleId}
        />
      ) : (
        <></>
      )}
      <nav className="flex items-center justify-between h-14  bg-gray-800 px-6 py-3 md:py-5 lg:py-2  w-full z-30 drop-shadow-xl max-h-20">
        <div className="w-2/4 md:w-1/3 ">
          <Button
            variant="contained"
            color="warning"
            startIcon={<ArrowBackIosNewIcon />}
            onClick={() => navigate(-1)}
            className=""
          >
            ย้อนกลับ
          </Button>
        </div>
        <div className="flex items-center justify-center flex-shrink-0 text-white mr-6 w-2/4 md:w-1/3  ">
          {/* <img src={logoweb} alt="headerlogo" className="h-20" /> */}
        </div>

        <div className="w-1/12 md:w-1/3 flex justify-end"></div>
      </nav>
      <div className="mt-5 w-full md:flex px-2 md:px-7">
        <div className="w-full mx-auto p-2 md:p-4 bg-zinc-100 rounded-xl shadow-md shadow-gray-400">
          <div className="flex items-center mb-2">
            <div className=" w-2/3 mb-3 px-1">
              <div className="flex ">
                <p className="mr-3 font-semibold my-0 truncate">สถานะ :</p>
                {status === 0 ? (
                  <>
                    <DoNotDisturbOnIcon className="text-red-600 mr-2" />
                    <p>ไม่ได้ยืนยันตัวตน</p>
                  </>
                ) : status === 1 ? (
                  <>
                    <CheckCircleIcon className="text-green-600 mr-2" />
                    <p>ยืนยันตัวตนแล้ว</p>
                  </>
                ) : status === 2 ? (
                  <>
                    <CancelIcon className="text-red-600 mr-2" />
                    <p>ยืนยันตัวตนไม่ผ่าน</p>
                  </>
                ) : (
                  <>
                    <ChangeCircleIcon className="text-orange-400 mr-2" />
                    <p>รออนุมัติ</p>
                  </>
                )}
              </div>
              <div className="flex items-center justify-start my-1 wsn">
                <p className="font-semibold my-0 truncate">ชื่อ :&nbsp;</p>
                <p className="my-0">
                  {firstname} {lastname}
                </p>
              </div>
              <div className="flex items-center justify-start my-1 wsn">
                <p className="font-semibold my-0 truncate">Uid :&nbsp;</p>
                <p className="my-0">{uid}</p>
              </div>
              <div className="flex items-center justify-start my-1 text-sm lg:text-base">
                <p className="font-semibold my-0 ">เบอร์โทร :&nbsp; </p>
                <p className="my-0">{phone}</p>
              </div>
              <div className="flex items-center justify-start my-1 text-sm lg:text-base">
                <p className="font-semibold my-0 ">E-mail :&nbsp; </p>
                <p className="my-0">{email}</p>
              </div>
              <div className="flex items-center justify-start my-1 text-sm lg:text-base">
                <p className="font-semibold my-0 ">ที่อยู่ :&nbsp; </p>
                <p className="my-0">{addressnow}</p>
              </div>
              <div className="flex items-center justify-start my-1 wsn text-sm lg:text-base">
                <p className="font-semibold my-0">ธนาคาร :&nbsp; </p>
                <p className="my-0 truncate max-w-sm">{BankSwitch(idbank)}</p>
              </div>
              <div className="flex items-center justify-start my-1 text-sm lg:text-base">
                <p className="font-semibold my-0">หมายเลขบัญชี :&nbsp; </p>
                <p className="my-0">{codebank}</p>
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <button
              onClick={showModalEdit}
              className="text-center text-white font-semibold my-0 w-4/6 md:w-3/6  px-10 py-1 rounded-md bg-orange-500 hover:bg-orange-800 shadow-md"
            >
              แก้ไขข้อมูลส่วนตัว
            </button>
          </div>
        </div>

        <div className="w-full mx-auto">
          <div className="my-0  mx-1 md:mx-8 grid grid-cols-1 lg:grid-cols-2 ">
            <div className="p-0.5 ">
              <div className="pl-1 pr-1  w-full   bg-green-500  rounded-lg shadow-md mt-2 lg:mt-0">
                <div className="flex w-full h-full py-2 px-4 bg-white rounded-lg justify-between">
                  <div className="my-auto truncate mx-auto">
                    <p className="font-medium text-sm lg:text-lg my-auto text-center">
                      เครดิต
                    </p>
                    <div className="flex text-center mx-auto justify-center">
                      <p className="text-base lg:text-xl my-auto text-center">
                        {numberFormat(credit)}
                      </p>
                      <p className="ml-2 text-xs lg:text-md  align-bottom">
                        USDT
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/********************************************************/}
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mt-2">
            <LazyLoadImage
              className="rounded-md text-center mx-auto imgDepositpending h-52 object-contain"
              src={
                imageFrontCard !== null
                  ? imageFrontCard
                  : null && imageFrontCard !== null
                  ? imageFrontCard
                  : null
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror =
                  imageFrontCard !== null ? imageFrontCard : null;
                currentTarget.src = imageEmpty;
              }}
              alt="ID Card"
            />
            <LazyLoadImage
              className="rounded-md text-center mx-auto imgDepositpending h-52 object-contain"
              src={
                imageBackCard !== null
                  ? imageBackCard
                  : null && imageBackCard !== null
                  ? imageBackCard
                  : null
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror =
                  imageBackCard !== null ? imageBackCard : null;
                currentTarget.src = imageEmpty;
              }}
              alt="ID Card"
            />
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="w-full px-1 md:px-8">
          <ul
            className="grid grid-cols-2 lg:grid-cols-2 gap-3 mb-0 list-none  pt-3 pb-4 flex-row"
            role="tablist"
          >
            {datatab.map((data, index) => (
              <li className=" flex-auto text-center" key={index}>
                <a
                  className={
                    "myFont text-sm md:text-md font-bold uppercase px-5 py-2 lg:py-4 shadow-lg rounded-lg block leading-normal " +
                    (openTab === data.id
                      ? "text-white bg-blue-900 "
                      : "text-blue-600  bg-white hover:text-blue-900 hover:bg-gray-100")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTabCK("openTabCK", data.id, { path: `/` });
                    setOpenTab(data.id);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  {data.id === 1 ? (
                    <PaymentsIcon className="mr-2" />
                   
                  ) : data.id === 2 ? (
                    <AssessmentIcon className="mr-2" />
                  ) : (
                  
                    <CreditCardIcon className="mr-2" />
                  )}
                  {data.tabname}
                </a>
              </li>
            ))}
          </ul>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                {/* tab1 */}

                {/* tab2 */}

                <div className={openTab === 1 ? "block" : "hidden"} id="link2">
                  <WithdrawList peopleId={peopleId} />
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <TradeList  peopleId={peopleId} />
                </div>
                {/* <div className={openTab === 3 ? "block" : "hidden"} id="link1">
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Userdetail;
