import React from "react";
import {  Modal } from "antd";
import imageEmpty from "../../../image/emptyimg.png";

import { LazyLoadImage } from "react-lazy-load-image-component";

function IDcardModal({ showIDCradModal, showIDCradModalClose, isIDcardMoal, urlimagefront,urlimageback }) {
  return (
    <Modal
      style={{
        top: 10,
      }}
      open={isIDcardMoal}
      onOk={showIDCradModal}
      onCancel={showIDCradModalClose}
      width={700}
      key={0}
      footer={[null]}
    >
      <div className="">
        <LazyLoadImage
          className="rounded-sm w-full lg:w-3/4 mx-auto object-contain"
          src={urlimagefront !== null ? `${urlimagefront}` : null && urlimagefront !== null ? urlimagefront : null}
          onError={({ currentTarget }) => {
            currentTarget.onerror = urlimagefront !== null ? urlimagefront : null; // prevents looping
            currentTarget.src = imageEmpty;
          }}
      
          alt="nice2"
        />
        <LazyLoadImage
          className="rounded-sm w-full lg:w-3/4 mx-auto object-contain"
          src={urlimageback !== null ? `${urlimageback}` : null && urlimageback !== null ? urlimageback : null}
          onError={({ currentTarget }) => {
            currentTarget.onerror = urlimageback !== null ? urlimageback : null; // prevents looping
            currentTarget.src = imageEmpty;
          }}
      
          alt="nice2"
        />
      </div>
    </Modal>
  );
}

export default IDcardModal;
