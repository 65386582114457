import React from "react";
import { Table, Tag } from "antd";


import numberFormat from "../../../Function/NumberFormat2float";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

function TransactinoList({ allcredit }) {
  const { t } = useTranslation();

  const columns_alluser = [
    {
      title: `${t("status")}`,
      align: "center",
      width: "8%",
      render: (allcredit) => (
        <>
          {allcredit.status === 0 ? (
            <Tag color="geekblue">
              <p className="text-xs text-white my-auto px-2 py-1 font-semibold">
                {t("waiting")}
              </p>
            </Tag>
          ) : allcredit.status === 1 ? (
            <Tag color="green">
              <p className="text-xs text-white my-auto px-2 py-1 font-semibold">
                {t("approve")}
              </p>
            </Tag>
          ) : (
            <Tag color="volcano">
              <p className="text-xs text-white my-auto px-2 py-1 font-semibold">
                {t("notapproved")}
              </p>
            </Tag>
          )}
        </>
      ),
    },

    {
      title: `${t("time")}`,
      align: "center",
      render: (allcredit) => (
        <p className=" text-xs text-white my-auto px-2">
          {dayjs(allcredit.sliptime).format("DD/MM/YYYY")} {" "}
          {dayjs(allcredit.sliptime).format("HH:mm")}
        </p>
      ),
    },

    {
      title: `${t("credit")}`,
      align: "center",
      render: (allcredit) => (
        <p className="wsn text-xs text-white my-auto px-2">
          {numberFormat(allcredit.amount)}
        </p>
      ),
    },
    {
      title: `${t("oldamount")}`,
      align: "center",
      render: (allcredit) => (
        <p className="wsn text-xs text-white my-auto px-2">
          {numberFormat(allcredit.preamount)}
        </p>
      ),
    },
    {
      title: `${t("balance")}`,
      align: "center",
      render: (allcredit) => (
        <>
          <Tag color="orange">
            <p className="text-xs text-white my-auto px-2 py-1 font-semibold">
              {numberFormat(allcredit.net)}
            </p>
          </Tag>
        </>
      ),
    },
    {
      title: `${t("note")}`,
      align: "center",
      width: "30%",
      render: (allcredit) => (
          <p className="text-xs text-white my-auto  text-hover-show">
            {allcredit.note}
          </p>
  
      ),
    },
  ];

  return (
    <div>
      <Table
        className="table-striped-rows my-1"
        size="middle"
        scroll={{
          x: 800,
        }}
        pagination={{ pageSize: 10 }}
        rowKey="id"
        columns={columns_alluser}
        dataSource={allcredit}
        
        
      />
    </div>
  );
}

export default TransactinoList;
